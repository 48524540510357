import {type FC, useState} from 'react';
import {observer} from 'mobx-react-lite';

import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop/Backdrop';
import Drawer from '@mui/material/Drawer/Drawer';
import {MIconButton} from '@src/components/redesign/mui-components/common/icon-button/icon-button';
import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';
import {useStrategyContext} from '@src/pages/repricer/hooks/useStrategyContext';
import {TRepricerItem} from '@src/pages/repricer/types/repricer-item';
import {useStore} from '@src/stores';

import {CompetitorsTable} from './components/competitors-table/competitors-table';
import {PriceBlock} from './components/price-block/price-block';
import {RecommendedPrice} from './components/recommended-price/recommended-price';
import {TUpdateProductPriceOnShop} from '../product-settings-row/product-settings-row';

import {useEmotionWrapper} from './repricer-item-details.s';

interface IRepricerItemDetails {
  isOpenAnalytics: boolean;
  setIsOpenAnalytics: (isOpen: boolean) => void;
  product: TRepricerItem | null;
  updateProductPriceOnShop: TUpdateProductPriceOnShop;
}

export const RepricerItemDetails: FC<IRepricerItemDetails> = observer(
  ({isOpenAnalytics, setIsOpenAnalytics, product, updateProductPriceOnShop}) => {
    const {classes} = useEmotionWrapper();
    const {mpAccounts, mpItems} = useStore();
    const [isLoading, setIsLoading] = useState(false);
    const {strategy} = useStrategyContext();

    const priceLogsDashboardUrl = () => {
      if (mpAccounts.current?.uuidSk && product?.sku) {
        const url = new URL(import.meta.env.WEB_APP_YANDEX_DATA_LENS_PRICE_LOG_DASHBOARD_URL);

        url.searchParams.append('token', mpAccounts.current?.uuidSk);
        url.searchParams.append('sku', product.sku);

        return url.toString();
      }
    };

    if (product === null) return null;

    const handleClickApplyRecommendations = async () => {
      setIsLoading(true);
      if (product.recommendedPrice) {
        try {
          await mpItems.updateItemPrice(product.itemId, product.recommendedPrice * 100);

          updateProductPriceOnShop({
            price: product.recommendedPrice,
            type: 'price',
          });
        } finally {
          setIsLoading(false);
        }
      }
    };

    return (
      <Backdrop sx={(theme) => ({color: '#fff', zIndex: theme.zIndex.drawer + 1})} open={isOpenAnalytics}>
        <Drawer
          anchor="right"
          open={isOpenAnalytics}
          onClose={() => setIsOpenAnalytics(false)}
          classes={{paperAnchorRight: classes.paperRoot}}
        >
          <div className={classes.header}>
            <MTypography className={classes.productName}>{product.name}</MTypography>
            <MIconButton onClick={() => setIsOpenAnalytics(false)}>
              <CloseIcon />
            </MIconButton>
          </div>
          <div className={classes.content}>
            {product.recommendedPrice !== product.price && strategy.isAuto === false && (
              <div className={classes.detailsItem}>
                <MTypography className={classes.blockTitle}>Рекомендации стратегии</MTypography>
                <RecommendedPrice
                  recommendedPrice={product.recommendedPrice}
                  itemId={product.itemId}
                  isLoading={isLoading}
                  handleClickApplyRecommendations={handleClickApplyRecommendations}
                />
              </div>
            )}
            {product.optimalPrice || product.averagePrice ? (
              <div className={classes.detailsItem}>
                <MTypography className={classes.blockTitle}>Рекомендации Яндекса</MTypography>
                <div className={classes.priceContent}>
                  <PriceBlock price={product.optimalPrice} description={'Привлекательная цена'} />
                  <PriceBlock price={product.averagePrice} description={'Умеренно привлекательная цена'} />
                </div>
              </div>
            ) : null}
            {product.competitorsPrices?.length > 0 ? (
              <div className={classes.detailsItem}>
                <MTypography className={classes.blockTitle}>Анализ конкурентов</MTypography>
                <CompetitorsTable competitors={product.competitorsPrices} />
              </div>
            ) : null}
            {product.competitors?.length === 0 ? (
              <div style={{display: 'flex', gap: 10}}>
                {product.competitorsPrices[0]?.modelPrices.avgPrice && (
                  <PriceBlock price={product.competitorsPrices[0]?.modelPrices.avgPrice} description={'Средняя цена'} />
                )}
                {product.competitorsPrices[0]?.modelPrices.minPrice && (
                  <PriceBlock price={product.competitorsPrices[0]?.modelPrices.minPrice} description={'Мин. цена'} />
                )}
                {product.competitorsPrices[0]?.modelPrices.maxPrice && (
                  <PriceBlock price={product.competitorsPrices[0]?.modelPrices.maxPrice} description={'Макс. цена'} />
                )}
              </div>
            ) : null}
            <div className={classes.detailsItem}>
              <MTypography className={classes.blockTitle}>График изменения цены</MTypography>
              <div className={classes.priceContent}>
                <iframe className={classes.priceLogIframe} src={priceLogsDashboardUrl()} />
              </div>
            </div>
          </div>
        </Drawer>
      </Backdrop>
    );
  }
);
