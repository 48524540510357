export const ErrorIcon = ({size = '30px', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path d="M15 5.25L23 21.25H7L15 5.25Z" fill="#CA8BF1" />
      <path d="M15 5.25L14.25 6.75L21.5 21.25H23L15 5.25Z" fill="#9E6CF7" />
      <path
        d="M21 29.7508C20.8 29.7508 20.65 29.6507 20.55 29.4508C20.45 29.2007 20.55 28.9007 20.8 28.8008C25.8 26.5508 29 21.5008 29 16.0508C29 8.3508 22.7 2.0508 15.0001 2.0508C7.30008 2.0508 1.00002 8.3508 1.00002 16.0508C1.00002 20.5008 3.10002 24.5008 6.80004 27.1508C7.00002 27.3008 7.10004 27.6008 6.90006 27.8508C6.75006 28.0508 6.45006 28.1508 6.20004 27.9508C2.25 25.1508 0 20.8008 0 16.0508C0 7.80078 6.75 1.05078 15 1.05078C23.25 1.05078 30 7.80078 30 16.0508C30 21.9008 26.55 27.3008 21.2 29.7008C21.15 29.7508 21.05 29.7508 21 29.7508Z"
        fill="#CA8BF1"
      />
      <path d="M7.49998 25.25L9.49996 28.75L5.5 28.25L7.49998 25.25Z" fill="#CA8BF1" />
      <path
        d="M22.9996 21.7508H6.99962C6.84962 21.7508 6.64964 21.6508 6.54962 21.5008C6.4496 21.3508 6.4496 21.1508 6.54962 21.0008L14.5496 5.00077C14.6996 4.65079 15.2496 4.65079 15.4496 5.00077L23.4496 21.0008C23.5496 21.1508 23.4996 21.3508 23.4496 21.5008C23.3996 21.6508 23.1496 21.7508 22.9996 21.7508ZM7.7996 20.7508H22.1996L14.9996 6.35077L7.7996 20.7508Z"
        fill="#1D2940"
      />
      <path
        d="M15 17.25C14.7 17.25 14.5 17.05 14.5 16.75V11.25C14.5 10.95 14.7 10.75 15 10.75C15.3 10.75 15.5 10.95 15.5 11.25V16.75C15.5 17.05 15.3 17.25 15 17.25Z"
        fill="#1D2940"
      />
      <path
        d="M15.5 18.75C15.5 19.05 15.3 19.25 15 19.25C14.7 19.25 14.5 19.05 14.5 18.75C14.5 18.45 14.7 18.25 15 18.25C15.3 18.25 15.5 18.45 15.5 18.75Z"
        fill="#1D2940"
      />
      <path
        d="M21 28.95C20.8 28.95 20.65 28.85 20.55 28.65C20.45 28.4 20.55 28.1 20.8 28C25.8 25.75 29 20.75 29 15.25C29 7.55002 22.7 1.25002 15.0001 1.25002C7.30008 1.25002 1.00002 7.54996 1.00002 15.25C1.00002 19.7 3.10002 23.7 6.80004 26.35C7.00002 26.5 7.10004 26.8 6.90006 27.05C6.75006 27.25 6.45006 27.35 6.20004 27.15C2.25 24.35 0 20 0 15.25C0 7 6.75 0.25 15 0.25C23.25 0.25 30 7 30 15.25C30 21.1 26.55 26.5 21.2 28.9C21.15 28.95 21.05 28.95 21 28.95Z"
        fill="#1D2940"
      />
      <path
        d="M9.50043 29.2493H9.45045L5.45043 28.7493C5.30043 28.7493 5.15043 28.5993 5.05041 28.4493C5.00043 28.2993 5.00043 28.0993 5.10039 27.9493L7.10037 24.9493C7.20039 24.7993 7.35039 24.6993 7.55037 24.7494C7.70037 24.7494 7.90035 24.8494 7.95039 24.9994L9.95037 28.4994C10.0504 28.6494 10.0504 28.8493 9.95037 29.0494C9.80043 29.1493 9.65043 29.2493 9.50043 29.2493ZM6.35043 27.8493L8.55045 28.1493L7.45047 26.1993L6.35043 27.8493Z"
        fill="#1D2940"
      />
    </svg>
  );
};
