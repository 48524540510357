import {FC, useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom'; // Импортируем createPortal
import {FixedSizeList as List} from 'react-window'; // Импортируем FixedSizeList из react-window
import {observer} from 'mobx-react-lite';

import {COLORS} from '@shared/const/appPalette';
import {DropdownIcon, SearchIcon} from '@src/components/common/icons';

import {useStore} from '../../../stores';
import {Typography} from '../..';

import {useEmotionWrapper} from './dropdown.s';

type TDropdownItem = {
  value: number | string;
  title: string;
};

interface IDropdown {
  items?: Array<TDropdownItem>;
  onClick: (item: TDropdownItem) => void;
  currentItemId?: number;
}

export const Dropdown: FC<IDropdown> = observer(({items, currentItemId, onClick}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {mpAccounts} = useStore();
  const {classes} = useEmotionWrapper({isOpen});
  const targetRef = useRef<HTMLButtonElement>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [dropdownPosition, setDropdownPosition] = useState<{top: number; left: number}>({top: 0, left: 0});
  const [dropdownWidth, setDropdownWidth] = useState<number>(0);
  const refSearchInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (targetRef.current && !targetRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const getCurrentItem = () => {
    if (items) {
      const findIndex = items.findIndex((item) => item.value === currentItemId);
      return findIndex !== -1 ? items[findIndex].title : null;
    }
  };

  useEffect(() => {
    if (!isOpen) setSearchQuery('');
  }, [isOpen]);

  const filteredAccounts = (items || [])
    .filter((item) => item.title.toLowerCase().includes(searchQuery.toLowerCase()))
    .filter((item) => item.title.trim() !== ''); // Убираем пустые элементы

  useEffect(() => {
    if (targetRef.current && isOpen) {
      const rect = targetRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom,
        left: rect.left,
      });

      setTimeout(() => {
        if (refSearchInput.current) {
          refSearchInput.current.focus();
        }
      }, 0);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && filteredAccounts.length > 0) {
      const widths = filteredAccounts.map((item) => {
        const element = document.getElementById(`dropdown-item-${item.value}`);
        return element ? element.offsetWidth : 0;
      });

      const maxWidth = Math.max(...widths, 0) > 300 ? Math.max(...widths, 0) : 300;
      setDropdownWidth(maxWidth);
    }
  }, [isOpen, filteredAccounts]);

  const dropdownContent = (
    <div
      className={isOpen ? classes.dropdownContent : classes.hideContent}
      style={{
        position: 'absolute',
        top: dropdownPosition.top + window.scrollY + 5,
        left: dropdownPosition.left,
        zIndex: 9999,
        backgroundColor: 'white',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        width: dropdownWidth,
        overflow: 'hidden',
      }}
    >
      {mpAccounts.items.length > 5 && (
        <div
          onKeyUp={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
          className={classes.search}
        >
          <input
            data-cy="dropdown-mp-accounts-search"
            placeholder="Поиск кабинета..."
            ref={refSearchInput}
            value={searchQuery}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <SearchIcon size="16" color={COLORS.DARK_70} />
        </div>
      )}
      {filteredAccounts.length === 0 && (
        <div
          className={classes.notFound}
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          Кабинет не найден
        </div>
      )}

      {filteredAccounts.length > 0 && (
        <List height={200} itemCount={filteredAccounts.length} itemSize={40} width={dropdownWidth}>
          {({index, style}) => {
            const item = filteredAccounts[index];
            if (!item.value) return null;
            return (
              <div
                className={classes.mpAcc}
                id={`dropdown-item-${item.value}`}
                style={{
                  ...style,
                  color: currentItemId === item.value ? COLORS.PURPLE_100 : '',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
                onClick={() => {
                  onClick(item);
                  setIsOpen(false);
                }}
                key={item.value}
              >
                {item.title}
              </div>
            );
          }}
        </List>
      )}
    </div>
  );

  return (
    <button
      data-cy="dropdown-mp-accounts"
      onMouseDown={() => setIsOpen(true)}
      onMouseUp={() => setIsOpen(true)}
      onKeyUp={(e) => e.stopPropagation()}
      onKeyDown={(e) => {
        e.stopPropagation();
        if (e.key === ' ') {
          e.preventDefault();
        }
      }}
      className={classes.root}
      ref={targetRef}
    >
      <Typography
        whiteSpace="nowrap"
        cursor="pointer"
        color={COLORS.PURPLE_100}
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {getCurrentItem()}
      </Typography>
      <div className={classes.icon}>
        <DropdownIcon size="10px" color={COLORS.PURPLE_100} />
      </div>
      {items && items.length > 0 && isOpen && targetRef.current
        ? ReactDOM.createPortal(dropdownContent, document.body)
        : null}
    </button>
  );
});
