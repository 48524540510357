import {type FC} from 'react';

import {COLORS_REDESIGN} from '@shared/const/appPalette';

import {useEmotionWrapper} from './notification-container.s';

interface INotifications {
  children: React.ReactNode;
  color?: string;
}

export const NotificationContainer: FC<INotifications> = ({children, color = COLORS_REDESIGN.BRAND_LIGHT}) => {
  const {classes} = useEmotionWrapper(color);
  return <div className={classes.root}>{children}</div>;
};
