import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  headerRoot: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  contentBlock: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  contentMaxWidth: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    maxWidth: 1600,
    flexDirection: 'column',
    gap: 10,
  },
  subTitle: {
    fontWeight: 600,
    fontSize: 18,
  },
  backButton: {
    width: 'fit-content',
  },
  nextStepButton: {
    width: 'fit-content',
    marginLeft: 'auto',
  },
}));
