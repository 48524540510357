import {FC, useEffect, useState} from 'react';
import {FileUploader} from 'react-drag-drop-files';
import {toast} from 'react-toastify';
import {useParams} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {CircularProgress, Link, Typography} from '@mui/material';
import {Modal} from '@src/components/common/modal/modal';
import {MButton} from '@src/components/redesign/mui-components/common/button/button';
import {COLORS, COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {useStore} from '@src/stores';

import {useEmotionWrapper} from './upload-products-modal.s';

interface IFeedUploadModalProps {
  opened: boolean;
  onClose: () => void;
}

const fileTypes = ['xlsx', 'xls'];

export const UploadProductsModal: FC<IFeedUploadModalProps> = observer(({opened, onClose}) => {
  const {mpAccounts, repricer} = useStore();
  const {classes} = useEmotionWrapper();
  const [isLoading, setIsLoading] = useState(false);
  const {strategyId} = useParams<{strategyId: string}>();
  const [resultParsedFile, setResultParsedFile] = useState<{itemsParsed: number; itemsUpdated: number} | null>(null);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  const onDownload = async () => {
    if (mpAccounts.current?.id) {
      setIsDownloadLoading(true);
      const res = await repricer
        .getRepricerItemsTemplate(mpAccounts.current?.id)
        .finally(() => setIsDownloadLoading(false));
      if (res) {
        const link = document.createElement('a');
        link.href = res.url;
        link.setAttribute('download', res.fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode && link.parentNode.removeChild(link);
      }
    }
  };

  const onUploadFile = async (file: File) => {
    setResultParsedFile(null);
    if (mpAccounts.current?.id && file) {
      try {
        setIsLoading(true);
        const res = await repricer.uploadRepricerItems({strategyId: Number(strategyId), file});
        await repricer.getStrategies({marketPlaceAccountId: mpAccounts.current.id, searchItem: ''});

        setResultParsedFile(res);
        toast.success('Файл успешно загружен');
      } catch (e) {
        console.log('e', e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const loader = (
    <div className={classes.loader}>
      <CircularProgress size={20} />
      <Typography>Загрузка файла...</Typography>
    </div>
  );

  useEffect(() => {
    return () => {
      setResultParsedFile(null);
      setIsLoading(false);
    };
  }, [opened]);

  return (
    <Modal title="Загрузка товаров" opened={opened} onClose={onClose}>
      <div className={classes.container}>
        <FileUploader
          handleChange={onUploadFile}
          name="file"
          hoverTitle="Отпустите в это поле"
          dropMessageStyle={{color: COLORS.PURPLE_100, fontWeight: 500}}
          onTypeError={() => {
            toast.error(`Недопустимый формат файла. Загрузите файл имеющий расширение: ${fileTypes.join(', ')}`);
          }}
          types={fileTypes}
        >
          <div className={classes.root}>
            {resultParsedFile ? (
              <div>
                <Typography sx={{fontWeight: 700, fontSize: 18}}>Файл успешно загружен</Typography>
                <br />
                <Typography sx={{fontWeight: 700}}>Товаров обработано: {resultParsedFile.itemsParsed} шт.</Typography>
                <Typography sx={{fontWeight: 700}}>
                  Товаров добавлено: {resultParsedFile.itemsUpdated || 0} шт.
                </Typography>
              </div>
            ) : isLoading ? (
              loader
            ) : (
              <>
                <CloudUploadIcon sx={{color: COLORS_REDESIGN.DARK_NIGHT_BLUE}} />
                <Typography sx={{fontWeight: 700, fontSize: 18}}>Загрузите XLSX-файл</Typography>
                <Typography sx={{color: COLORS_REDESIGN.NIGHT_GREY}}>
                  <Typography className={classes.selectFileLabel}>Выберите файл</Typography> или перетащите сюда
                </Typography>
              </>
            )}
          </div>
        </FileUploader>
        <div>
          <Typography>
            Используйте шаблон для правильного <br /> заполнения файла
          </Typography>
          <Link
            disabled={isDownloadLoading}
            endIcon={isDownloadLoading ? <CircularProgress size={16} sx={{color: 'inherit'}} /> : null}
            sx={{fontSize: 14, paddingLeft: 0, fontWeight: 500}}
            onClick={onDownload}
            size="small"
            component={MButton}
          >
            Скачать шаблон
          </Link>
        </div>
      </div>
    </Modal>
  );
});
