import {FC, useEffect, useMemo, useState} from 'react';

import CheckIcon from '@mui/icons-material/Check';
import {FormControl, InputAdornment, Menu, OutlinedInput, Tooltip, Typography} from '@mui/material';
import {useConfirmationDialog} from '@src/components';
import {FaqIcon} from '@src/components/common/icons/components/faq';
import {MButton} from '@src/components/redesign/mui-components/common/button/button';
import {MIconButton} from '@src/components/redesign/mui-components/common/icon-button/icon-button';
import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';
import {useStrategyContext} from '@src/pages/repricer/hooks/useStrategyContext';
import {COLORS, COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {stringToNumberWithDot} from '@src/shared/utils/stringToNumberWithDot';

import {validateMaxPricePct} from '../../../../utils/valid-max-price-pct';

import {useEmotionWrapper} from './max-price-header-cell.s';

interface IMaxPriceHeaderCellProps {
  resetCollectPriceUpdateFunctions: (type: 'minPrice' | 'maxPrice' | 'price') => void;
}

export const MaxPriceHeaderCell: FC<IMaxPriceHeaderCellProps> = ({resetCollectPriceUpdateFunctions}) => {
  const {classes} = useEmotionWrapper();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {onChangeStrategy, strategy} = useStrategyContext();
  const open = Boolean(anchorEl);
  const [newMaxPercent, setNewMaxPercent] = useState<string | null>(null);
  const isNotValidNewMaxPercent = useMemo(() => validateMaxPricePct(newMaxPercent), [newMaxPercent]);
  const {getConfirmation} = useConfirmationDialog();

  useEffect(() => {
    setNewMaxPercent(strategy.maxPricePct ? strategy.maxPricePct.toString() : null);
  }, [strategy.id]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickApplyMaxPricePercent = async () => {
    handleClose();

    const confirm = await getConfirmation({
      title: 'Внимание',
      message: (
        <div>
          <Typography>Вы уверены, что хотите изменить максимальную цену?</Typography>
          <Typography>Максимальная цена у всех товаров будет обновлена.</Typography>
        </div>
      ),
    });

    if (confirm) {
      resetCollectPriceUpdateFunctions('maxPrice');
      onChangeStrategy({
        ...strategy,
        maxPricePct:
          stringToNumberWithDot(newMaxPercent || '') !== 0 ? stringToNumberWithDot(newMaxPercent || '') : null,
      });
    }
  };

  return (
    <div className={classes.root}>
      <Tooltip
        arrow
        title="Установите Максимальный порог цены, выше которого не готовы повышать цену. Для установки Макс. цен на все товары сразу нажмите на кнопку % справа"
      >
        <Typography sx={{fontWeight: 700, whiteSpace: 'nowrap'}}>Макс. цена</Typography>
      </Tooltip>
      <MIconButton
        classes={{root: classes.buttonRoot}}
        sx={{backgroundColor: COLORS_REDESIGN.LIGHT_GREY}}
        onClick={handleClick}
      >
        %
      </MIconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <div style={{padding: 12}}>
          <MTypography sx={{fontWeight: 600}}>Макс. для всех товаров</MTypography>{' '}
          <div style={{display: 'flex', alignItems: 'center', gap: 5}}>
            <MTypography sx={{fontWeight: 400, fontSize: 13, marginTop: 1}}>От 100 до 999%</MTypography>
            <Tooltip
              title={
                <Typography sx={{fontSize: 13}}>
                  Установите Максимальный порог цен сразу на все товары, результат отобразится моментально. После можно
                  отредактировать выборочно, если необходимо
                  <br />
                  Цена задается в проценте от текущей цены товара.
                  <br />
                  <Typography sx={{fontStyle: 'italic', fontSize: 13}}>
                    Пример: Цены товаров 900, 1200 и 1500 руб., устанавливаем 120% порог Макс. цен. Получаем Макс. цены
                    для товаров 1080, 1440 и 1800 руб. соответственно
                  </Typography>
                </Typography>
              }
            >
              <div>
                <FaqIcon color={COLORS.BLACK_15} />
              </div>
            </Tooltip>
          </div>
          <div className={classes.inputRoot}>
            <FormControl error={isNotValidNewMaxPercent} size="small" sx={{width: 100}}>
              <OutlinedInput
                value={newMaxPercent}
                onChange={(event) => setNewMaxPercent(event.target.value)}
                placeholder="0"
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </FormControl>
            <MButton disabled={isNotValidNewMaxPercent} onClick={onClickApplyMaxPricePercent} variant="contained">
              <CheckIcon />
            </MButton>
          </div>
        </div>
      </Menu>
    </div>
  );
};
