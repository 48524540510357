import {useCallback, useEffect} from 'react';

import {useWebSocket} from '@src/components/providers/websocket/websocket-provider';

interface IUseSocketSubscribe<T> {
  socketChannel: 'private' | 'public';
  eventName?: keyof WebSocketEventMap;
  actionType: string;
  eventHandler: (data: T) => void;
}

export const useSocketSubscribe = <T>({
  socketChannel,
  eventName = 'message',
  actionType,
  eventHandler,
}: IUseSocketSubscribe<T>) => {
  const socket = useWebSocket();

  const stableEventHandler = useCallback(eventHandler, [eventHandler]);

  useEffect(() => {
    const handler = (e: Event) => {
      if ('data' in e) {
        if (e.data === 'Authorized') {
          console.log('Private Socket Authorized');
        } else if (e.data === 'Unauthorized') {
          console.log('Private Socket Unauthorized');
        } else if (e.data === 'Connected') {
          console.log('Public Socket Connected');
        } else {
          const data = JSON.parse((e as MessageEvent).data);
          if (data && data.action === actionType) {
            stableEventHandler(data);
          }
        }
      }
    };
    socket[socketChannel]?.addEventListener(eventName, handler);
    console.log('WS: added listener:', actionType);
    return () => {
      console.log('WS: removing listener:', actionType);
      socket[socketChannel]?.removeEventListener(eventName, handler);
    };
  }, []);
};
