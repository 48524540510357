export const COLORS = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  SCROLLBAR: '#ebd2fa',
  SCROLLBAR_HOVERED: '#daa8f7',

  //new styles
  BLACK_100: '#393939', //to DARK_NIGHT_BLUE
  BLACK_50: 'rgba(0, 0, 0, .5)',
  BLACK_40: '#999999',
  BLACK_20: 'rgba(0, 0, 0, .2)',
  BLACK_15: 'rgba(0, 0, 0, .15)',
  BLACK_10: '#E5E5E5',
  BLACK_6: '#F0F0F0',

  DARK_100: 'rgba(57, 57, 57, 1)',
  DARK_70: 'rgba(57, 57, 57, .7)',
  DARK_50: 'rgba(57, 57, 57, .5)',
  DARK_20: 'rgba(57, 57, 57, .2)',

  GREEN_100: '#5DBE66',
  GREEN_20: '#9ED8A3',
  GREEN_15: 'rgba(93, 190, 102, .15)',

  PURPLE_100: '#9E6CF7',
  PURPLE_70: 'rgba(189, 111, 244, .7)',
  PURPLE_30: 'rgba(189, 111, 244, .3)',
  PURPLE_20: '#D5B5E9',

  YELLOW_100: '#FEDD3E',
  YELLOW_20: '#FEEFA6',

  RED_100: 'rgba(248, 74, 74, 1)',
  RED_20: 'rgba(248, 74, 74, .2)',

  BLUE_PRIMARY: 'rgba(52, 92, 229, 1)',
  BLUE_SECONDARY: 'rgba(132, 159, 255, 1)',

  GRAY_100: 'rgba(248, 248, 248, 1)',
  GRAY_10: 'rgba(248, 248, 248, .1)',
};

export const COLORS_REDESIGN = {
  BRAND: '#9E6CF7',
  BRAND_LIGHT: '#FAF7FE',

  DARK_NIGHT_BLUE: '#1D2940',
  TEXT_PRIMARY: '#344054',

  DARK_GREY: '  #666666',
  LIGHT_GREY: '#F8F8F8',
  BRIGHT_GRAY: '#EDEDED',
  GENERAL_GRAY: '#9E9E9E',
  NIGHT_GREY: '#5B6575',

  SKY_BLUE: '#335CE5',

  WHITE: '#FFFFFF',

  GREEN: '#00B72E',
  ORANGE: '#FF8400',
};
