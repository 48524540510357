import {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import {COLORS, COLORS_REDESIGN} from '@shared/const/appPalette';
import {TARIFFS_TYPES} from '@shared/enums/tariffs';
import {BellIcon, RightArrowIcon} from '@src/components/common/icons';
import {MButton} from '@src/components/redesign/mui-components/common/button/button';
import {getRoutePricing} from '@src/shared/const/router';
import dayjs from 'dayjs';

import {useCurrentTariff} from '../../../../hooks/useCurrenTariff';
import {useStore} from '../../../../stores';
import {Typography} from '../../..';

import {useEmotionWrapper} from './tariff-notification.s';

export const TariffNotification = observer(() => {
  const {classes} = useEmotionWrapper();
  const navigate = useNavigate();
  const {tariffs, mpAccounts} = useStore();
  const {currentTariff} = useCurrentTariff();

  const getRemainingDays = useMemo(() => {
    const now = dayjs(new Date());
    const expirationDate = dayjs(currentTariff.periodTo);
    return expirationDate.diff(now, 'day');
  }, [currentTariff.periodTo]);

  const isViewNotification = useMemo(() => {
    const daysLeft = getRemainingDays;
    const isStartTariff = currentTariff.type === TARIFFS_TYPES.START;
    const isTrialTariff = currentTariff.isTrial;

    if (!isTrialTariff && !isStartTariff && daysLeft < 0) {
      return true;
    } else {
      return false;
    }
  }, [mpAccounts.current?.id, currentTariff.id, JSON.stringify(tariffs.list)]);

  if (!isViewNotification) return null;

  return (
    <div className={classes.root}>
      <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
        <BellIcon color={COLORS_REDESIGN.BRAND} />
        <Typography size={16} weight={700} color={COLORS_REDESIGN.BRAND}>
          Оплата не поступила. Пожалуйста, оплатите тарифный план для продолжения работы
        </Typography>
      </div>

      <div className={classes.buttonContainer}>
        <MButton variant="contained" onClick={() => navigate(getRoutePricing())}>
          <div className={classes.buttonContent}>
            Выбрать тариф
            <RightArrowIcon color={COLORS.WHITE} size="16px" />
          </div>
        </MButton>
      </div>
    </div>
  );
});
