import {emotionWrapper} from '@shared/lib';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';

export const useEmotionWrapper = emotionWrapper((idx: number) => ({
  root: {
    padding: '0 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: idx % 2 === 0 ? COLORS_REDESIGN.LIGHT_GREY : 'none',
    borderRadius: '8px',
    gap: '5px',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: 5,
  },
  checkBoxLabel: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 320,
    cursor: 'pointer',
    textAlign: 'left',
    '&:hover': {
      textDecoration: 'underline',
      color: COLORS_REDESIGN.BRAND,
    },
  },
}));
