import {type FC, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';

import {COLORS, COLORS_REDESIGN} from '@shared/const/appPalette';
import {scrollToTop} from '@shared/utils/scrollToTop';
import {ChevronIcon} from '@src/components/common/icons';
import {yandexMetrika} from '@src/shared/utils/yandex-metrika';

import {
  getIndexOnLocalStorage,
  useItemFiltersFromLocalStorage,
} from '../../../pages/products/utils/get-selected-filter-on-local-storage';
import {useStore} from '../../../stores';
import {Button, Input, Typography} from '../..';

import {useEmotionWrapper} from './pagintaion.s';

export interface SharedPaginationProps {
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (pageNumber: number) => void;
}

export type PaginationProps = SharedPaginationProps &
  Omit<React.ComponentPropsWithoutRef<'div'>, keyof SharedPaginationProps>;

export const Pagination: FC<SharedPaginationProps> = observer(({totalItems, itemsPerPage, onPageChange}) => {
  const {classes} = useEmotionWrapper();
  const {mpAccounts, mpItems} = useStore();
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [currentPage, setCurrentPage] = useState<number | string>(1);
  const {getSelectedFilters, setFiltersOnLocalStorage} = useItemFiltersFromLocalStorage(mpAccounts.current?.id);

  const handlePageChange = (page: number | string) => {
    scrollToTop({blockId: 'content'});
    setCurrentPage(Number(page) < 1 ? 1 : Number(page));
    onPageChange(Number(page));
    const localStorageSelectedFilters = getSelectedFilters();
    const findIndex =
      mpAccounts.current?.id && getIndexOnLocalStorage(localStorageSelectedFilters, mpAccounts.current?.id);
    if (findIndex !== -1 && findIndex !== undefined) {
      localStorageSelectedFilters[findIndex].currentPage = page;
      setFiltersOnLocalStorage(localStorageSelectedFilters);
    } else {
      localStorageSelectedFilters.push({
        mpAccountId: mpAccounts.current?.id,
        vendors: mpItems.filters.selected.vendors,
        shops: mpItems.filters.selected.shops,
        categories: mpItems.filters.selected.categories,
        groups: mpItems.filters.selected.groups,
        search: mpItems.filters.selected.search,
        currentPage: page,
      });
      setFiltersOnLocalStorage(localStorageSelectedFilters);
    }
  };

  useEffect(() => {
    const localStorageSelectedFilters = getSelectedFilters();
    const findIndex = getIndexOnLocalStorage(localStorageSelectedFilters, mpAccounts.current?.id);
    const currentPageOnLocalStorage = localStorageSelectedFilters[findIndex]?.currentPage;
    if (findIndex !== -1 && currentPageOnLocalStorage) {
      if (Number(currentPageOnLocalStorage) > totalPages) {
        handlePageChange(1);
      }
      handlePageChange(currentPageOnLocalStorage);
    } else {
      setCurrentPage(1);
      onPageChange(1);
    }
  }, [mpAccounts.current?.id]);

  useEffect(() => {
    if (mpItems.count !== undefined && mpItems.pageParams.offset > mpItems.count) {
      handlePageChange(1);
    }
  }, [mpItems.count]);

  return (
    <div className={classes.root}>
      <Button
        onClick={() => {
          handlePageChange(Number(currentPage) - 1);
          yandexMetrika().targetFulfilled.pagination();
        }}
        disabled={Number(currentPage) <= 1}
        color={COLORS.GRAY_100}
        variant="contained"
        icon={<ChevronIcon color={COLORS_REDESIGN.DARK_NIGHT_BLUE} style={{transform: 'rotate(180deg)'}} />}
      />
      <div className={classes.pageCountText}>
        <Typography>Страница</Typography>
        <Input
          height={26}
          onChange={(e) => {
            handlePageChange(e.target.value);
            yandexMetrika().targetFulfilled.pagination();
          }}
          value={currentPage}
          width={totalPages > 999 ? 48 : 40}
        />
        <Typography weight="bold" size={12}>
          {`из ${totalPages}`}
        </Typography>
      </div>
      <Button
        onClick={() => {
          handlePageChange(Number(currentPage) + 1);
          yandexMetrika().targetFulfilled.pagination();
        }}
        disabled={Number(currentPage) >= totalPages}
        color={COLORS.GRAY_100}
        variant="contained"
        icon={<ChevronIcon color={COLORS_REDESIGN.DARK_NIGHT_BLUE} />}
      />
    </div>
  );
});
