import {FC} from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';
import {insertSeparatorToNumberOrString} from '@src/shared/utils/insert-separator-to-number-or-string';

import {useEmotionWrapper} from './recommended-price.s';

interface IRecommendedPrice {
  recommendedPrice: number | null;
  itemId: number;
  handleClickApplyRecommendations: () => void;
  isLoading: boolean;
}

export const RecommendedPrice: FC<IRecommendedPrice> = ({
  recommendedPrice,
  handleClickApplyRecommendations,
  isLoading,
}) => {
  const {classes} = useEmotionWrapper();

  if (!recommendedPrice) return null;

  return (
    <div className={classes.root}>
      <MTypography classes={{root: classes.price}}>{insertSeparatorToNumberOrString(recommendedPrice)} ₽</MTypography>
      <LoadingButton
        loading={isLoading}
        onClick={handleClickApplyRecommendations}
        classes={{root: classes.button}}
        variant="contained"
      >
        Применить цену
      </LoadingButton>
    </div>
  );
};
