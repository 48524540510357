import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import LoadingButton from '@mui/lab/LoadingButton';
import {Divider, IconButton, Slide, Snackbar} from '@mui/material';
import {CloseIcon} from '@src/components/common/icons/components/close';
import {MButton} from '@src/components/redesign/mui-components/common/button/button';
import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';
import {useCurrentTariff} from '@src/hooks/useCurrenTariff';
import {PRICE_COMPETITIVENESS_TYPE} from '@src/shared/enums/price-competitiveness-type';
import {REPRICER_STRATEGY} from '@src/shared/enums/repricer-strategy';
import {TARIFFS_TYPES} from '@src/shared/enums/tariffs';
import {yandexMetrika} from '@src/shared/utils/yandex-metrika';
import {useStore} from '@src/stores';
import {TPriceCompetitiveness} from '@src/stores/market-place-account-item/types';
import dayjs from 'dayjs';

import {CompetitivenessItem} from './competitiveness-item/competitiveness-item';
import {usePriceCompetitiveness} from './hooks/use-price-competitiveness';

import {useEmotionWrapper} from './price-competitiveness.s';

export const PriceCompetitiveness = observer(() => {
  const {classes} = useEmotionWrapper();
  const {mpItems, mpAccounts, repricer, user} = useStore();
  const {currentTariff} = useCurrentTariff();
  const navigate = useNavigate();

  const [isShowNotification, setIsShowNotification] = useState(false);
  const [priceCompetitiveness, setPriceCompetitiveness] = useState<TPriceCompetitiveness[]>([]);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const {currentDay, lastSeenFromLocalStorage, setNowLastSeenInLocalStorage} = usePriceCompetitiveness();

  useEffect(() => {
    setIsShowNotification(false);
    const fetchPriceCompetitiveness = async () => {
      if (mpAccounts.current?.id && !isShowNotification) {
        const data = await mpItems.getPriceCompetitiveness(mpAccounts.current.id);
        setPriceCompetitiveness(data || []);
        checkNotificationConditions(data || []);
      }
    };

    const timeoutId = setTimeout(fetchPriceCompetitiveness, 40000);

    return () => clearTimeout(timeoutId);
  }, [mpAccounts.current?.id]);

  const checkNotificationConditions = (competitivenessData: TPriceCompetitiveness[]) => {
    const now = dayjs();

    if (competitivenessData.length === 0) {
      setIsShowNotification(false);
      return;
    }

    if (!lastSeenFromLocalStorage && mpAccounts.current?.id && competitivenessData.length > 0 && !user.isNewUser) {
      if (currentDay >= 1 && currentDay <= 6) {
        setIsShowNotification(true);
        // setNowLastSeenInLocalStorage();
        return;
      }
    } else {
      const lastSeenDate = dayjs(lastSeenFromLocalStorage);
      const startOfCurrentWeek = now.startOf('week');

      if (currentDay === 1 && lastSeenDate.isBefore(startOfCurrentWeek)) {
        if (!isShowNotification) {
          setIsShowNotification(true);
          // setNowLastSeenInLocalStorage();
        }
        return;
      } else if (currentDay !== 1 && lastSeenDate.isBefore(startOfCurrentWeek)) {
        if (!isShowNotification) {
          setIsShowNotification(true);
          // setNowLastSeenInLocalStorage();
        }
        return;
      }
    }
  };

  const closeNotification = () => {
    setIsShowNotification(false);
    setNowLastSeenInLocalStorage();
  };

  const onClickAddStrategy = async () => {
    yandexMetrika().targetFulfilled.addFromPriceCompetitiveness();

    if (mpAccounts.current?.id) {
      try {
        setIsLoadingButton(true);
        const newStrategy = await repricer.createStrategy({
          marketPlaceAccountId: mpAccounts.current?.id,
          name: `Рекомендации цен ${dayjs().format('YYYY-MM-DD HH:mm')}`,
          enabled: true,
          type: REPRICER_STRATEGY.DEFAULT,
          isAuto: false,
          minPricePct: null,
          maxPricePct: null,
          params: {
            place: null,
          },
        });

        navigate(`/repricer/${newStrategy.strategyId}?create=true&priceCompetitivenessTypes=LOW`);

        closeNotification();
      } catch (e) {
        console.log('e', e);
      } finally {
        setIsLoadingButton(false);
      }
    }
  };

  return (
    <Snackbar
      open={isShowNotification}
      autoHideDuration={6000}
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      TransitionComponent={Slide}
      ContentProps={{classes: {root: classes.root}}}
      message={
        <div className={classes.contentRoot}>
          <div className={classes.content}>
            <MTypography className={classes.title}>Рекомендации цен</MTypography>
            <IconButton onClick={closeNotification}>
              <CloseIcon />
            </IconButton>
          </div>
          <MTypography className={classes.description}>
            Мы проанализировали цены на ваши товары и шансы <br /> на софинансирование скидки от Яндекс Маркета
          </MTypography>
          <div className={classes.competitivenessContainer}>
            {priceCompetitiveness.map((item) => (
              <CompetitivenessItem key={item.priceCompetitiveness} item={item} />
            ))}
          </div>
          {priceCompetitiveness.find((item) => item.priceCompetitiveness === PRICE_COMPETITIVENESS_TYPE.LOW) &&
            currentTariff.type !== TARIFFS_TYPES.START && (
              <>
                <Divider />
                <MTypography className={classes.repricer}>
                  Добавить товары в Ценообразование для увеличения шанса на скидку Маркета?
                </MTypography>
                <div className={classes.buttonsContainer}>
                  <LoadingButton
                    sx={{width: 160}}
                    variant="contained"
                    loading={isLoadingButton}
                    onClick={onClickAddStrategy}
                  >
                    Добавить
                  </LoadingButton>
                  <MButton onClick={closeNotification} sx={{width: 160}}>
                    Закрыть
                  </MButton>
                </div>
              </>
            )}
        </div>
      }
    />
  );
});
