import {FC, useEffect, useMemo, useState} from 'react';
import {toast} from 'react-toastify';
import {observer} from 'mobx-react-lite';

import {CircularProgress, InputAdornment, TextField} from '@mui/material';
import {Button, Typography} from '@src/components';
import {COLORS} from '@src/shared/const/appPalette';
import {stringToNumberWithDot} from '@src/shared/utils/stringToNumberWithDot';
import {useStore} from '@src/stores';
import {TItemCostPriceHistory} from '@src/stores/market-place-account-item/types';
import {TMapShopType, TProduct} from './types';

import {CostPriceHistory} from './components/cost-price-history';

import {useEmotionWrapper} from './product-price-updater-tooltip.s';

interface IProductPriceUpdaterTooltip {
  isVisible?: boolean;
  product: TProduct;
  updateShopPrice: (priceData: {itemId: number; shopId: number; price: number}) => Promise<void>;
  onCloseTooltip?: () => void;
  currentShopId?: number;
}

const initialProductShops: Map<number, TMapShopType> = new Map();

export const ProductPriceUpdaterTooltip: FC<IProductPriceUpdaterTooltip> = observer(
  ({product, updateShopPrice, isVisible, onCloseTooltip, currentShopId}) => {
    const {classes} = useEmotionWrapper();
    const {mpAccounts, mpItems} = useStore();
    const [productShops, setProductShops] = useState<Map<number, TMapShopType>>(initialProductShops);
    const [basePrice, setBasePrice] = useState('');

    const [costPriceHistory, setCostPriceHistory] = useState<TItemCostPriceHistory[]>([]);
    const [newCostPrice, setNewConstPrice] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCostPrice, setIsLoadingCostPrice] = useState(false);

    const isOnlyDefaultPriceEnabled = mpAccounts.current?.onlyDefaultPriceEnabled;

    const getCostPrice = async () => {
      setIsLoadingCostPrice(true);
      try {
        const res = await mpItems.getItemCostPriceHistory(product.itemId);
        setCostPriceHistory(res);
        setNewConstPrice(res[0].costPrice);
      } catch (e) {
        null;
      } finally {
        setIsLoadingCostPrice(false);
      }
    };

    useEffect(() => {
      const newProductShops = new Map();
      product.shops.forEach((shop) => {
        newProductShops.set(shop.shopId, {
          ...shop,
        });
      });
      setProductShops(newProductShops);
      setBasePrice(product.price.toString());
    }, [JSON.stringify(product.shops), product.itemId, isVisible]);

    useEffect(() => {
      if (isVisible) {
        getCostPrice();
      }

      return () => {
        setCostPriceHistory([]);
        setNewConstPrice('');
      };
    }, [product.itemId, isVisible]);

    const isNotChangedShopData = useMemo(() => {
      const isNotChangedPrice = product.shops.every(
        (shop) => shop.price === stringToNumberWithDot(productShops.get(shop.shopId)?.price || 0)
      );
      const isNotChangedMinPrice = product.shops.every(
        (shop) => shop.minPrice === stringToNumberWithDot(productShops.get(shop.shopId)?.minPrice || 0)
      );
      const isNotChangedMaxPrice = product.shops.every(
        (shop) =>
          stringToNumberWithDot(shop.maxPrice || 0) ===
          stringToNumberWithDot(productShops.get(shop.shopId)?.maxPrice || 0)
      );
      const isChangedMargin = product.shops.every(
        (shop) =>
          stringToNumberWithDot(shop.margin || 0) === stringToNumberWithDot(productShops.get(shop.shopId)?.margin || 0)
      );

      const isNotChangedProductPrice = stringToNumberWithDot(product.price) === stringToNumberWithDot(basePrice);

      return (
        isNotChangedPrice && isNotChangedMinPrice && isChangedMargin && isNotChangedProductPrice && isNotChangedMaxPrice
      );
    }, [product.shops, productShops, basePrice]);

    const onSaveUpdatedPrices = async () => {
      setIsLoading(true);
      const requests: Promise<void>[] = [];

      const formattedProductPrice = stringToNumberWithDot(product.price);

      productShops.forEach((shop) => {
        const findShopInStore = product.shops.find((s) => s.shopId === shop.shopId);
        const formattedShopPrice = stringToNumberWithDot(shop.price);
        const formattedMargin = shop.margin !== null ? stringToNumberWithDot(shop.margin) : undefined;

        const isMarginDifferentFromShopMargin = findShopInStore && formattedMargin !== findShopInStore.margin;
        const isPriceDifferentFromShopPrice = findShopInStore && formattedShopPrice !== findShopInStore.price;

        if (isPriceDifferentFromShopPrice && formattedShopPrice > 0) {
          requests.push(
            updateShopPrice({itemId: product.itemId, shopId: shop.shopId, price: formattedShopPrice * 100})
          );
        }

        if (isMarginDifferentFromShopMargin && formattedMargin !== undefined) {
          requests.push(
            mpItems.updateItemMarginInShop({
              itemId: product.itemId,
              shopId: shop.shopId,
              margin: formattedMargin,
            })
          );
        }
      });

      if (formattedProductPrice !== stringToNumberWithDot(basePrice) && stringToNumberWithDot(basePrice) > 0) {
        requests.push(mpItems.updateItemPrice(product.itemId, stringToNumberWithDot(basePrice) * 100));
      }

      if (costPriceHistory[0]?.costPrice !== stringToNumberWithDot(newCostPrice)) {
        requests.push(
          mpItems.updateItem({itemId: product.itemId, costPrice: stringToNumberWithDot(newCostPrice) * 100})
        );
      }

      Promise.all(requests)
        .then(() => {
          mpItems.getItemsByAccountId({marketPlaceAccountId: mpAccounts.current?.id, isHiddenLoading: true});
        })
        .then(() => {
          onCloseTooltip && onCloseTooltip();
          requests.length > 0 && toast.success('Данные обновлены');
        })
        .catch(() => toast.error('Не удалось обновить цены'))
        .finally(() => setIsLoading(false));
    };

    const onChangePrice = (shopId: number, price: string) => {
      const newProductShops = new Map([...productShops]);
      const shopById = productShops.get(shopId);

      if (shopById) {
        newProductShops.set(shopId, {...shopById, price: price});
      }

      setProductShops(newProductShops);
    };

    const onChangeMargin = (shopId: number, margin: string) => {
      const newProductShops = new Map(productShops);
      const shopById = productShops.get(shopId);

      if (shopById) {
        newProductShops.set(shopId, {...shopById, margin: margin});
      }

      setProductShops(newProductShops);
    };

    const renderShopRow = (s: TMapShopType) => {
      return (
        <tr key={s.shopId}>
          <td align="left">
            <Typography paragraph whiteSpace="nowrap" className={classes.shopName}>
              {s.shopName}
            </Typography>{' '}
          </td>
          {!isOnlyDefaultPriceEnabled ? (
            <td align="right">
              <TextField
                sx={{width: 90}}
                size="small"
                placeholder={product.price.toString()}
                disabled={isLoading}
                onChange={(e) => {
                  onChangePrice(s.shopId, e.target.value);
                }}
                value={s.price}
              />
            </td>
          ) : null}
          <td align="right">
            <TextField
              sx={{width: 90}}
              size="small"
              placeholder={'0'}
              disabled={isLoading}
              onChange={(e) => {
                onChangeMargin(s.shopId, e.target.value);
              }}
              value={s.margin ? s.margin : ''}
            />
          </td>
        </tr>
      );
    };

    const geTableBody = () => {
      if (currentShopId) {
        const currentShop = productShops.get(currentShopId);
        if (currentShop) {
          return renderShopRow(currentShop);
        }
      } else {
        return Array.from(productShops.values()).map((shop) => {
          return renderShopRow(shop);
        });
      }
    };

    return (
      <div className={classes.root}>
        <Typography paragraph whiteSpace="nowrap" className={classes.productName} color={COLORS.DARK_70}>
          {product.name}
        </Typography>
        <br />
        <div style={{display: 'flex', gap: 20, flexDirection: 'column'}}>
          <div style={{display: 'flex', alignItems: 'center', gap: 10, marginBottom: 10}}>
            <TextField
              label="Базовая цена"
              slotProps={{
                input: {
                  endAdornment: <InputAdornment position="end">₽</InputAdornment>,
                },
              }}
              sx={{width: 160}}
              size="small"
              value={basePrice}
              onChange={(e) => setBasePrice(e.target.value)}
            />
          </div>
          <div style={{display: 'flex', gap: 10, alignItems: 'center', marginBottom: 10}}>
            <TextField
              label="Себестоимость"
              slotProps={{
                input: {
                  endAdornment: <InputAdornment position="end">₽</InputAdornment>,
                },
              }}
              sx={{width: 160}}
              size="small"
              value={newCostPrice}
              onChange={(e) => setNewConstPrice(e.target.value)}
            />
            {isLoadingCostPrice ? <CircularProgress size={12} /> : <CostPriceHistory history={costPriceHistory} />}
          </div>
        </div>

        <table className={classes.tableRoot}>
          <thead>
            <tr>
              <th align="left">Магазин</th>
              {!mpAccounts.current?.onlyDefaultPriceEnabled ? <th align="center">Цена, ₽</th> : null}
              <th align={isOnlyDefaultPriceEnabled ? 'center' : 'right'}>Маржинальность, %</th>
            </tr>
          </thead>
          <tbody>{geTableBody()}</tbody>
        </table>
        <Button
          loading={isLoading}
          disabled={isNotChangedShopData || isLoading}
          onClick={onSaveUpdatedPrices}
          variant="outlined"
          color={COLORS.GREEN_100}
        >
          Сохранить
        </Button>
      </div>
    );
  }
);
