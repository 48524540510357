import {COLORS_REDESIGN} from '@shared/const/appPalette';
import {emotionWrapper} from '@shared/lib';

interface ITypographyStyle {
  color?: string;
  align?: 'left' | 'center' | 'right' | 'justify';
  size?: string | number;
  weight?: 'normal' | 'bold' | 'lighter' | 'bolder' | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-wrap' | 'pre-line' | 'break-spaces';
  paragraph?: boolean;
  italic?: boolean;
  variant?: 'primary' | 'secondary' | 'titleSecondary';
  cursor: 'pointer' | 'default' | 'not-allowed' | 'inherit' | 'text' | 'none' | 'auto';
}

const getVariant = (variant: string) => {
  if (variant === 'primary') {
    return {
      fontSize: 14,
      fontWeight: 600,
      color: COLORS_REDESIGN.DARK_NIGHT_BLUE,
    };
  }
};

export const useEmotionWrapper = emotionWrapper(
  ({
    color = '#1D2940',
    align = 'left',
    size = 14,
    weight = 400,
    whiteSpace = 'normal',
    paragraph = false,
    italic = false,
    variant = 'primary',
  }: ITypographyStyle) => ({
    root: {
      ...getVariant(variant),
      color,
      textAlign: align,
      fontSize: size,
      fontWeight: weight,
      whiteSpace,
      display: paragraph ? 'block' : 'inline',
      fontStyle: italic ? 'italic' : 'normal',
    },
  })
);
