import {FC} from 'react';

import {MButton} from '@src/components/redesign/mui-components/common/button/button';

import {useEmotionWrapper} from './price-button.s';

interface IPriceButton {
  children?: React.ReactNode;
}

export const PriceButton: FC<IPriceButton> = ({children, ...props}) => {
  const {classes} = useEmotionWrapper();
  return (
    <MButton className={classes.root} {...props}>
      {children}
    </MButton>
  );
};
