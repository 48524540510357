import {COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {emotionWrapper} from '@src/shared/lib';

export const useEmotionWrapper = emotionWrapper({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: 10,
    justifyContent: 'center',
  },
  root: {
    width: 390,
    height: 299,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
    flexDirection: 'column',
    backgroundColor: COLORS_REDESIGN.LIGHT_GREY,
    borderRadius: 8,
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
      border: `2px dashed ${COLORS_REDESIGN.BRAND}`,
    },
  },
  selectFileLabel: {
    color: COLORS_REDESIGN.BRAND,
    display: 'inline',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    flexDirection: 'column',
  },
});
