import {observer} from 'mobx-react-lite';

import {LimitedAccount} from '@src/components/specific/notifications/limited-account/limited-account';
import {PriceMonitoringNotify} from '@src/components/specific/notifications/price-monitoring-notify/price-monitoring-notify';
import {TariffNotification} from '@src/components/specific/notifications/tariff-notification';
import {TrialOfferNotification} from '@src/components/specific/notifications/trial-offer-notification/trial-offer-notification';
import {useMediaQuery} from '@src/hooks/useMediaQuery';
import {useSocketSubscribe} from '@src/hooks/useSocketSubscribe';
import {WEBSOCKET_ACTION_TYPE, WEBSOCKET_CHANNELS} from '@src/shared/const/websocket';
import {useStore} from '@src/stores';

import {AccountIsUpdatingNotify} from '../account-is-updating-notify/account-is-updating-notify';
import {PriceCompetitiveness} from '../price-competitiveness/price-competitiveness';

type TAccountReloadWs = {
  data: {
    id: number;
    is_updating: boolean;
    updated_at: string;
  };
};

export const NotificationsManager = observer(() => {
  const {mpAccounts} = useStore();
  const {isScreenNarrow} = useMediaQuery();

  useSocketSubscribe<TAccountReloadWs>({
    socketChannel: WEBSOCKET_CHANNELS.PRIVATE,
    actionType: WEBSOCKET_ACTION_TYPE.ACCOUNT_RELOAD,
    eventHandler: (e) => {
      mpAccounts.updateReloadStatusMpAccount(e.data.id, {
        is_updating: e.data.is_updating,
        updated_at: e.data.updated_at,
      });
    },
  });

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: 1}}>
      <LimitedAccount />
      <TariffNotification />
      <PriceMonitoringNotify />
      <TrialOfferNotification />
      <AccountIsUpdatingNotify />
      {!isScreenNarrow && <PriceCompetitiveness />}
    </div>
  );
});
