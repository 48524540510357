export const RESOURCE_URLS = {
  INSTRUCTION: 'https://app.weeek.net/ws/677601/document/60',
  CONSULTATION: 'https://my.smartseller.me/demo',
  SUPPORT_TELEGRAM: 'https://t.me/smartsellermemp',
  INTEGRATION_FAQ: 'https://app.weeek.net/ws/677601/document/67',
  OFERTA: 'https://my.smartseller.me/oferta',
  TELEGRAM_CHANEL: 'https://t.me/smartsellerme',
  BUSINESS_CARD_PAYMENT_POST: 'https://app.weeek.net/ws/677601/document/68',
  PRAVO: 'https://my.smartseller.me/pravo',
  IBEREG_UTM_URL: 'https://ibereg.ru/?utm_source=partners&utm_medium=ibereg&utm_campaign=pop_up_bidder_ozon',
};
