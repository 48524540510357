export const YandexBlackIcon = ({size = '16', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <g clip-path="url(#clip0_1237_6636)">
        <path
          d="M11.9318 14.4769H9.75177V3.68644H8.7783C6.99588 3.68644 6.06354 4.57765 6.06354 5.9076C6.06354 7.4158 6.70796 8.11505 8.03791 9.00626L9.13478 9.74665L5.98128 14.4769H3.63672L6.47487 10.254C4.84328 9.08853 3.92465 7.95052 3.92465 6.031C3.92465 3.63159 5.59737 2 8.76459 2H11.9181V14.4769H11.9318Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1237_6636">
          <rect width="8.36364" height="12.4769" fill="white" transform="translate(3.63672 2)" />
        </clipPath>
      </defs>
    </svg>
  );
};
