import {useEffect, useRef, useState} from 'react';
import {CSSTransition} from 'react-transition-group';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {IconButton, MenuItem} from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import {useStore} from '@src/stores';

import {useEmotionWrapper} from './current-marketplace-title.s';

export const CurrentMarketplaceTitle = () => {
  const {utils} = useStore();
  const [isOpen, setIsOpen] = useState(false);
  const {classes} = useEmotionWrapper(isOpen);
  const contentRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const overlayAnimation = {
    enter: classes.dropdownEnter,
    enterActive: classes.dropdownEnterActive,
    exit: classes.dropdownExit,
    exitActive: classes.dropdownExitActive,
  };

  return (
    <div ref={containerRef}>
      <div onClick={handleClick} className={classes.yandexMarketTitle}>
        <img draggable={false} src="/png/yandex-market-logo.png" height={24} width={24} />
        <Typography sx={{fontSize: 14, fontWeight: 700}}>Яндекс Маркет</Typography>
        <IconButton sx={{padding: 0}} size="small">
          <ExpandLessIcon
            sx={{transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'all .2s ease-in-out'}}
          />
        </IconButton>
        <CSSTransition
          timeout={200}
          mountOnEnter
          unmountOnExit
          in={isOpen}
          nodeRef={contentRef}
          classNames={overlayAnimation}
        >
          <div className={classes.content} ref={contentRef}>
            <MenuItem divider>
              <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                <img draggable={false} src="/png/yandex-market-logo.png" height={24} width={24} />
                <Typography sx={{fontSize: 14, fontWeight: 700}}>Яндекс Маркет</Typography>
              </div>
            </MenuItem>
            <MenuItem onClick={() => utils.openIberegModal()} divider>
              <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                <img draggable={false} src="/png/ibereg-logo-small.png" height={24} width={24} />
                <Typography sx={{fontSize: 14, fontWeight: 500}}>OZON x ibereg</Typography>
              </div>
            </MenuItem>
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};
