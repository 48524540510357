import {FC, useState} from 'react';
import {observer} from 'mobx-react-lite';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {CircularProgress, Tooltip} from '@mui/material';
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import {FaqIcon} from '@src/components/common/icons/components/faq';
import {MButton} from '@src/components/redesign/mui-components/common/button/button';
import {useStrategyContext} from '@src/pages/repricer/hooks/useStrategyContext';
import {COLORS} from '@src/shared/const/appPalette';

interface IFastActionButton {
  countSelectedFilters: number;
}

export const FastActionButton: FC<IFastActionButton> = observer(({countSelectedFilters}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isLoadingAdd, setIsLoadingAdd] = useState<boolean>(false);
  const {addItemsBySelectedFilters, deleteAllProducts} = useStrategyContext();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const addItemsBySelectedFiltersHandler = async () => {
    setIsLoadingAdd(true);
    await addItemsBySelectedFilters().finally(() => setIsLoadingAdd(false));
  };

  return (
    <>
      <div style={{position: 'relative'}}>
        <MButton variant="text" endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />} onClick={handleClick}>
          Быстрые действия
        </MButton>
        <Tooltip
          arrow
          title="Добавляйте сразу все товары кабинета или выберите нужные товары по фильтрам и добавьте их одним кликом. Или можно очистить список добавленных товаров кнопкой Удалить все"
        >
          <div style={{position: 'absolute', top: 8, right: -16}}>
            <FaqIcon color={COLORS.BLACK_15} />
          </div>
        </Tooltip>
      </div>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={addItemsBySelectedFiltersHandler}>
          <div style={{display: 'flex', alignItems: 'center', gap: 5}}>
            Добавить {countSelectedFilters > 0 ? 'по фильтрам' : 'все'}{' '}
            {isLoadingAdd ? <CircularProgress size={18} /> : <div style={{width: 18, height: 18}} />}
          </div>
        </MenuItem>
        <MenuItem onClick={deleteAllProducts}>Убрать все</MenuItem>
      </Menu>
    </>
  );
});
