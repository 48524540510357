import {TMpAccountItem} from '@src/stores/market-place-account-item/types';

export const updateMaxPriceWithPercentOnShops = ({
  products,
  maxPricePct,
}: {
  products: TMpAccountItem[];
  maxPricePct: number | null;
}) => {
  return products.map((product) => {
    return {
      ...product,
      shops: product.shops.map((s) => {
        return {
          ...s,
          maxPrice:
            maxPricePct === s.maxPrice ? null : ((s.price ? s.price : product.price) * (maxPricePct || 100)) / 100,
        };
      }),
    };
  });
};

export const updateMinPriceWithPercentOnShops = ({
  products,
  minPricePct,
}: {
  products: TMpAccountItem[];
  minPricePct: number | null;
}) => {
  return products.map((product) => {
    return {
      ...product,
      shops: product.shops.map((s) => {
        return {
          ...s,
          minPrice:
            minPricePct === s.minPrice ? null : ((s.price ? s.price : product.price) * (minPricePct || 100)) / 100,
        };
      }),
    };
  });
};
