import {emotionWrapper} from '@shared/lib';
import {COLORS} from '@src/shared/const/appPalette';

export const useEmotionWrapper = emotionWrapper(() => ({
  settingButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 12px',
    borderRadius: 8,
    border: `1px ${COLORS.DARK_20} solid`,
    cursor: 'pointer',
    outline: 'none',
    backgroundColor: 'transparent',
    svg: {
      color: COLORS.DARK_50,
      '@media (max-width: 1440px)': {
        display: 'none',
      },
    },
    '&:disabled': {
      cursor: 'not-allowed',
    },

    '&:not(:disabled)': {
      '&:hover': {
        transition: 'all .3s ease',
        border: `1px ${COLORS.PURPLE_100} solid`,
        boxShadow: `0 0 8px ${COLORS.DARK_20}`,
        svg: {
          color: COLORS.PURPLE_100,
        },
        span: {
          color: COLORS.PURPLE_100,
        },
      },
    },
  },
  buttonText: {
    fontWeight: 500,
    textWrap: 'nowrap',
    fontSize: 14,
  },
}));
