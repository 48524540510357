import {Navigate, useLocation} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import {getRouteLogin} from '@src/shared/const/router';
import {useStore} from '@src/stores';

interface IRequireAuth {
  children: JSX.Element;
}

export const RequireAuth = observer(({children}: IRequireAuth) => {
  const {user} = useStore();
  const location = useLocation();

  // const {roles} = useStore();

  // const hasRequiredRoles = useMemo(() => {
  //   if (!roles) {
  //     return true;
  //   }

  //   return roles.some((requiredRole) => {
  //     const hasRole = userRoles?.includes(requiredRole);
  //     return hasRole;
  //   });
  // }, [roles, userRoles]);

  if (!user.isAuth) {
    return <Navigate to={getRouteLogin()} state={{from: location}} replace />;
  }

  // if (!hasRequiredRoles) {
  //   return <Navigate to={getRouteForbidden()} state={{from: location}} replace />;
  // }

  return children;
});
