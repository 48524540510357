import {FC, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import {IconButton, Typography} from '@src/components';
import {useCurrentTariff} from '@src/hooks/useCurrenTariff';
import {useCurrentMpAccount} from '@src/hooks/useCurrentMarketPlaceAccount';
import {useShopInfoById} from '@src/hooks/useShopInfoById';
import {COLORS} from '@src/shared/const/appPalette';
import {getRoutePricing} from '@src/shared/const/router';
import {TARIFFS_TYPES} from '@src/shared/enums/tariffs';
import {useStore} from '@src/stores';
import {TProductLinks, TProductOtherLink, TShop} from './types';

import {CreateAndEditLinksBlock} from './components/create-and-edit-links-block/create-and-edit-links-block';
import {LinksBlock} from './components/links-block/links-block';
import {getEditProductCardLink} from '../../../shared/utils/get-edit-product-card-link';
import {getOffersLink} from '../../../shared/utils/get-offers-link';
import {getProductLink} from '../../../shared/utils/get-product-link';
import {CloseIcon} from '../../common/icons';

import {useEmotionWrapper} from './product-links-tooltip.s';

interface ProductLinksTooltip {
  productName: string;
  productId: number;
  productSku: string;
  productShops: TShop[];
  marketProductId: number | null;
  marketSku: number | null;
  isAnalyticsTooltipVisible: boolean;
}

const initialProductLinks = {
  editLink: null,
  offersLink: null,
  productLink: null,
  otherLinks: [],
};

export const ProductLinksTooltip: FC<ProductLinksTooltip> = observer(
  ({productName, productId, productShops, marketSku, marketProductId, productSku, isAnalyticsTooltipVisible}) => {
    const navigate = useNavigate();

    const {mpItems} = useStore();
    const {currentTariff} = useCurrentTariff();
    const [isDisabled, setIsDisabled] = useState(false);
    const {classes} = useEmotionWrapper({isDisabled});

    const [isEditMode, setIsEditMode] = useState(false);
    const [isLoadingOtherLinks, setIsLoadingOtherLinks] = useState(false);
    const [currentOtherLink, setCurrentOtherLink] = useState<TProductOtherLink | null>(null);
    const [productLinks, setProductLinks] = useState<TProductLinks>(() => initialProductLinks);

    const currentShop = useShopInfoById();
    const currentMpAccount = useCurrentMpAccount();
    //@ts-ignore
    const currentMarketShopId = currentShop(mpItems.filters.shops[0])?.marketShopId;

    const loadOtherLinks = async () => {
      if (currentMpAccount.id) {
        try {
          setIsLoadingOtherLinks(true);
          const links = await mpItems.getItemLinks(productId, currentMpAccount.id);
          setProductLinks({
            ...productLinks,
            otherLinks: links,
          });
        } catch (error) {
          console.log('error_get_links', error);
        } finally {
          setIsLoadingOtherLinks(false);
        }
      }
    };

    useEffect(() => {
      if (currentMpAccount.id) {
        setIsDisabled(currentTariff.type === TARIFFS_TYPES.START);
      }
    }, [currentMpAccount.id, currentTariff.id]);

    useEffect(() => {
      const productLink = getProductLink(marketProductId, marketSku);
      const offersLink = getOffersLink(marketProductId, marketSku);
      const editLink = getEditProductCardLink(productSku, currentMarketShopId);

      setProductLinks({
        ...productLinks,
        productLink: productLink,
        offersLink: offersLink,
        editLink: editLink,
      });
    }, [marketSku, marketProductId]);

    useEffect(() => {
      if (!isEditMode) {
        setCurrentOtherLink(null);
      }
    }, [isEditMode]);

    useEffect(() => {
      if (isAnalyticsTooltipVisible && currentMpAccount.id) {
        loadOtherLinks();
      }
    }, [productId, isAnalyticsTooltipVisible]);

    const onChangeCurrentLink = (link: TProductOtherLink) => {
      setCurrentOtherLink(link);
      setIsEditMode(true);
    };

    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <div className={classes.titleContainer}>
            <div className={classes.leftBlock}>
              <Typography whiteSpace="nowrap" color={COLORS.DARK_70} size={14} weight={600}>
                Быстрые ссылки
              </Typography>
            </div>
            {isEditMode && (
              <div className={classes.rightBlock}>
                <Typography color={COLORS.DARK_70} size={14} weight={600}>
                  Пользовательские ссылки
                </Typography>
                <IconButton color="error" size={14} onClick={() => setIsEditMode(false)}>
                  <CloseIcon />
                </IconButton>
              </div>
            )}
          </div>
        </div>
        <div className={classes.content}>
          <div className={classes.contentBlock}>
            <LinksBlock
              isLoadingOtherLinks={isLoadingOtherLinks}
              productLinks={productLinks}
              productName={productName}
              isDisabled={isDisabled}
              toggleEditMode={() => setIsEditMode((prev) => !prev)}
              onChangeCurrentLink={onChangeCurrentLink}
              productShops={productShops}
            />
          </div>
          {isEditMode && <div className={classes.blockDelimiter} />}
          {isEditMode && (
            <CreateAndEditLinksBlock
              onToggleEditMode={() => setIsEditMode((prev) => !prev)}
              productId={productId}
              currentOtherLink={currentOtherLink}
              loadOtherLinks={loadOtherLinks}
            />
          )}
        </div>
        {isDisabled && (
          <Typography size={12}>
            Доступно в платном тарифе{' '}
            <span className={classes.openTariffModalButton} onClick={() => navigate(getRoutePricing())}>
              Оплатить
            </span>
          </Typography>
        )}
      </div>
    );
  }
);
