import {FC, ReactNode} from 'react';

import {COLORS_REDESIGN} from '@shared/const/appPalette';
import {CloseIcon} from '@src/components/common/icons';

import {useMount} from './hooks/useMount';
import {Layout} from './layout/layout';
import {IconButton, Portal, Typography} from '../..';

import {useEmotionWrapper} from './modal.s';

interface IModal {
  opened: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string | JSX.Element;
  actionButton?: JSX.Element;
  isCloseOnClickOverlay?: boolean;
  icon?: JSX.Element;
  hideCloseButton?: boolean;
  hideTitle?: boolean;
}

export const Modal: FC<IModal> = ({
  opened,
  onClose,
  children,
  title,
  actionButton,
  icon,
  isCloseOnClickOverlay = true,
  hideCloseButton = false,
  hideTitle = false,
  ...props
}) => {
  const {classes} = useEmotionWrapper();
  const {mounted} = useMount({opened});

  if (!mounted || !opened) {
    return null;
  }

  return (
    <Portal>
      <Layout onClose={isCloseOnClickOverlay ? onClose : () => {}} opened={opened} {...props}>
        <div style={{marginBottom: hideTitle ? 0 : 13}} className={classes.titleContainer}>
          {!hideTitle && (
            <div className={classes.title}>
              {icon}
              <Typography size={20} weight={700} color={COLORS_REDESIGN.DARK_NIGHT_BLUE}>
                {title}
              </Typography>
            </div>
          )}
          <div style={{marginLeft: hideTitle ? 'auto' : 0}} className={classes.actionButtonBlock}>
            {actionButton}
            {!hideCloseButton && (
              <IconButton size={16} onClick={onClose}>
                <CloseIcon color={COLORS_REDESIGN.DARK_NIGHT_BLUE} />
              </IconButton>
            )}
          </div>
        </div>
        <div style={{paddingBottom: 3}} className={classes.contentContainer}>
          {children}
        </div>
      </Layout>
    </Portal>
  );
};
