import {type FC} from 'react';
import {observer} from 'mobx-react-lite';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EastIcon from '@mui/icons-material/East';
import UploadIcon from '@mui/icons-material/Upload';
import {Button, CircularProgress} from '@mui/material';
import {FaqBlockWrapper} from '@src/components';
import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';
import {useStrategyContext} from '@src/pages/repricer/hooks/useStrategyContext';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {declOfNum} from '@src/shared/utils/declOfNum';
import {useStore} from '@src/stores';

import {useEmotionWrapper} from './stepper-buttons.s';

interface IStepperButtonsProps {
  currentStep: number;
  setCurrentStepHandler: (step: number) => void;
  isLoadingNextStep: boolean;
  countAddedProducts: number;
  onOpenUploadProductModal: () => void;
}

export const StepperButtons: FC<IStepperButtonsProps> = observer(
  ({currentStep, setCurrentStepHandler, isLoadingNextStep, countAddedProducts, onOpenUploadProductModal}) => {
    const {classes} = useEmotionWrapper();
    const {repricer} = useStore();
    const {strategy, deleteProductIds} = useStrategyContext();

    const getCountItems = () => {
      if (currentStep === 0) {
        const initialItemIds = repricer.summaryRepricerItemIds.get(strategy.id.toString())?.itemIds;
        return (initialItemIds?.length || 0) - deleteProductIds.length + countAddedProducts;
      }
    };

    return (
      <>
        <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
          <MTypography className={classes.subTitle}>
            {currentStep === 0 ? 'Добавьте товары в стратегию' : 'Настройка стратегии'}
          </MTypography>
          {currentStep === 0 && (
            <MTypography sx={{fontSize: 12, color: COLORS_REDESIGN.NIGHT_GREY}}>
              Добавлено {declOfNum(getCountItems() || 0, ['товар', 'товара', 'товаров'])}
            </MTypography>
          )}
        </div>
        {currentStep === 1 && (
          <Button
            variant="text"
            className={classes.backButton}
            startIcon={<ArrowBackIcon />}
            onClick={() => setCurrentStepHandler(currentStep - 1)}
          >
            Назад
          </Button>
        )}
        {currentStep === 0 && (
          <div style={{display: 'flex', marginLeft: 'auto', gap: 30}}>
            <FaqBlockWrapper content="Загрузите товары в стратегию с помощью Excel таблицы. При загрузке для товаров сразу можно указать Минимальные и Максимальные пороги цен">
              <Button
                disabled={isLoadingNextStep}
                className={classes.nextStepButton}
                startIcon={<UploadIcon />}
                onClick={onOpenUploadProductModal}
              >
                Загрузить товары
              </Button>
            </FaqBlockWrapper>
            <Button
              disabled={isLoadingNextStep}
              className={classes.nextStepButton}
              endIcon={isLoadingNextStep ? <CircularProgress size={16} /> : <EastIcon />}
              onClick={() => setCurrentStepHandler(currentStep + 1)}
            >
              Настройка товаров
            </Button>
          </div>
        )}
      </>
    );
  }
);
