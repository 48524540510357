import {useEffect} from 'react';
import {observer} from 'mobx-react-lite';

import {useTour} from '@reactour/tour';
import {Button} from '@src/components/common/buttons/button';
import {Typography} from '@src/components/common/typography';
import {MButton} from '@src/components/redesign/mui-components/common/button/button';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {IS_SENDED_USER_CONTACTS} from '@src/shared/const/localStorage';
import {yandexMetrika} from '@src/shared/utils/yandex-metrika';
import {useStore} from '@src/stores';

import {NotificationContainer} from '../notification-container/notification-container';

export const TrialOfferNotification = observer(() => {
  const {utils, mpAccounts} = useStore();
  const {isOpen} = useTour();
  const isTrialTariff = mpAccounts.current?.tariff?.isTrial;
  const isHasPaidTariff = mpAccounts.current?.hadPaidTariff;
  const isSendedUserContacts = localStorage.getItem(IS_SENDED_USER_CONTACTS) === 'true';

  useEffect(() => {
    if (!isTrialTariff && !isHasPaidTariff && !isSendedUserContacts && mpAccounts.current?.id && !isOpen) {
      setTimeout(() => {
        utils.openOfferTrialModal();
      }, 2000);
    }
  }, [mpAccounts.current?.id, isOpen]);

  return isTrialTariff || isHasPaidTariff || !mpAccounts.current?.id ? null : (
    <NotificationContainer>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 35, width: '100%'}}>
        <div style={{display: 'flex', alignItems: 'center', gap: 6}}>
          <Typography whiteSpace="nowrap" size={16} weight="bold" color={COLORS_REDESIGN.BRAND}>
            Для включения сервиса оплатите тариф или{' '}
          </Typography>
          <Button
            variant="text"
            onClick={() => {
              yandexMetrika().targetFulfilled.tryFree();
              utils.openOfferTrialModal();
            }}
          >
            <Typography size={16} weight="bold" style={{textDecoration: 'underline'}} color={COLORS_REDESIGN.BRAND}>
              воспользуйтесь демо периодом
            </Typography>
          </Button>
        </div>

        <div style={{display: 'flex', alignItems: 'center', gap: 15}}>
          <MButton
            size="small"
            variant="contained"
            onClick={() => {
              yandexMetrika().targetFulfilled.tryFree();
              utils.openOfferTrialModal();
            }}
          >
            Получить демо доступ
          </MButton>
        </div>
      </div>
    </NotificationContainer>
  );
});
