//FIXME: TypeScript Errors
// @ts-nocheck
import {useRef} from 'react';
import Select, {
  ActionMeta,
  components,
  GroupBase,
  OnChangeValue,
  OptionProps,
  Props as ReactSelectProps,
  PropsValue,
} from 'react-select';

import {COLORS, COLORS_REDESIGN} from '@shared/const/appPalette';
import {limitString} from '@shared/utils/limitString';
import {DropdownFilledIcon} from '@src/components/common/icons';

import {Checkbox} from '../..';

interface ICustomSelect {
  width?: string | number;
  isSelectedOptions?: boolean;
  isCheckboxOptions?: boolean;
  isSelectedAllOptions?: boolean;
  isSelectedAllMessage?: string;
  isBordered?: boolean;
  backgroundColor?: string;
  height?: string | number;
  isUsePortal?: boolean;
  isDisabled?: boolean;
  enableFocusEffect?: boolean;
  isError?: boolean;
}

const SELECT_OPTION_ACTION_TYPE = {
  SELECT: 'select-option',
  DESELECT: 'deselect-option',
  REMOVE: 'remove-value',
};

export function CustomSelect<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  options,
  value,
  onChange,
  width = 170,
  height = '32px',
  isSelectedOptions = false,
  isCheckboxOptions = false,
  isBordered = true,
  backgroundColor = 'white',
  isSelectedAllOptions = false,
  isSelectedAllMessage,
  isUsePortal = true,
  isDisabled = false,
  enableFocusEffect = true,
  isError = false,
  ...rest
}: ReactSelectProps<Option, IsMulti, Group> & ICustomSelect) {
  const OptionCheckbox: React.ComponentType<OptionProps<Option, IsMulti, Group>> | undefined = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <Checkbox checked={props.isSelected} label={limitString(props.label, 40)} />
        </components.Option>
      </div>
    );
  };

  const Option: React.ComponentType<OptionProps<Option, IsMulti, Group>> | undefined = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <span style={{fontSize: 13, fontWeight: 500, color: COLORS_REDESIGN.DARK_NIGHT_BLUE}}>{props.label}</span>
        </components.Option>
      </div>
    );
  };

  const valueRef = useRef<PropsValue<Option> | undefined>(value);

  valueRef.current = value;

  const isSelectAllSelected = () => Array.isArray(valueRef.current) && valueRef.current.length === options?.length;

  const SELECT_ALL_OPTIONS = {
    value: 'SELECT_ALL',
    label: isSelectedAllMessage ? isSelectedAllMessage : 'Выбрать все',
  };
  const onChangeSelect = (newValue: OnChangeValue<Option, IsMulti>, actionMeta: ActionMeta<Option>) => {
    const {action, option, removedValue} = actionMeta;

    const isSelectAction = action === SELECT_OPTION_ACTION_TYPE.SELECT;
    const isDeselectAction = action === SELECT_OPTION_ACTION_TYPE.DESELECT;
    const isRemoveAction = action === SELECT_OPTION_ACTION_TYPE.REMOVE;
    const isSelectAndSelectAll = isSelectAction && option?.value === SELECT_ALL_OPTIONS.value;
    const isDeselectAndSelectAll = isDeselectAction && option?.value === SELECT_ALL_OPTIONS.value;
    const isRemoveAndSelectAll = isRemoveAction && removedValue?.value === SELECT_ALL_OPTIONS.value;
    const isDeselectAndSelectedAll = actionMeta.action === SELECT_OPTION_ACTION_TYPE.DESELECT && isSelectAllSelected();

    if (onChange) {
      if (isSelectAndSelectAll) {
        if (Array.isArray(options)) {
          onChange(options as OnChangeValue<Option, IsMulti>, actionMeta);
        }
      } else if (isDeselectAndSelectAll || isRemoveAndSelectAll) {
        onChange([], actionMeta);
      } else if (isDeselectAndSelectedAll && options) {
        const filteredOptions = options.filter(({value}) => value !== option?.value);
        onChange(filteredOptions, actionMeta);
      } else {
        onChange(newValue || [], actionMeta);
      }
    }
  };

  const isOptionSelected = (option: Option) => {
    if (Array.isArray(valueRef.current)) {
      return valueRef.current.some(({value}) => value === option.value) || isSelectAllSelected();
    }
  };

  const getOptions = () => {
    if (isSelectedAllOptions) {
      return [SELECT_ALL_OPTIONS, ...(options ?? [])];
    } else {
      return options ?? [];
    }
  };

  const getValue = () => (isSelectAllSelected() ? SELECT_ALL_OPTIONS : value);

  return (
    <Select
      {...rest}
      isOptionSelected={(option) => {
        return isSelectedOptions ? isOptionSelected(option) : false;
      }}
      isDisabled={isDisabled}
      options={getOptions()}
      value={getValue()}
      menuPortalTarget={isUsePortal ? document.body : undefined}
      onChange={onChangeSelect}
      theme={(theme) => ({
        ...theme,
        borderRadius: 7,
        colors: {
          ...theme.colors,
          primary: '#cd99e8',
          primary25: '#f7ebff',
          primary50: '#f7ebff',
          dangerLight: '#f7ebff',
          neutral5: COLORS.PURPLE_100,
          neutral10: '#f7ebff',
          neutral50: COLORS.DARK_50,
        },
      })}
      styles={{
        menuPortal: (base) => ({
          ...base,
          // zIndex: 2,
        }),
        singleValue: (base) => ({
          ...base,
          textOverflow: 'none',
          width: '100%',
          textAlign: 'center',
          cursor: 'pointer',
        }),
        control: (provided) => {
          return {
            ...provided,
            width: width,
            minHeight: height,
            height: height,
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            pointerEvents: 'auto',
            fontSize: 13,
            whiteSpace: 'nowrap',
            boxShadow: 'none',
            backgroundColor: backgroundColor ? backgroundColor : COLORS.WHITE,
            borderRadius: 8,
            fontWeight: 500,
            border: isBordered ? (isError ? '1px solid red' : `1px solid ${COLORS.DARK_20}`) : 'none',
            transition: 'none',
            '&:hover': {
              border: enableFocusEffect
                ? isDisabled
                  ? `1px solid ${COLORS.DARK_20}`
                  : `1px solid ${COLORS.PURPLE_100}`
                : 'none',
            },
            '&:focused': {
              border: enableFocusEffect ? `1px solid ${COLORS.PURPLE_100}` : 'none',
              boxShadow: 'none',
            },
          };
        },
        menu(base) {
          return {
            ...base,
            width: 'max-content',
            minWidth: '100%',
          };
        },
        option: (provided, state) => {
          return {
            ...provided,
            backgroundColor:
              state.options.findIndex((option) => option.value === state.value) % 2 === 0 ? COLORS.GRAY_100 : 'white',
            cursor: 'pointer',
          };
        },
        placeholder: (base) => ({
          ...base,
          fontSize: 13,
          fontWeight: 500,
          color: COLORS.BLACK_50,
        }),
      }}
      components={{
        Option: isCheckboxOptions ? OptionCheckbox : Option,
        IndicatorSeparator: () => null,
        DropdownIndicator: (state) => {
          return (
            <div style={{paddingRight: 8}}>
              <DropdownFilledIcon
                color={
                  state.isDisabled
                    ? COLORS.DARK_20
                    : isSelectedOptions
                      ? COLORS_REDESIGN.BRAND
                      : COLORS_REDESIGN.DARK_NIGHT_BLUE
                }
              />
            </div>
          );
        },
      }}
    />
  );
}
