import {FC} from 'react';

import {IconButton} from '@src/components/common/buttons/icon-button';
import {CopyToClipboardIcon, EditIcon} from '@src/components/common/icons';
import {Typography} from '@src/components/common/typography';
import {COLORS, COLORS_REDESIGN} from '@src/shared/const/appPalette';

import {TProductOtherLink} from '../../types';
import {handleClickToClipboard} from '../../utils/click-to-clipboard';

import {useEmotionWrapper} from './custom-link.s';

interface ICustomLink {
  link: TProductOtherLink;
  isDisabled: boolean;
  onChangeCurrentLink: (link: TProductOtherLink) => void;
}

export const CustomLink: FC<ICustomLink> = ({link, isDisabled, onChangeCurrentLink}) => {
  const {classes} = useEmotionWrapper();

  return (
    <>
      <div className={classes.liTitle} onClick={() => window.open(link.link, '_blank')}>
        <Typography color={isDisabled ? COLORS.DARK_20 : COLORS_REDESIGN.DARK_NIGHT_BLUE}>{link.name}</Typography>
      </div>
      <IconButton size="small" onClick={() => link && onChangeCurrentLink(link)}>
        <EditIcon />
      </IconButton>
      <IconButton size="small" onClick={() => handleClickToClipboard(link.link)}>
        <CopyToClipboardIcon />
      </IconButton>
    </>
  );
};
