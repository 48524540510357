import {FC, useEffect, useMemo, useState} from 'react';

import CheckIcon from '@mui/icons-material/Check';
import {FormControl, InputAdornment, Menu, OutlinedInput, Tooltip, Typography} from '@mui/material';
import {FaqIcon} from '@src/components/common/icons/components/faq';
import {useConfirmationDialog} from '@src/components/providers/confirm-dialog/confirm-dialog';
import {MButton} from '@src/components/redesign/mui-components/common/button/button';
import {MIconButton} from '@src/components/redesign/mui-components/common/icon-button/icon-button';
import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';
import {useStrategyContext} from '@src/pages/repricer/hooks/useStrategyContext';
import {COLORS, COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {stringToNumberWithDot} from '@src/shared/utils/stringToNumberWithDot';

import {validateMinPricePct} from '../../../../utils/valid-min-price-pct';

import {useEmotionWrapper} from './min-price-header-cell.s';

interface IMinPriceHeaderCellProps {
  resetCollectPriceUpdateFunctions: (type: 'minPrice' | 'maxPrice' | 'price') => void;
}

export const MinPriceHeaderCell: FC<IMinPriceHeaderCellProps> = ({resetCollectPriceUpdateFunctions}) => {
  const {classes} = useEmotionWrapper();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {onChangeStrategy, strategy} = useStrategyContext();
  const open = Boolean(anchorEl);
  const [newMinPercent, setNewMinPercent] = useState<string | null>(null);
  const {getConfirmation} = useConfirmationDialog();

  useEffect(() => {
    setNewMinPercent(strategy.minPricePct ? strategy.minPricePct.toString() : null);
  }, [strategy.id]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickApplyMinPricePercent = async () => {
    handleClose();

    const confirm = await getConfirmation({
      title: 'Внимание',
      message: (
        <div>
          <Typography>Вы уверены, что хотите изменить минимальную цену?</Typography>
          <Typography>Минимальная цена у всех товаров будет обновлена.</Typography>
        </div>
      ),
    });

    if (confirm) {
      resetCollectPriceUpdateFunctions('minPrice');
      onChangeStrategy({
        ...strategy,
        minPricePct:
          stringToNumberWithDot(newMinPercent || '') !== 0 ? stringToNumberWithDot(newMinPercent || '') : null,
      });
    }
  };

  const isNotValidNewMinPercent = useMemo(() => validateMinPricePct(newMinPercent), [newMinPercent]);

  return (
    <div className={classes.root}>
      <Tooltip
        arrow
        title="Установите Минимальный порог цены, ниже которого не готовы снижать цену. Для установки Мин. цен на все товары сразу нажмите на кнопку % справа"
      >
        <Typography sx={{fontWeight: 700, whiteSpace: 'nowrap'}}>Мин. цена</Typography>
      </Tooltip>
      <MIconButton
        classes={{root: classes.buttonRoot}}
        sx={{backgroundColor: COLORS_REDESIGN.LIGHT_GREY}}
        onClick={handleClick}
      >
        %
      </MIconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <div style={{padding: 12}}>
          <MTypography sx={{fontWeight: 600}}>Мин. для всех товаров</MTypography>
          <div style={{display: 'flex', alignItems: 'center', gap: 5}}>
            <MTypography sx={{fontWeight: 400, fontSize: 13, marginTop: 1}}>От 0 до 100%</MTypography>
            <Tooltip
              title={
                <Typography sx={{fontSize: 13}}>
                  Установите Минимальный порог цен сразу на все товары, результат отобразится моментально. После можно
                  отредактировать выборочно, если необходимо
                  <br />
                  Цена задается в проценте от текущей цены товара.
                  <br />
                  <Typography sx={{fontStyle: 'italic', fontSize: 13}}>
                    Пример: Цены товаров 900, 1200 и 1500 руб., устанавливаем 80% порог Мин цен. Получаем Мин. цены для
                    товаров 720, 960 и 1200 руб. соответственно
                  </Typography>
                </Typography>
              }
            >
              <div>
                <FaqIcon color={COLORS.BLACK_15} />
              </div>
            </Tooltip>
          </div>
          <div className={classes.inputRoot}>
            <FormControl error={isNotValidNewMinPercent} size="small" sx={{width: 100}}>
              <OutlinedInput
                placeholder="0"
                value={newMinPercent}
                onChange={(e) => setNewMinPercent(e.target.value)}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </FormControl>
            <MButton disabled={isNotValidNewMinPercent} onClick={onClickApplyMinPricePercent} variant="contained">
              <CheckIcon />
            </MButton>
          </div>
        </div>
      </Menu>
    </div>
  );
};
