import {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';

import {useTour} from '@reactour/tour';
import {Modal} from '@src/components/common/modal';
import {Typography} from '@src/components/common/typography/typography';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {IS_VIEWED_LAST_CHANGE_LOG_VERSION} from '@src/shared/const/localStorage';
import {useStore} from '@src/stores';
import {LOGS_TYPES} from './consts';
import {TLogType} from './types';

import {LogItem} from './log-item/log-item';
import packageJson from '../../../../../package.json';

const logs: {
  date: string;
  items: TLogType[];
}[] = [
  {
    date: '11.12.2024',
    items: [
      {
        type: LOGS_TYPES.feature,
        content:
          'В Ценообразование добавили новую  стратегию Софинансирование, которая проверяет типы цен на товары и устанавливает Привлекательные для получениям максимальных скидок от Маркета',
      },
      {
        type: LOGS_TYPES.feature,
        content:
          'Добавили уведомление о привлекательности цен, будем показывать раз неделю количеством товаров с типами цен: Умеренная, Привлекательная, Непривлекательная',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'Добавили фильтр по типам цен в Ценообразование',
      },
      {
        type: LOGS_TYPES.feature,
        content:
          'В XML - фид добавили разбивку по магазинам, теперь данные по остаткам можно передать не только на кабинет, но и разнести по магазинам',
      },
    ],
  },
  {
    date: '03.12.2024',
    items: [
      {
        type: LOGS_TYPES.feature,
        content:
          'Добавили в Ценообразование возможность загружать товары и пороги минимальных/максимальных цен Excel таблицей',
      },
      {
        type: LOGS_TYPES.feature,
        content:
          'Добавили фильтры поиска уже добавленных товаров в стратегию Ценообразования для быстрой настройки нужного товара',
      },
      {
        type: LOGS_TYPES.feature,
        content:
          'Теперь можно Добавить все товары кабинета одной кнопкой в Ценообразование или добавить все выбранные по фильтрам',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'Очистить список добавленных товаров в Ценообразование тоже стало просто кнопкой Удалить все',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'Добавили ссылку быстрого перехода на карточку товара на Маркете из Ценообразования',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'Добавили подсказки в Ценообразование',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'Добавили возможность передать Себестоимость XML-фидом',
      },
      {
        type: LOGS_TYPES.update,
        content:
          'Теперь ссылку на товар конкурента в Ценообразовании можно прикрепить без сохранения стратегии, также как и посмотреть аналитику по ценам',
      },
      {
        type: LOGS_TYPES.update,
        content: 'Улучшили навигацию по шагам добавления товаров и настроек в Ценообразовании',
      },
    ],
  },
  {
    date: '26.11.2024',
    items: [
      {
        type: LOGS_TYPES.feature,
        content: 'Добавили возможность передавать Себестоимость и Маржинальность ecxel таблицей.',
      },
      {
        type: LOGS_TYPES.feature,
        content:
          'При добавлении ссылки на конкурента в Ценообразовании теперь подставляется название товара в ссылке и есть возможность редактирования',
      },
      {
        type: LOGS_TYPES.feature,
        content:
          'Для упрощения передачи/получения закрывающих актов по ЭДО, добавили окно для ввода необходимых данных и автоматизировали работу бухгалтерии',
      },
    ],
  },
  {
    date: '19.11.2024',
    items: [
      {
        type: LOGS_TYPES.update,
        content: 'Доработали Ценообразование по дизайну и функционалу, исправили баги и недочеты',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'Добавили возможность привязывать ссылки на карточки товаров конкурентов в Ценообразовании',
      },
      {
        type: LOGS_TYPES.feature,
        content:
          'Теперь цена конкурента и предложения на его карточке будут учитываться в работе стратегии Ценнобразования',
      },
      {
        type: LOGS_TYPES.feature,
        content:
          'Добавили Рекомендацию нашей цены для стратегии и кнопку Применить для ручной работы стратегии Ценообразования',
      },
      {
        type: LOGS_TYPES.feature,
        content:
          'Добавили возможность указывать Себестоимость товара, будет использоваться для Аналитики расходов на услуги Маркета и прибыльности бизнеса',
      },
    ],
  },
  {
    date: '07.11.2024',
    items: [
      {
        type: LOGS_TYPES.update,
        content: (
          <>
            <Typography size={13} weight={500} color={COLORS_REDESIGN.DARK_NIGHT_BLUE}>
              Большое обновление Мониторинга цен, теперь это Ценообразование - инструмент, который упрощает управление
              ценой товаров, используя данные о конкурентах и рекомендации цен в автоматических и ручных стратегиях.
              <br />
              ⁃ Удобное добавление товаров и настройка стратегий
              <br />
              ⁃ Быстрая установка максимальных и минимальных цен процентом сразу на все товары стратегии
              <br /> ⁃ Понятный запуск Авто-стратегии
            </Typography>
          </>
        ),
      },
    ],
  },
  {
    date: '22.10.2024',
    items: [
      {
        type: LOGS_TYPES.feature,
        content:
          'Добавили возможность копирования стратегии Буста продаж для быстрой настройки на товары в разных магазинах',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'Добавили порог Максимальной цены для Мониторинга цен',
      },
      {
        type: LOGS_TYPES.update,
        content:
          'Удалили стратегию "Софинансирование Яндекса" из Мониторинга цен. Для товаров, использовавших эту стратегию, теперь установлена стратегия "Выключено"',
      },
    ],
  },
  {
    date: '08.10.2024',
    items: [
      {
        type: LOGS_TYPES.feature,
        content: 'Показываем количество товаров, добавленное в Ценообразование',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'Товар становится неактивным в Мониторинге цен, если он снят с витрины или имеет 0 остаток',
      },
      {
        type: LOGS_TYPES.feature,
        content:
          'Добавили (?) на дополнительные условия стратегий, где объяснили как работают приоритеты, при задании нескольких условий разных типов.',
      },
      {
        type: LOGS_TYPES.feature,
        content:
          'В сводных показателях Аналитики можно будет смотреть данные по категориям и брендам, с помощью фильтра',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'В Аналитику добавили историю остатков по товарам и коэффициент оборачиваемости',
      },
    ],
  },
  {
    date: '25.09.2024',
    items: [
      {
        type: LOGS_TYPES.feature,
        content:
          'Отображение акций на товар - теперь на главной странице в списке товаров будем отображать есть ли товар в акции, каким способом он туда попал и название акции',
      },
      {
        type: LOGS_TYPES.feature,
        content:
          'Дополнительное условие для стратегий биддера по акции - если товар попадает в акцию, то можно будет понизить/повысить ставку Буста',
      },
      {
        type: LOGS_TYPES.feature,
        content:
          'Загрузку товаров в Ценообразование таблицей - можно будет скачать шаблон, заполнить его и сразу загрузить в сервис с указанием минимальных цен и стратегий. После успешной загрузки товары сразу появятся в Мониторинге цен и стратегии начнут работать',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'Аналитику по магазинам кабинета',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'Аналитику Буста по доставленным товарам',
      },
      {
        type: LOGS_TYPES.update,
        content: 'Категории теперь как на Маркете - настраивать стратегии стало привычнее',
      },
      {
        type: LOGS_TYPES.bugfix,
        content: 'Смена работы с базовыми или ценами магазинов для Мониторинга цен - работает без ошибок',
      },
      {
        type: LOGS_TYPES.bugfix,
        content: 'Оптимизировали получение данных по брендам - повторение брендов станет меньше',
      },
      {
        type: LOGS_TYPES.bugfix,
        content: 'В Аналитике статусы Заказа более точные, теперь органика - это органика и появился Буст Маркета.',
      },
      {
        type: LOGS_TYPES.bugfix,
        content: 'В Аналитике Метрики трафика показывают правильные значения',
      },
    ],
  },
  {
    date: '11.09.2024',
    items: [
      {
        type: LOGS_TYPES.feature,
        content: 'Теперь будем уведомлять обо всех улучшениях и обновлениях сервиса',
      },

      {
        type: LOGS_TYPES.feature,
        content: 'Стратегии для Мониторинга цен - "Быть на позиции" и "Лучшая цена"',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'Стратегия "Выключено" для Мониторинга цен, чтобы получать данные о конкурентах без автостратегий',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'Кнопка “Удалить все”, чтобы удалить товары из Мониторинга цен за один раз',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'График изменения цены на товар',
      },
      {
        type: LOGS_TYPES.feature,
        content: 'В Аналитику вернули обновленные Метрики просмотров и добавлений в корзину',
      },
      {
        type: LOGS_TYPES.update,
        content:
          'Новые настройки стратегий в Бусте продаж, стало удобнее и понятнее. Дополнительные условия работы стратегии теперь легче настраивать',
      },
      {
        type: LOGS_TYPES.update,
        content:
          'Улучшили информацию о ценах для товаров добавленных в Ценообразование: теперь помимо рекомендаций Маркета мы выводим конкурентов, их цены, рейтинг и позицию',
      },
    ],
  },
];

export const ChangelogModal = observer(() => {
  const {isOpen: isOpenTour} = useTour();
  const {user} = useStore();

  const [isOpen, setIsOpen] = useState(false);

  const currentAppVersion = packageJson.version;
  const appVersionOnLocalStorage = localStorage.getItem(IS_VIEWED_LAST_CHANGE_LOG_VERSION);

  useEffect(() => {
    let intervalId = null;
    if (appVersionOnLocalStorage !== currentAppVersion && user.isAuth && !isOpenTour) {
      intervalId = setTimeout(() => setIsOpen(true), 6000);
    }
    if (isOpenTour) {
      setIsOpen(false);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isOpenTour, user.isAuth]);

  const onClose = () => {
    setIsOpen(false);
    localStorage.setItem(IS_VIEWED_LAST_CHANGE_LOG_VERSION, currentAppVersion);
  };

  return (
    <Modal isCloseOnClickOverlay={false} title="История обновлений" opened={isOpen} onClose={onClose}>
      <div style={{display: 'flex', flexDirection: 'column', gap: 35, padding: 1, maxWidth: 800}}>
        {logs.map((log) => (
          <LogItem key={log.date} date={log.date} items={log.items} />
        ))}
      </div>
    </Modal>
  );
});
