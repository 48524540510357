import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  rootStepper: {
    minWidth: 250,
    maxWidth: 300,
    width: '100%',
    marginLeft: 90,
  },
  stepLabel: {
    fontWeight: '600 !important',
    cursor: 'pointer',
  },
  label: {
    cursor: 'pointer !important',
    '&:hover': {
      color: `red important`,
    },
  },
}));
