import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import {COLORS_REDESIGN} from '@shared/const/appPalette';
import {TARIFFS_TYPES} from '@shared/enums/tariffs';
import {ChevronIcon} from '@src/components/common/icons';
import {MButton} from '@src/components/redesign/mui-components/common/button/button';
import {getRoutePricing} from '@src/shared/const/router';

import {useCurrentTariff} from '../../../../hooks/useCurrenTariff';
import {Typography} from '../../..';
import {NotificationContainer} from '../notification-container/notification-container';

import {useEmotionWrapper} from './price-monitoring-notify.s';

export const PriceMonitoringNotify = observer(() => {
  const [isView, setIsView] = useState(false);
  const location = useLocation();
  const {classes} = useEmotionWrapper();
  const {currentTariff} = useCurrentTariff();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/price-monitoring' && currentTariff.type === TARIFFS_TYPES.START) {
      setIsView(true);
    } else {
      setIsView(false);
    }
  }, [location.pathname, currentTariff.type]);

  return isView ? (
    <NotificationContainer>
      <div className={classes.root}>
        <Typography weight="bold" color={COLORS_REDESIGN.BRAND} size={16}>
          Функция мониторинга цен доступна только в рамках платных тарифов.
          <br />
          Чтобы использовать этот функционал, пожалуйста, перейдите на один из наших платных тарифных планов!
        </Typography>
        <MButton endIcon={<ChevronIcon />} variant="contained" onClick={() => navigate(getRoutePricing())}>
          Выбрать тариф
        </MButton>
      </div>
    </NotificationContainer>
  ) : null;
});
