import {createContext} from 'react';

import {STRATEGY_CREATE_STATUS} from '@src/pages/repricer/enums';
import {TRepricerItem} from '@src/pages/repricer/types/repricer-item';
import {TMpAccountItem} from '@src/stores/market-place-account-item/types';
import {TRepricerStrategy} from '@src/stores/repricer/types';

interface IStrategyContext {
  productsOnStrategy: TMpAccountItem[];
  strategy: TRepricerStrategy;
  deleteProductIds: number[];
  onSelectProduct: (product: TMpAccountItem) => void;
  onChangeStrategy: (strategy: TRepricerStrategy) => void;
  initialRepricerItems: TRepricerItem[];
  isRepricerLimited: boolean;
  strategyCreationStatus: STRATEGY_CREATE_STATUS;
  addProductIds: number[];
  addItemsBySelectedFilters: () => Promise<void>;
  deleteAllProducts: () => void;
  deleteProduct: (itemId: number) => void;
}

export const StrategyContext = createContext({} as IStrategyContext);
