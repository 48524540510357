import {FC} from 'react';

import {BestPriceIcon} from '@src/components/common/icons/components/best-price';
import {PlaceIcon} from '@src/components/common/icons/components/place';
import {YandexBlackIcon} from '@src/components/common/icons/components/yandex-icon';
import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {REPRICER_STRATEGY, REPRICER_STRATEGY_SELECT_OPTIONS} from '@src/shared/enums/repricer-strategy';

import {useEmotionWrapper} from './strategy-label.s';

interface IStrategyLabel {
  strategy: REPRICER_STRATEGY;
  params: {place: number | null} | null;
}

export const StrategyLabel: FC<IStrategyLabel> = ({strategy, params}) => {
  const {classes} = useEmotionWrapper();

  const getIconByStrategy = () => {
    switch (strategy) {
      case REPRICER_STRATEGY.DEFAULT:
        return <BestPriceIcon color={COLORS_REDESIGN.DARK_NIGHT_BLUE} size="16" />;
      case REPRICER_STRATEGY.PLACE:
        return <PlaceIcon color={COLORS_REDESIGN.DARK_NIGHT_BLUE} size="16" />;
      case REPRICER_STRATEGY.COFINANCE:
        return <YandexBlackIcon color={COLORS_REDESIGN.DARK_NIGHT_BLUE} size="16" />;
    }
  };

  return (
    <div className={classes.root}>
      {getIconByStrategy()}
      <MTypography sx={{fontWeight: 500, color: COLORS_REDESIGN.DARK_NIGHT_BLUE, fontSize: 13}}>
        {REPRICER_STRATEGY_SELECT_OPTIONS.find((s) => s.value === strategy)?.label}
        {strategy === REPRICER_STRATEGY.PLACE && params && ` — ${params.place}`}
      </MTypography>
    </div>
  );
};
