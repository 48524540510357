import {type FC, useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';

import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import {IconButton, InputAdornment, TextField, Tooltip, Zoom} from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {MButton} from '@src/components/redesign/mui-components/common/button/button';
import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';
import {TProductPrices} from '@src/pages/repricer/types/repricer-item';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {stringToNumberWithDot} from '@src/shared/utils/stringToNumberWithDot';
import {useStore} from '@src/stores';
import {TMpItemShop} from '@src/stores/market-place-account-item/types';

type TPriceType = 'price' | 'minPrice' | 'maxPrice';

interface IPriceUpdaterTooltip {
  isAllShops?: boolean;
  productId: number;
  shop: TMpItemShop;
  type: TPriceType;
  children?: React.ReactNode;
  updateProductPriceOnShop: ({shopId, price, type}: {shopId?: number; price: number; type: TPriceType}) => void;
  collectPriceUpdateFunctions: (type: TProductPrices, updateFunction: () => Promise<void>) => void;
  disabled?: boolean;
  price?: number | string;
  minPrice?: number | string;
  maxPrice?: number | string;
}

export const PriceUpdaterTooltip: FC<IPriceUpdaterTooltip> = observer(
  ({
    children,
    shop,
    type,
    productId,
    isAllShops = false,
    disabled = false,
    updateProductPriceOnShop,
    collectPriceUpdateFunctions,
    price,
    minPrice,
    maxPrice,
  }) => {
    const {repricer, mpItems, mpAccounts} = useStore();
    const [isOpen, setIsOpen] = useState(false);
    const [newPrices, setNewPrices] = useState({
      price: '',
      minPrice: '',
      maxPrice: '',
    });

    useEffect(() => {
      setNewPrices({
        price: price?.toString() || '',
        minPrice: minPrice?.toString() || '',
        maxPrice: maxPrice?.toString() || '',
      });
    }, [price, minPrice, maxPrice]);

    const getLabelByType = useMemo(() => {
      switch (type) {
        case 'price':
          return 'Цена';
        case 'minPrice':
          return 'Минимальная цена';
        case 'maxPrice':
          return 'Максимальная цена';
      }
    }, [type]);

    const getPriceByType = () => {
      switch (type) {
        case 'price':
          return newPrices?.price;
        case 'minPrice':
          return newPrices?.minPrice;
        case 'maxPrice':
          return newPrices?.maxPrice;
      }
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const {value} = event.target;
      setNewPrices((prev) => ({
        ...prev,
        [type]: value,
      }));
    };

    const onUpdatePrice = async () => {
      //обновляем данные в массиве productsOnStrategy, для изменения на ui т.к запрос отправляем после создания/редактирования стратегии
      updateProductPriceOnShop({
        shopId: isAllShops ? undefined : shop.shopId,
        price: stringToNumberWithDot(newPrices[type]),
        type: type,
      });

      const updateFunction = async () => {
        switch (type) {
          case 'price':
            if (isAllShops || mpAccounts.current?.onlyDefaultPriceEnabled) {
              await mpItems.updateItemPrice(productId, stringToNumberWithDot(newPrices.price) * 100);
            } else {
              await repricer.updatePriceShopItem({
                itemId: productId,
                shopId: shop.shopId,
                price: stringToNumberWithDot(newPrices.price) * 100,
              });
            }
            break;

          case 'maxPrice':
            await repricer.updateMaxPriceShopItem({
              productShopId: productId,
              shopId: isAllShops ? undefined : shop.shopId,
              maxPrice: stringToNumberWithDot(newPrices.maxPrice) * 100,
            });
            break;

          case 'minPrice':
            await repricer.updateMinPriceShopItem({
              productShopId: productId,
              shopId: isAllShops ? undefined : shop.shopId,
              minPrice: stringToNumberWithDot(newPrices.minPrice) * 100,
            });
            break;
        }
      };

      //собирем массив промисов чтобы запустить их после создания/обновления стратегии (strategy-settings.tsx)
      collectPriceUpdateFunctions(type, updateFunction);

      setIsOpen(false);
    };

    return (
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <div>
          <Tooltip
            sx={{width: 100}}
            arrow
            TransitionComponent={Zoom}
            PopperProps={{
              style: {
                width: 220,
              },
            }}
            open={isOpen}
            title={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'column',
                  textAlign: 'justify',
                  padding: 6,
                }}
              >
                <MTypography sx={{fontSize: 12, color: COLORS_REDESIGN.DARK_GREY}}>
                  {getLabelByType} товара{' '}
                  {type === 'price' && mpAccounts.current?.onlyDefaultPriceEnabled
                    ? 'базовая'
                    : isAllShops
                      ? 'во всех магазинах'
                      : `в магазине ${shop.shopName}`}
                </MTypography>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <TextField
                    onChange={onChange}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="start">₽</InputAdornment>,
                      },
                    }}
                    sx={{width: 100}}
                    size="small"
                    variant="standard"
                    value={getPriceByType() || ''}
                  />
                  <IconButton onClick={onUpdatePrice} color="success" size="small">
                    <DoneIcon />
                  </IconButton>
                  <IconButton onClick={() => setIsOpen(false)} size="small">
                    <ClearIcon />
                  </IconButton>
                </div>
              </div>
            }
          >
            <MButton
              disabled={disabled}
              onClick={() => setIsOpen(true)}
              sx={{cursor: 'pointer', color: '#335CE5', fontSize: 14, whiteSpace: 'nowrap'}}
            >
              {children}
            </MButton>
          </Tooltip>
        </div>
      </ClickAwayListener>
    );
  }
);
