import {emotionWrapper} from '@shared/lib';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    backgroundColor: COLORS_REDESIGN.WHITE,
    width: 'fit-content',
    alignItems: 'flex-start',
  },
  contentRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: 7,
    width: 'fit-content',
  },
  competitivenessContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 10,
  },

  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
  },
  description: {
    fontSize: 14,
    color: COLORS_REDESIGN.NIGHT_GREY,
  },
  repricer: {
    fontSize: 13.5,
    fontWeight: 600,
  },
  buttonsContainer: {
    display: 'flex',
    gap: 40,
  },
}));
