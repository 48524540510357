export const CashbackIcon = ({size = '16', color = 'currentColor', ...others}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...others}>
      <path
        d="M13.8 39.1194L13.6 38.1194C13.5 37.6194 13.1 37.2194 12.6 37.3194L9 37.4194L8.9 37.0194C8.8 36.5194 8.4 36.1194 7.9 36.1194H1C0.4 36.1194 0 36.5194 0 37.1194V62.9194C0 63.5194 0.6 64.0194 1.2 63.9194L11.6 61.7194C12.1 61.6194 12.5 61.1194 12.4 60.6194L12.3 59.7194L15.9 59.6194C16.5 59.6194 17 59.0194 16.9 58.4194L16.6 56.3194H17.2C22.9 53.8194 27.4 57.5194 27.6 57.5194C33.3 61.1194 40.7 60.8194 46.1 56.7194L62.2 44.4194C64.3 42.8194 64.8 39.8194 63 37.5194C61.8 35.9194 59.8 35.3194 57.9 35.7194C60.6 31.1194 61.8 25.7194 61 20.1194C59.5 9.51938 50.7 1.11938 40.1 0.11938C26 -1.28062 14.1 9.81938 14.1 23.6194C14.1 24.8194 14.2 26.0194 14.4 27.2194L11.9 25.4194C11.4 25.1194 10.8 25.2194 10.5 25.6194C10.2 26.1194 10.3 26.7194 10.7 27.0194L15.2 30.2194C15.7 30.5194 16.3 30.4194 16.6 30.0194L19.8 25.5194C20.1 25.0194 20 24.4194 19.6 24.1194C19.1 23.8194 18.5 23.9194 18.2 24.3194L16.3 26.9194C16.1 25.8194 16 24.7194 16 23.5194C16 11.3194 26.2 1.41938 38.6 2.01938C49.6 2.51938 58.6 11.5194 59.2 22.4194C59.5 29.0194 56.9 35.2194 52.1 39.5194C51.2 40.2194 50.4 40.8194 49.8 41.2194C49.4 41.5194 49.3 42.2194 49.6 42.6194C49.9 43.0194 50.6 43.1194 51 42.8194C53.3 41.1194 57.1 38.2194 57.2 38.1194C58.5 37.1194 60.4 37.4194 61.5 38.8194C62.4 40.0194 62.1 41.8194 60.8 42.7194L44.8 55.0194C40.1 58.6194 33.6 58.9194 28.6 55.7194C28.3 55.6194 23.2 51.3194 16.3 54.3194C16.3 54.3194 16.2 54.3194 16.2 54.4194L14.1 41.3194C17.2 38.8194 21.3 37.9194 25.2 38.8194L42.6 43.0194C43.7 43.3194 44.5 44.1194 44.6 45.2194C44.7 46.9194 43.3 48.2194 41.8 47.9194L32.6 46.7194C32 46.6194 31.5 47.0194 31.5 47.6194C31.4 48.1194 31.8 48.6194 32.4 48.7194L41.6 49.9194C44.1 50.2194 46.3 48.5194 46.7 46.0194C47 43.7194 45.5 41.6194 43.3 41.0194L25.8 36.8194C21.6 36.0194 17.2 36.8194 13.8 39.1194ZM2 61.6194V38.2194H7L10.3 59.9194L2 61.6194ZM12 57.6194L9.3 39.4194L11.8 39.3194C12.8 45.3194 14.2 54.4194 14.7 57.5194L12 57.6194Z"
        fill={color}
      />
      <path
        d="M25.7 29.1195C25.7 34.0195 30.5 37.2195 38 37.2195C45.5 37.2195 50.3 34.0195 50.3 29.1195C50.3 24.8195 46.6 16.3195 41.3 13.6195C43.6 11.8195 45.1 8.5195 45.1 6.7195C45.1 6.3195 44.9 6.0195 44.6 5.8195C44.3 5.6195 43.9 5.6195 43.6 5.8195C41.5 7.0195 40.4 7.4195 38.5 6.5195C36.6 5.6195 33.9 4.5195 31.5 5.8195C31.2 6.0195 31 6.3195 31 6.7195C31 8.5195 32.5 11.8195 34.8 13.6195C29.4 16.4195 25.7 24.9195 25.7 29.1195ZM33 7.4195C34.5 7.0195 36.3 7.7195 37.6 8.4195C39.6 9.4195 41.2 9.3195 42.7 8.7195C41.8 10.7195 39.8 13.4195 37.6 13.0195C35.3 12.5195 33.4 9.3195 33 7.4195ZM37.4 14.9195C38 15.0195 38 15.0195 38.6 14.9195C43.8 15.6195 48.3 24.9195 48.3 29.2195C48.3 33.7195 42.8 35.3195 38 35.3195C33.3 35.3195 27.7 33.7195 27.7 29.2195C27.7 24.9195 32.2 15.6195 37.4 14.9195Z"
        fill={color}
      />
      <path
        d="M36 31.9194V19.9194H39.9231C41.07 19.9194 42 21.1921 42 22.7615C42 24.331 41.07 25.6036 39.9231 25.6036H36"
        stroke={color}
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path d="M34 28.9194H39" stroke={color} stroke-width="1.5" stroke-miterlimit="10" />
    </svg>
  );
};
