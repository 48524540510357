import {COLORS_REDESIGN} from '@shared/const/appPalette';
import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    width: '100%',
    backgroundColor: COLORS_REDESIGN.BRAND_LIGHT,
    height: 74,
    paddingLeft: 46,
    paddingRight: 46,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 35,
  },
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {},
}));
