import {type FC} from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import {Tooltip} from '@components/index';
import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';

import {useRouterApp} from '../../../hooks';
import {useStore} from '../../../stores';

import {useEmotionWrapper} from './sidebar-menu.s';

type TMenuItem = {
  title: string;
  path?: string;
  icon: JSX.Element;
  isPrivate?: boolean;
  customTooltip?: string | JSX.Element;
  isNewWindow?: boolean;
  isMpAcc?: boolean;
  isHide?: boolean;
  params?: {[key: string]: string | undefined}[];
  id?: string;
  dataCy?: string;
  disabled?: boolean;
  onClick?: () => void;
};

interface ISidebarMenu {
  menuList: {
    primary?: Array<TMenuItem>;
    secondary?: Array<TMenuItem>;
  };
  isCompact: boolean;
}

export const SidebarMenu: FC<ISidebarMenu> = observer(({menuList, isCompact}) => {
  const {classes, cx} = useEmotionWrapper({isCompact});
  const {location, navigate} = useRouterApp();
  const {user, mpAccounts} = useStore();

  const checkIsPrivate = (item: TMenuItem) => {
    if ((item.isPrivate && !user.isAuth) || item.isHide) {
      return null;
    }

    const redirectOrNewWindow = () => {
      item.isNewWindow ? window.open(item.path, '_blank') : item.path && navigate(item.path);
    };
    if (item.isMpAcc && mpAccounts.items.length === 0) return;
    let params = '';
    if (item.params) {
      params = item.params
        .map((item) => {
          if (item.value === undefined) return;
          return `${item.key}=${item.value}`;
        })
        .join('&');
    }

    return (
      <Tooltip
        position="right"
        destroy={!item.disabled}
        content={item.disabled ? 'Для доступа к разделам активируйте тариф' : ''}
      >
        <Tooltip
          key={item.path}
          position="right"
          content={item.customTooltip ? item.customTooltip : item.title}
          destroy={(!isCompact && !item.customTooltip) || item.disabled}
        >
          <Link
            data-cy={item.dataCy}
            id={item.id}
            to={{
              pathname: item.path,
              search: params,
            }}
            state={{from: location.pathname}}
            target={item.isNewWindow ? '_blank' : '_self'}
            key={item.path}
            onClick={() => {
              if (item.onClick) {
                item.onClick();
              } else {
                redirectOrNewWindow();
              }
            }}
            className={cx(classes.menuItemBlock, {
              [classes.isActiveMenuItem]: location.pathname === item.path,
              [classes.disabled]: item.disabled === true,
            })}
          >
            <div className={classes.iconContainer}>{item.icon}</div>
            <div className={classes.menuItemText}>
              <MTypography sx={{fontWeight: 500, fontSize: 13}}>{item.title}</MTypography>
            </div>
          </Link>
        </Tooltip>
      </Tooltip>
    );
  };

  return (
    <div className={classes.root}>
      {menuList.primary?.map((item) => checkIsPrivate(item))}
      {menuList.secondary && menuList.secondary?.length > 0 && <div className={classes.divider} />}
      {menuList.secondary?.map((item) => checkIsPrivate(item))}
    </div>
  );
});
