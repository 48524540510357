export enum ERROR_CODES {
  DEFAULT = 'DEFAULT',
  NOT_FOUND = 'Не найдено',
  VALIDATION_ERROR = 'Ошибка валидации',
  BAD_REQUEST = 'Некорректный запрос',
  INTERNAL_SERVER_ERROR = 'Внутренняя ошибка сервера',
  // AUTH_ERROR = 'Ошибка аутентификации',
  NO_ACCOUNT_ACCESS = 'Нет доступа к данному кабинету',
  ACCOUNT_UPDATE_IN_PROGRESS = 'Обновление кабинета уже запущено',
  ACCOUNT_RECENTLY_UPDATED = 'Обновление кабинета возможно запустить 1 раз в час',
  ACCOUNT_HAS_BASE_TARIFF = 'Аккаунт имеет базовый тариф',
  PAYMENT_ERROR = 'Ошибка оплаты',
  INVALID_TARIFF = 'Неверный тариф',
  TARIFF_NOT_FOUND = 'Тариф не найден',
  ITEM_NOT_FOUND = 'Товар не найден',
  SHOP_ITEM_NOT_FOUND = 'Не найден товар в магазине',
  REPRICER_ITEM_NOT_FOUND = 'Не найден товар в мониторинге цен',
  SHOP_NOT_FOUND = 'Магазин не найден',
  USER_NOT_FOUND = 'Пользователь не найден',
  PARTNER_NOT_FOUND = 'Партнер не найден',
  ACCOUNT_NOT_FOUND = 'Кабинет не найден',
  ACCOUNT_TOKEN_NOT_FOUND = 'Токен аккаунта не найден',
  YANDEX_ERROR = 'Ошибка Яндекса',
  ITEM_BIDDER_ENABLED = 'Ставки на товар уже активированы',
  ONLY_DEFAULT_PRICE_AVAILABLE = 'Доступна только базовая цена',
  NO_ACTIVE_TARIFF = 'Аккаунт не имеет активного тарифа',
  RULE_ALREADY_EXISTS = 'Правило с таким набором фильтров уже существует',
  INVALID_SERVICE_TOKEN = 'Неверный сервисный токен',
  REPRICER_STRATEGY_NOT_FOUND = 'Не найдена стратегия мониторинга цен',
  REPRICER_STRATEGY_ALREADY_EXISTS = 'Стратегия мониторинга цен уже существует',
}
