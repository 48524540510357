import {useEffect, useState} from 'react';
import {MultiValue} from 'react-select';
import {observer} from 'mobx-react-lite';

import {CustomSelect} from '@src/components';
import {BIDDER_STRATEGY} from '@src/shared/enums/bidder-strategy';
import {getSelectPlaceholder} from '@src/shared/utils/getPlaceholderFilterForSelect';
import {useStore} from '@src/stores';

const strategyFilterOptions = [
  {
    label: 'Индивидуальные настройки',
    value: 'INDIVIDUAL',
  },
  {
    label: 'Искусственный интеллект',
    value: BIDDER_STRATEGY.AI,
  },

  {
    label: 'Рекомендации Я.Маркета',
    value: BIDDER_STRATEGY.RECOMMENDED,
  },
  {
    label: 'Ручное управление',
    value: BIDDER_STRATEGY.MANUAL,
  },
  {
    label: 'Выключено',
    value: BIDDER_STRATEGY.OFF,
  },
];

type TExtendedBidderStrategy =
  | BIDDER_STRATEGY
  | BIDDER_STRATEGY.AI
  | BIDDER_STRATEGY.MANUAL
  | BIDDER_STRATEGY.OFF
  | 'INDIVIDUAL';

type TStrategyFilterOption = {
  label: string;
  value: string | null;
};

const sortOrder: {[key in TExtendedBidderStrategy]: number} = {
  INDIVIDUAL: 1,
  AI: 1,
  RECOMMENDED: 1,
  MANUAL: 2,
  OFF: 3,
};

export const StrategiesFilter = observer(() => {
  const {mpItems} = useStore();
  const [selectedStrategyFilter, setSelectedStrategyFilter] = useState<MultiValue<TStrategyFilterOption> | null>(null);

  const sortStrategies = (array: MultiValue<TStrategyFilterOption>) => {
    if (array === null) {
      return array;
    }

    const sortedShops =
      Array.isArray(array) &&
      array.sort((a: TStrategyFilterOption, b: TStrategyFilterOption) => {
        const orderA = sortOrder[a.value as BIDDER_STRATEGY] ?? Number.MAX_VALUE;
        const orderB = sortOrder[b.value as BIDDER_STRATEGY] ?? Number.MAX_VALUE;

        return orderA - orderB;
      });

    return sortedShops as MultiValue<TStrategyFilterOption>;
  };

  const onChange = (selectedStrategies: MultiValue<TStrategyFilterOption>) => {
    setSelectedStrategyFilter(sortStrategies(selectedStrategies));

    let resultArr = selectedStrategies.map((strategy) => strategy.value?.toString() || '');

    const isFindIndexHaveIndividualStrategy = resultArr.findIndex((strategy) => strategy === 'INDIVIDUAL');

    if (isFindIndexHaveIndividualStrategy !== -1) {
      resultArr = resultArr.filter((strategy) => strategy !== 'INDIVIDUAL');
      mpItems.selectHaveIndividualStrategy(true);
    } else {
      mpItems.selectHaveIndividualStrategy(false);
    }

    mpItems.selectStrategyFilter(resultArr);
  };

  useEffect(() => {
    const selectedValues = mpItems.filters.selected.strategies.map((strategy) => {
      return {
        label: strategyFilterOptions.find((item) => item.value === strategy)?.label || '',
        value: strategy,
      };
    });

    if (mpItems.filters.selected.haveIndividualStrategy) {
      selectedValues.unshift({label: 'Индивидуальные настройки', value: 'INDIVIDUAL'});
    }

    setSelectedStrategyFilter(sortStrategies(selectedValues));
  }, []);

  return (
    <CustomSelect
      width="fit-content"
      isMulti
      isCheckboxOptions
      controlShouldRenderValue={false}
      isSearchable={false}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isClearable={false}
      isBordered={false}
      enableFocusEffect={false}
      isSelectedOptions={!!selectedStrategyFilter?.length}
      backgroundColor="transparent"
      options={strategyFilterOptions}
      onChange={(selectedStrategies) => onChange(selectedStrategies)}
      value={selectedStrategyFilter}
      placeholder={getSelectPlaceholder({
        lengthFilterArray: selectedStrategyFilter !== null ? selectedStrategyFilter.length : 0,
        title: 'Стратегия',
        fontSize: 13,
        fontWeight: 500,
      })}
    />
  );
});
