import {emotionWrapper} from '@shared/lib';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';

export const useEmotionWrapper = emotionWrapper((color: string) => ({
  root: {
    width: '100%',
    backgroundColor: color,
    height: 56,
    paddingLeft: 46,
    paddingRight: 46,
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    justifyContent: 'center',
    color: COLORS_REDESIGN.BRAND,
    fontWeight: 700,
  },
}));
