import {emotionWrapper} from '@shared/lib';

export const useEmotionWrapper = emotionWrapper(() => ({
  subTitle: {
    fontWeight: 600,
    fontSize: 18,
  },
  backButton: {
    width: 'fit-content',
  },
  nextStepButton: {
    width: 'fit-content',
    marginLeft: 'auto',
  },
}));
