import {emotionWrapper} from '@shared/lib';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';

export const useEmotionWrapper = emotionWrapper(() => ({
  competitivenessItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 177,
    borderRadius: 8,
    backgroundColor: COLORS_REDESIGN.LIGHT_GREY,
    padding: 8,
    color: COLORS_REDESIGN.BRAND,
    textAlign: 'center',
    gap: 5,
  },
  low: {
    color: '#E8293B',
    backgroundColor: '#FDD9D5',
    padding: '2px 6px',
    borderRadius: 7,
    fontWeight: 600,
    fontSize: 11,
  },
  optimal: {
    color: '#4C6831',
    backgroundColor: '#E1EDD9',
    padding: '2px 6px',
    borderRadius: 7,
    fontWeight: 600,
    fontSize: 11,
  },
  average: {
    color: '#9F5F2E',
    backgroundColor: '#F3EACD',
    padding: '2px 6px',
    borderRadius: 7,
    fontWeight: 600,
    fontSize: 11,
  },
  itemsCount: {
    fontSize: 14,
    fontWeight: 500,
  },
  itemDescription: {
    color: COLORS_REDESIGN.GENERAL_GRAY,
    fontSize: 12,
  },
}));
