import {emotionWrapper} from '@shared/lib';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';

export const useEmotionWrapper = emotionWrapper((isOpen: boolean) => ({
  content: {
    position: 'absolute',
    width: 185,
    top: 45,
    left: 0,
    paddingTop: 5,
    backgroundColor: COLORS_REDESIGN.LIGHT_GREY,
    borderRadius: 7,
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;',
  },
  yandexMarketTitle: {
    position: 'relative',
    backgroundColor: COLORS_REDESIGN.LIGHT_GREY,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: 8,
    borderRadius: isOpen ? '8px 8px 0 0' : 8,
    userSelect: 'none',
    whiteSpace: 'nowrap',
    transition: 'box-shadow 0.3s ease, transform 0.3s ease',
    ':hover': {
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      transform: 'translateY(-1px)',
      transition: 'box-shadow 0.3s ease, transform 0.3s ease',
    },
  },
  dropdownEnter: {
    opacity: 0,
    height: 0,
  },
  dropdownEnterActive: {
    transition: 'opacity 300ms ease-out, height 300ms ease-out',
    opacity: 1,
    height: 'auto',
  },
  dropdownExit: {
    opacity: 0,
  },
  dropdownExitActive: {
    transition: 'opacity 300ms ease-out, height 300ms ease-out',
    opacity: 0,
    height: 0,
  },
}));
