export enum PRICE_COMPETITIVENESS_TYPE {
  OPTIMAL = 'OPTIMAL',
  AVERAGE = 'AVERAGE',
  LOW = 'LOW',
}

export const PRICE_COMPETITIVENESS_TYPE_LABELS: Record<PRICE_COMPETITIVENESS_TYPE, string> = {
  [PRICE_COMPETITIVENESS_TYPE.OPTIMAL]: 'Привлекательная',
  [PRICE_COMPETITIVENESS_TYPE.AVERAGE]: 'Умеренная',
  [PRICE_COMPETITIVENESS_TYPE.LOW]: 'Непривлекательная',
};

export const PRICE_COMPETITIVENESS_TYPE_OPTIONS = [
  {
    value: PRICE_COMPETITIVENESS_TYPE.OPTIMAL,
    label: PRICE_COMPETITIVENESS_TYPE_LABELS[PRICE_COMPETITIVENESS_TYPE.OPTIMAL],
  },
  {
    value: PRICE_COMPETITIVENESS_TYPE.AVERAGE,
    label: PRICE_COMPETITIVENESS_TYPE_LABELS[PRICE_COMPETITIVENESS_TYPE.AVERAGE],
  },
  {value: PRICE_COMPETITIVENESS_TYPE.LOW, label: PRICE_COMPETITIVENESS_TYPE_LABELS[PRICE_COMPETITIVENESS_TYPE.LOW]},
];
