import {useCallback, useState} from 'react';

export const useStepper = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const changeCurrentStep = useCallback((step: number) => {
    setCurrentStep(step);
    return step;
  }, []);

  return {
    currentStep,
    changeCurrentStep,
  };
};
