import {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {CircularProgress, Tooltip} from '@mui/material';
import {useStore} from '@src/stores';

import {NotificationContainer} from '../notification-container/notification-container';

export const AccountIsUpdatingNotify = observer(() => {
  const {mpAccounts} = useStore();
  const [isView, setIsView] = useState(false);

  useEffect(() => {
    if (mpAccounts.current?.isUpdating && mpAccounts.current?.lastDataUpdatedAt === null) {
      setIsView(true);
    }
    if (mpAccounts.current?.isUpdating === false) {
      setIsView(false);
    }
  }, [mpAccounts.current?.isUpdating]);

  if (!isView) return null;

  return (
    <NotificationContainer>
      <CircularProgress size={20} /> Идет синхронизация с Яндекс Маркетом{' '}
      <Tooltip
        arrow
        title="Мы получаем актуальную информацию о кабинетах, товарах, продажах и рекомендованных ставках. После окончания синхронизации сообщение исчезнет и вы сможете полноценно начать пользоваться сервисом"
      >
        <HelpOutlineIcon />
      </Tooltip>
    </NotificationContainer>
  );
});
