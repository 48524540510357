import {type FC, useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import {useDebounce} from '@src/hooks/useDebounce';
import {ITEMS_LIST_OFFSET} from '@src/pages/repricer/consts';
import {PRICE_COMPETITIVENESS_TYPE} from '@src/shared/enums/price-competitiveness-type';
import {useStore} from '@src/stores';
import {TMpAccountItem} from '@src/stores/market-place-account-item/types';

import {ProductsTable} from './products-table/products-table';
import {TSelectedProductFilters} from '../../../../types/products';
import {ProductFilters} from '../../../product-filters/product-filters';

import {useEmotionWrapper} from './add-items.s';

interface IAddProducts {
  selectedFiltersAddStep: TSelectedProductFilters;
  setSelectedFiltersAddStep: (selectedFilters: TSelectedProductFilters) => void;
}

export const AddProducts: FC<IAddProducts> = observer(({selectedFiltersAddStep, setSelectedFiltersAddStep}) => {
  const {classes} = useEmotionWrapper();
  const {mpAccounts, repricer} = useStore();
  const [searchParams] = useSearchParams();
  const priceCompetitivenessTypes = searchParams.get('priceCompetitivenessTypes');
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  const [isPriceCompetitivenessTypesSet, setIsPriceCompetitivenessTypesSet] = useState(false);

  const [products, setProducts] = useState<TMpAccountItem[]>([]);

  const [pageParams, setPageParams] = useState({offset: 0, limit: ITEMS_LIST_OFFSET, count: 0});
  const debouncedSearch = useDebounce(selectedFiltersAddStep.search, 300);

  const onLoadProducts = async (offset: number) => {
    const {shops, categories, vendors, groups, priceCompetitivenessTypes} = selectedFiltersAddStep;

    return await repricer.getItemsByAccountId({
      marketPlaceAccountId: mpAccounts.current?.id,
      priceCompetitivenessTypes: priceCompetitivenessTypes,
      shopsIds: shops,
      categoriesId: categories,
      vendorsIds: vendors,
      groupIds: groups,
      offset: offset ? offset : pageParams.offset,
      limit: pageParams.limit,
      search: debouncedSearch,
    });
  };

  const loadProducts = async (offset: number = 0) => {
    setIsLoadingProducts(true);

    try {
      const res = await onLoadProducts(offset);

      setProducts(res?.items as unknown as TMpAccountItem[]);
      setPageParams({offset: offset, limit: res?.limit, count: res?.count});
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoadingProducts(false);
    }
  };
  const loadMoreProducts = async (offset: number) => {
    if (offset < pageParams.count) {
      try {
        const res = await onLoadProducts(offset);

        setProducts([...products, ...(res?.items as unknown as TMpAccountItem[])]);
        setPageParams({offset: res?.offset, limit: res?.limit, count: res?.count});
      } catch (e) {
        console.log('e', e);
      }
    }
  };

  useEffect(() => {
    setSelectedFiltersAddStep({
      ...selectedFiltersAddStep,
      priceCompetitivenessTypes: priceCompetitivenessTypes
        ? ([priceCompetitivenessTypes] as PRICE_COMPETITIVENESS_TYPE[])
        : [],
    });
    setIsPriceCompetitivenessTypesSet(true);
  }, [priceCompetitivenessTypes]);

  useEffect(() => {
    if (mpAccounts.current?.id && isPriceCompetitivenessTypesSet) {
      loadProducts();
    }
  }, [
    selectedFiltersAddStep.priceCompetitivenessTypes?.length,
    selectedFiltersAddStep.categories,
    selectedFiltersAddStep.groups,
    selectedFiltersAddStep.shops,
    selectedFiltersAddStep.vendors,
    debouncedSearch,
    mpAccounts.current?.id,
    isPriceCompetitivenessTypesSet,
  ]);

  const countSelectedFilters = Object.values(selectedFiltersAddStep).filter((item) => item?.length > 0).length;

  return (
    <div className={classes.root}>
      <ProductFilters selectedFilters={selectedFiltersAddStep} onChangeItemFilters={setSelectedFiltersAddStep} />
      <div className={classes.tableContainer}>
        <ProductsTable
          products={products}
          isLoadingProducts={isLoadingProducts}
          offset={pageParams.offset}
          loadProducts={loadMoreProducts}
          countSelectedFilters={countSelectedFilters}
        />
      </div>
    </div>
  );
});
