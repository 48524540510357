import {type FC} from 'react';

import {Typography} from '@mui/material';
import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';
import {PRICE_COMPETITIVENESS_TYPE} from '@src/shared/enums/price-competitiveness-type';
import {TPriceCompetitiveness} from '@src/stores/market-place-account-item/types';

import {useEmotionWrapper} from './competitiveness-item.s';

interface ICompetitivenessItemProps {
  item: TPriceCompetitiveness;
}

export const CompetitivenessItem: FC<ICompetitivenessItemProps> = ({item}) => {
  const {classes} = useEmotionWrapper();

  const label = () => {
    switch (item.priceCompetitiveness) {
      case PRICE_COMPETITIVENESS_TYPE.OPTIMAL:
        return <Typography classes={{root: classes.optimal}}>Привлекательные</Typography>;
      case PRICE_COMPETITIVENESS_TYPE.AVERAGE:
        return <Typography classes={{root: classes.average}}>Умеренные</Typography>;
      case PRICE_COMPETITIVENESS_TYPE.LOW:
        return <Typography classes={{root: classes.low}}>Непривлекательные</Typography>;
    }
  };

  const description = () => {
    switch (item.priceCompetitiveness) {
      case PRICE_COMPETITIVENESS_TYPE.AVERAGE:
        return 'Средние шансы на скидку';
      case PRICE_COMPETITIVENESS_TYPE.OPTIMAL:
        return 'Высокие шансы на скидку';
      case PRICE_COMPETITIVENESS_TYPE.LOW:
        return 'Нет шансов на скидку';
    }
  };

  return (
    <div className={classes.competitivenessItem}>
      {label()}
      <MTypography className={classes.itemsCount}>{item.itemCount} шт.</MTypography>
      <MTypography className={classes.itemDescription}>{description()}</MTypography>
    </div>
  );
};
