import {emotionWrapper} from '@shared/lib';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';

export const useEmotionWrapper = emotionWrapper(() => ({
  isAutoSwitch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    position: 'relative',
  },
  switchLabel: {
    color: COLORS_REDESIGN.NIGHT_GREY,
    fontSize: 14,
  },
}));
