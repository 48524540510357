import {FC, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import {Box, CircularProgress, FormControl, FormHelperText, Input, InputAdornment} from '@mui/material';
import Menu from '@mui/material/Menu/Menu';
import {MButton} from '@src/components/redesign/mui-components/common/button/button';
import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';
import {COLORS} from '@src/shared/const/appPalette';
import {useStore} from '@src/stores';
import {TRepricerItemCompetitor} from '@src/stores/repricer/types';

import {CompetitorItem} from './components/competitor-item/competitor-item';

import {useEmotionWrapper} from './competitors-button.s';

type TAddNewCompetitor = {
  url: string;
  enabled: boolean;
};

interface ICompetitorsButton {
  competitors: TRepricerItemCompetitor[];
  itemId: number;
  disabled?: boolean;
}

export const CompetitorsButton: FC<ICompetitorsButton> = observer(({itemId, competitors, disabled = false}) => {
  const {classes} = useEmotionWrapper();
  const {repricer} = useStore();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [newCompetitorUrl, setNewCompetitorUrl] = useState<string>('');
  const [competitorsList, setCompetitorsList] = useState<TRepricerItemCompetitor[]>([]);
  const [isLoadingAddNewCompetitor, setIsLoadingAddNewCompetitor] = useState<boolean>(false);

  const getCompetitors = async () => {
    try {
      const res = await repricer.getItemCompetitors(itemId);

      setCompetitorsList(res);
    } catch (e) {
      console.log('e', e);
    }
  };

  useEffect(() => {
    if (open) {
      getCompetitors();
    }

    return () => {
      setNewCompetitorUrl('');
    };
  }, [open]);

  useLayoutEffect(() => {
    if (itemId) {
      setCompetitorsList(competitors);
    }
  }, [itemId]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickAddCompetitor = async (competitor: TAddNewCompetitor) => {
    try {
      setIsLoadingAddNewCompetitor(true);

      await repricer.addItemCompetitors({itemId, competitors: [competitor]});
      await getCompetitors();

      setNewCompetitorUrl('');
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoadingAddNewCompetitor(false);
    }
  };

  const onClickDeleteCompetitors = async (competitorId: number) => {
    try {
      await repricer.deleteItemCompetitors({itemId, competitorIds: [competitorId]});
      await getCompetitors();
    } catch (e) {
      console.log('e', e);
    }
  };

  const onChangeEnabled = async (competitor: TRepricerItemCompetitor) => {
    try {
      await repricer.updateItemCompetitors({itemId, competitors: [competitor]});
      await getCompetitors();
    } catch (e) {
      console.log('e', e);
    }
  };

  const onUpdateCompetitorName = async (competitor: TRepricerItemCompetitor) => {
    try {
      await repricer.updateItemCompetitors({itemId, competitors: [competitor]});
      await getCompetitors();
    } catch (e) {
      console.log('e', e);
    }
  };

  const isValidNewCompetitor = useMemo(() => {
    return competitorsList?.every((c) => c.url !== newCompetitorUrl);
  }, [competitorsList, newCompetitorUrl]);

  return (
    <>
      <MButton
        variant="text"
        disabled={disabled}
        onClick={handleClick}
        startIcon={competitorsList.length ? null : <AddCircleOutlineIcon />}
      >
        {competitorsList.length ? `Конкуренты (${competitorsList.length})` : 'Отслеживать'}
      </MButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <div className={classes.root}>
          <FormControl fullWidth error={!isValidNewCompetitor} variant="standard">
            <Input
              fullWidth
              size="small"
              placeholder="Вставьте ссылку на конкурента"
              className={classes.newCompetitorInput}
              onChange={(e) => setNewCompetitorUrl(e.target.value)}
              value={newCompetitorUrl}
              endAdornment={<InputAdornment sx={{paddingBottom: 1}} position="end"></InputAdornment>}
            />
            <FormHelperText>{isValidNewCompetitor ? '' : 'Указанная ссылка уже добавлена'}</FormHelperText>
          </FormControl>
          {
            <MButton
              size="small"
              variant="contained"
              classes={{root: classes.saveButton}}
              disabled={!isValidNewCompetitor || !newCompetitorUrl}
              onClick={() => onClickAddCompetitor({url: newCompetitorUrl, enabled: true})}
            >
              {isLoadingAddNewCompetitor ? <CircularProgress sx={{color: COLORS.WHITE}} size={20} /> : <CheckIcon />}
            </MButton>
          }
        </div>
        {competitorsList.length > 0 ? (
          competitorsList?.map((competitor, idx) => (
            <CompetitorItem
              key={competitor.id}
              idx={idx}
              competitor={competitor}
              onClickDeleteCompetitors={onClickDeleteCompetitors}
              onChangeEnabled={onChangeEnabled}
              onUpdateCompetitorName={onUpdateCompetitorName}
            />
          ))
        ) : (
          <Box className={classes.competitorsNotFound}>
            <MTypography className={classes.competitorsNotFoundPlaceholder}>Ссылки конкурентов отсутствуют</MTypography>
          </Box>
        )}
      </Menu>
    </>
  );
});
