import {types} from 'mobx-state-tree';

import {IS_VIEWED_INVITE_TO_PARTNERS_MODAL} from '@src/shared/const/localStorage';
import {IS_CLOSED_ACCOUNTING_MODAL} from '@src/shared/const/session-storage';

export const UtilsStore = types
  .model('UtilsStore', {
    isNetworkError: types.optional(types.boolean, false),
    isOpenOfferTrialModal: types.optional(types.boolean, false),
    isOpenAccountingModal: types.optional(types.boolean, false),
    isOpenInviteToPartnerModal: types.optional(types.boolean, false),
    isOpenIberegModal: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setNetworkError: (isNetworkError: boolean) => {
      self.isNetworkError = isNetworkError;
    },
    openOfferTrialModal: () => {
      self.isOpenOfferTrialModal = true;
    },
    closeOfferTrialModal: () => {
      self.isOpenOfferTrialModal = false;
    },
    openAccountingModal: () => {
      self.isOpenAccountingModal = true;
    },
    closeAccountingModal: () => {
      self.isOpenAccountingModal = false;
      sessionStorage.setItem(IS_CLOSED_ACCOUNTING_MODAL, '1');
    },
    openInviteToPartnerModal: () => {
      self.isOpenInviteToPartnerModal = true;
    },
    closeInviteToPartnerModal: () => {
      self.isOpenInviteToPartnerModal = false;
      localStorage.setItem(IS_VIEWED_INVITE_TO_PARTNERS_MODAL, '1');
    },
    openIberegModal: () => {
      self.isOpenIberegModal = true;
    },
    closeIberegModal: () => {
      self.isOpenIberegModal = false;
    },
  }));
