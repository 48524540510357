export const CURRENT_MP_ACCOUNT = 'currentMpAccount';
export const USER_AUTH_ACCESS_TOKEN = 'accessToken';
export const USER_AUTH_REFRESH_TOKEN = 'refreshToken';
export const SIDEBAR_IS_COMPACT = 'sideBarIsCompact';
export const LIMIT_ITEMS_ON_PAGE = 'limitItemsOnPage';
export const LIMIT_ITEMS_ON_PAGE_PRICE_MONITORING = 'limitItemsOnPagePriceMonitoring';
export const SELECTED_FILTERS_ON_LOCAL_STORAGE = 'selectedFiltersOnLocalStorage';
export const CUSTOMIZE_STRATEGIES_FASTER_NOTIFY_VIEWED = 'customizeStrategiesFasterNotifyViewed';
export const PRODUCTS_REGION_SETTINGS = 'productsRegionSettings';
export const IS_VIEWED_REPRICER_PAGE_DESCRIPTION = 'is_viewed_repricer_page_description';
export const RULES_IS_DESCRIPTION_CLOSED = 'rulesIsDescriptionClosed';
export const GROUPS_IS_DESCRIPTION_CLOSED = 'groupsIsDescriptionClosed';
export const PARTNERS_IS_DESCRIPTION_CLOSED = 'partnersIsDescriptionClosed';
export const IS_VIEWED_ONBOARDING_TOUR = 'isViewedOnboardingTour';
export const IS_SENDED_USER_CONTACTS = 'isSendedUserContacts';
export const IS_VIEWED_LAST_CHANGE_LOG_VERSION = 'isViewedLastChangeLogVersion';
export const REFERRAL_ID = 'referralId';
export const IS_VIEWED_ITEMS_BIDDER_LIMIT_NOTIFY = 'isViewedItemsBidderLimitNotify';
export const PRICE_RECOMMENDATION_NOTIFY_LAST_SEEN = 'priceRecommendationNotifyLastSeen';
export const IS_VIEWED_INVITE_TO_PARTNERS_MODAL = 'isViewedInviteToPartnersModal';
