import {FC, useState} from 'react';
import {observer} from 'mobx-react-lite';

import {Button, IconButton, Tooltip, Typography} from '@src/components';
import {CopyToClipboardIcon, PlusIcon, PriceMonitoringIcon} from '@src/components/common/icons';
import {COLORS, COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {PRODUCT_LINKS_TYPE} from '@src/shared/enums/product-links';

import {PRODUCT_SEARCH_BASE_URL} from '../../consts';
import {TProductLinks, TProductOtherLink, TShop} from '../../types';
import {handleClickToClipboard} from '../../utils/click-to-clipboard';
import {extractFirstTwoWords} from '../../utils/extract-first-two-words';
import {AnalysisShopTooltip} from '../analysis-shop-tooltip/analysis-shop-tooltip';
import {CustomLink} from '../custom-link/custom-link';
import {LinkSkeleton} from '../link-skeleton/link-skeleton';
import {SearchLink} from '../search-link/search-link';

import {useEmotionWrapper} from './links-block.s';

interface ILinksBlock {
  productLinks: TProductLinks;
  productShops: TShop[];
  productName: string;
  isDisabled: boolean;
  isLoadingOtherLinks: boolean;
  toggleEditMode: () => void;
  onChangeCurrentLink: (link: TProductOtherLink) => void;
}

export const LinksBlock: FC<ILinksBlock> = observer(
  ({productLinks, productName, isDisabled, toggleEditMode, onChangeCurrentLink, productShops, isLoadingOtherLinks}) => {
    const {editLink, offersLink, productLink, otherLinks} = productLinks;
    const shortName = extractFirstTwoWords(productName);
    const {classes} = useEmotionWrapper({isDisabled});
    const productByNameLink = `${PRODUCT_SEARCH_BASE_URL}${productName}`;
    const productByShortNameLink = `${PRODUCT_SEARCH_BASE_URL}${shortName}`;
    const [isOpenAnalysisTooltip, setIsOpenAnalysisTooltip] = useState(false);

    const renderOtherLinksList = () => {
      if (otherLinks.length > 0) {
        return otherLinks?.map((link) => (
          <li key={link.id}>
            {link.type === PRODUCT_LINKS_TYPE.SEARCH ? (
              <SearchLink
                link={link}
                productShops={productShops}
                isDisabled={isDisabled}
                onChangeCurrentLink={onChangeCurrentLink}
              />
            ) : (
              <CustomLink link={link} isDisabled={isDisabled} onChangeCurrentLink={onChangeCurrentLink} />
            )}
          </li>
        ));
      } else {
        return (
          <Typography align="center" paragraph color={COLORS.DARK_70}>
            Нет пользовательских ссылок
          </Typography>
        );
      }
    };

    return (
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <ul className={classes.linkList}>
          {productLink && (
            <li>
              <div
                className={classes.liTitle}
                onClick={() => {
                  productLink && window.open(productLink, '_blank');
                }}
              >
                <Typography color={isDisabled ? COLORS.DARK_20 : COLORS_REDESIGN.DARK_NIGHT_BLUE}>
                  Открыть на ЯМ
                </Typography>
              </div>
              <IconButton size="small" onClick={() => productLink && handleClickToClipboard(productLink)}>
                <CopyToClipboardIcon />
              </IconButton>
            </li>
          )}
          {offersLink && (
            <li>
              <div className={classes.liTitle} onClick={() => offersLink && window.open(offersLink, '_blank')}>
                <Typography color={isDisabled ? COLORS.DARK_20 : COLORS_REDESIGN.DARK_NIGHT_BLUE}>
                  Все предложения на ЯМ
                </Typography>
              </div>
              <IconButton size="small" onClick={() => offersLink && handleClickToClipboard(offersLink)}>
                <CopyToClipboardIcon />
              </IconButton>
            </li>
          )}
          <li>
            <div
              className={classes.liTitle}
              onClick={() => productByNameLink && window.open(productByNameLink, '_blank')}
            >
              <Typography color={isDisabled ? COLORS.DARK_20 : COLORS_REDESIGN.DARK_NIGHT_BLUE}>
                Поиск по названию на ЯМ
              </Typography>
            </div>
            <div style={{display: 'flex', alignItems: 'center', gap: 5}}>
              <IconButton size="small" onClick={() => productByNameLink && handleClickToClipboard(productByNameLink)}>
                <CopyToClipboardIcon />
              </IconButton>
              <Tooltip destroy={isOpenAnalysisTooltip} content="Анализ  товаров в поиске">
                <Tooltip
                  interactive
                  onClickOutside={() => setIsOpenAnalysisTooltip(false)}
                  visible={isOpenAnalysisTooltip}
                  content={
                    <AnalysisShopTooltip
                      link={productByNameLink}
                      productShops={productShops}
                      visible={isOpenAnalysisTooltip}
                      onClose={() => setIsOpenAnalysisTooltip(false)}
                    />
                  }
                >
                  <IconButton size="small" onClick={() => setIsOpenAnalysisTooltip(!isOpenAnalysisTooltip)}>
                    <PriceMonitoringIcon />
                  </IconButton>
                </Tooltip>
              </Tooltip>
            </div>
          </li>
          <li>
            <div className={classes.liTitle} onClick={() => window.open(productByShortNameLink, '_blank')}>
              <Typography color={isDisabled ? COLORS.DARK_20 : COLORS_REDESIGN.DARK_NIGHT_BLUE}>
                Поиск по короткому названию на ЯМ
              </Typography>
            </div>
            <div style={{display: 'flex', alignItems: 'center', gap: 5}}>
              <IconButton size="small" onClick={() => handleClickToClipboard(productByShortNameLink)}>
                <CopyToClipboardIcon />
              </IconButton>
            </div>
          </li>
          {editLink && (
            <li onClick={() => editLink && window.open(editLink, '_blank')}>
              <div className={classes.liTitle}>
                <Typography color={isDisabled ? COLORS.DARK_20 : COLORS_REDESIGN.DARK_NIGHT_BLUE}>
                  Редактировать карточку на ЯМ
                </Typography>
              </div>
            </li>
          )}
          <div style={{marginTop: 10}}>
            <Typography weight={600} color={COLORS.DARK_70}>
              Пользовательские ссылки
            </Typography>
            {isLoadingOtherLinks ? <LinkSkeleton /> : renderOtherLinksList()}
          </div>
        </ul>
        <Button onClick={toggleEditMode} color={COLORS.GREEN_100} variant="outlined" icon={<PlusIcon />}>
          Добавить ссылку
        </Button>
      </div>
    );
  }
);
