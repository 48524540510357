import {type FC, useLayoutEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';

import InsightsIcon from '@mui/icons-material/Insights';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LoadingButton from '@mui/lab/LoadingButton';
import {Tooltip, Typography} from '@mui/material';
import {Link as LinkMUI} from '@mui/material';
import {NoImageIcon} from '@src/components/common/icons/components/no-image';
import {Link} from '@src/components/common/link/link';
import {useConfirmationDialog} from '@src/components/providers/confirm-dialog/confirm-dialog';
import {MIconButton} from '@src/components/redesign/mui-components/common/icon-button/icon-button';
import {MTableCell} from '@src/components/redesign/mui-components/common/table/table-cell';
import {MTableRow} from '@src/components/redesign/mui-components/common/table/table-row';
import {useStrategyContext} from '@src/pages/repricer/hooks/useStrategyContext';
import {TProductPrices, TRepricerItem} from '@src/pages/repricer/types/repricer-item';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {getRouteSettings} from '@src/shared/const/router';
import {getProductLink} from '@src/shared/utils/get-product-link';
import {insertSeparatorToNumberOrString} from '@src/shared/utils/insert-separator-to-number-or-string';
import {useStore} from '@src/stores';

import {getMinMaxPrice} from '../../../../utils/get-delta-min-max-shop-prices';
import {CompetitorsButton} from '../competitors-button/competitors-button';
import {PriceUpdaterTooltip} from '../price-updater-tooltip/price-updater-tooltip';
import {RepricerItemDetails} from '../repricer-item-details/repricer-item-details';

import {useEmotionWrapper} from './product-settings-row.s';

type TRepricerItemWithId = TRepricerItem & {id: number};
export type TUpdateProductPriceOnShop = ({
  shopId,
  price,
  type,
}: {
  shopId?: number;
  price: number;
  type: TProductPrices;
}) => void;

interface IProductSettingsRow {
  product: TRepricerItem;
  collectPriceUpdateFunctions: (type: TProductPrices, updateFunction: () => Promise<void>) => void;
}

export const ProductSettingsRow: FC<IProductSettingsRow> = observer(({product, collectPriceUpdateFunctions}) => {
  const {classes} = useEmotionWrapper();
  const {mpAccounts, repricer} = useStore();
  const [isOpen, setIsOpen] = useState(false);
  const {strategy, deleteProductIds, deleteProduct} = useStrategyContext();
  const [isOpenAnalytics, setIsOpenAnalytics] = useState(false);
  const [isErrorLoadImage, setIsErrorLoadImage] = useState(false);
  const [newProductData, setNewProductData] = useState<TRepricerItemWithId>({} as TRepricerItemWithId);
  const isOnlyDefaultPrice = mpAccounts.current?.onlyDefaultPriceEnabled;
  const {getConfirmation} = useConfirmationDialog();
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  useLayoutEffect(() => {
    setNewProductData(product as TRepricerItemWithId);
  }, [product]);

  const productHasOneShop = newProductData?.shops?.length === 1;

  const updateProductPriceOnShop: TUpdateProductPriceOnShop = (updateData) => {
    const {shopId, price, type} = updateData;
    const newProductsOnStrategy = {...newProductData};

    if (mpAccounts.current?.onlyDefaultPriceEnabled) {
      newProductsOnStrategy.price = price;
      setNewProductData(newProductsOnStrategy);
      return;
    }

    if (shopId) {
      const findShopIndex = newProductsOnStrategy.shops.findIndex((shop) => shop.shopId === shopId);

      if (findShopIndex !== -1) {
        newProductsOnStrategy.shops[findShopIndex][type] = price;
      }

      setNewProductData({...newProductsOnStrategy});
    } else {
      const updatedShops = newProductsOnStrategy.shops.map((shop) => {
        return {...shop, [type]: price};
      });

      setNewProductData({...newProductsOnStrategy, price: price, shops: updatedShops as TRepricerItemWithId['shops']});
    }
  };

  const formatPriceToString = (price: number | null) => {
    return price ? `${insertSeparatorToNumberOrString(price)} ₽` : '–';
  };

  const getMinPriceWithPercent = () => {
    return product.shops[0]?.minPrice || '0';
  };

  const getMaxPriceWithPercent = () => {
    return product.shops[0]?.maxPrice || '0';
  };

  const deleteProductOnStrategy = async () => {
    const confirm = await getConfirmation({
      title: `Удаление товара`,
      message: (
        <Typography>
          Вы дествительно хотите удалить товар <b>{product.name}</b> из стратегии?
        </Typography>
      ),
    });
    if (confirm && mpAccounts.current?.id) {
      setIsLoadingDelete(true);
      await repricer
        .deleteItemsFromStrategy({strategyId: strategy.id, ids: [product.itemId]})
        .then(() => deleteProduct(product.itemId));
      await repricer.getStrategies({marketPlaceAccountId: mpAccounts.current?.id});

      //Удаляем из массива товаров на удаление, чтобы не дублировать запрос
      deleteProduct(product.itemId);
      setIsLoadingDelete(false);
    }
  };

  if (deleteProductIds.includes(product.itemId)) return null;

  return (
    <>
      <MTableRow
        sx={{
          backgroundColor: isOpen ? COLORS_REDESIGN.LIGHT_GREY : 'transparent',
        }}
      >
        <MTableCell align="center">
          <RepricerItemDetails
            product={newProductData as TRepricerItem}
            isOpenAnalytics={isOpenAnalytics}
            setIsOpenAnalytics={setIsOpenAnalytics}
            updateProductPriceOnShop={updateProductPriceOnShop}
          />
          {!productHasOneShop ? (
            <MIconButton disabled={!product.isActive} onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </MIconButton>
          ) : (
            <div className={classes.collapseNoButton} />
          )}
        </MTableCell>

        <MTableCell sx={{filter: product.isActive ? 'none' : 'grayscale(100%)', opacity: product.isActive ? 1 : 0.5}}>
          <div className={classes.nameCell}>
            {isErrorLoadImage ? (
              <div className={classes.noImage}>
                <NoImageIcon color={COLORS_REDESIGN.DARK_GREY} size="32" />
              </div>
            ) : (
              <img
                onError={() => setIsErrorLoadImage(true)}
                loading="lazy"
                width={34}
                height={45}
                src={product.picture || ''}
              />
            )}
            <div className={classes.productInfo}>
              <Tooltip
                arrow
                enterDelay={500}
                sx={{display: 'inline !important', pointerEvents: product.isActive ? 'auto' : 'none'}}
                classes={{tooltip: classes.customTooltip, arrow: classes.customTooltipArrow}}
                title={
                  product.isActive ? (
                    <Typography sx={{whiteSpace: 'nowrap', fontSize: 12, color: COLORS_REDESIGN.WHITE}}>
                      Нажмите, для перехода на страницу товара
                    </Typography>
                  ) : (
                    ''
                  )
                }
              >
                <LinkMUI
                  target="_blank"
                  href={getProductLink(product.marketProductId, product.marketSku) || ''}
                  className={classes.productName}
                >
                  {product.name}
                </LinkMUI>
              </Tooltip>
              <span className={classes.productSku}>
                SKU: {product.sku} · {product.category}
              </span>
            </div>
          </div>
        </MTableCell>
        <MTableCell>
          <Tooltip
            arrow
            title="Анализируйте цены конкурентов на карточке, сравнивайте с рекомендациями Маркета по ценам и решайте какую цену установить на свой товар. Или включите автоуправление"
          >
            <div>
              <MIconButton disabled={!product.isActive} onClick={() => setIsOpenAnalytics(true)} size="small">
                <InsightsIcon sx={{fontSize: 20}} />
              </MIconButton>
            </div>
          </Tooltip>
        </MTableCell>
        {product.isActive ? (
          <>
            <MTableCell align="center">
              <PriceUpdaterTooltip
                type="price"
                price={
                  isOnlyDefaultPrice
                    ? newProductData?.price
                    : newProductData.shops?.[0]?.price
                      ? newProductData?.shops[0]?.price
                      : newProductData?.price
                }
                collectPriceUpdateFunctions={collectPriceUpdateFunctions}
                updateProductPriceOnShop={updateProductPriceOnShop}
                shop={newProductData.shops?.[0]}
                isAllShops={productHasOneShop ? false : true}
                productId={newProductData?.id}
              >
                {isOnlyDefaultPrice
                  ? insertSeparatorToNumberOrString(newProductData.price)
                  : productHasOneShop
                    ? insertSeparatorToNumberOrString(
                        newProductData.shops[0].price ? newProductData.shops[0].price : newProductData.price
                      )
                    : getMinMaxPrice({shops: newProductData.shops}).price}{' '}
                ₽
              </PriceUpdaterTooltip>
            </MTableCell>
            <MTableCell align="center">
              <PriceUpdaterTooltip
                type="minPrice"
                minPrice={getMinPriceWithPercent()}
                collectPriceUpdateFunctions={collectPriceUpdateFunctions}
                updateProductPriceOnShop={updateProductPriceOnShop}
                shop={newProductData.shops?.[0]}
                isAllShops={productHasOneShop ? false : true}
                productId={newProductData?.id}
              >
                {productHasOneShop ? getMinPriceWithPercent() : getMinMaxPrice({shops: newProductData.shops}).minPrice}{' '}
                {''}₽
              </PriceUpdaterTooltip>
            </MTableCell>
            <MTableCell align="center">
              <PriceUpdaterTooltip
                type="maxPrice"
                maxPrice={getMaxPriceWithPercent()}
                collectPriceUpdateFunctions={collectPriceUpdateFunctions}
                updateProductPriceOnShop={updateProductPriceOnShop}
                shop={newProductData.shops?.[0]}
                isAllShops={productHasOneShop ? false : true}
                productId={newProductData?.id}
              >
                {productHasOneShop ? getMaxPriceWithPercent() : getMinMaxPrice({shops: newProductData.shops}).maxPrice}{' '}
                {''}₽
              </PriceUpdaterTooltip>
            </MTableCell>
            <MTableCell>
              <CompetitorsButton
                disabled={!product.isActive}
                itemId={newProductData.id}
                competitors={product.competitors || []}
              />
            </MTableCell>
          </>
        ) : (
          <MTableCell
            align="center"
            colSpan={4}
            sx={{
              fontWeight: 500,
              color: COLORS_REDESIGN.DARK_GREY,
              opacity: '1 !important',
              filter: 'none !important',
            }}
          >
            <div>Товар скрыт с витрины</div>
            <LoadingButton
              color="primary"
              size="small"
              variant="text"
              loading={isLoadingDelete}
              onClick={deleteProductOnStrategy}
              sx={{fontWeight: 500, opacity: '1 !important', filter: 'none !important', textDecoration: 'underline'}}
            >
              Удалить товар из стратегии
            </LoadingButton>
          </MTableCell>
        )}
      </MTableRow>

      {isOpen &&
        !productHasOneShop &&
        newProductData.shops.map((s) => (
          <MTableRow sx={{backgroundColor: isOpen ? COLORS_REDESIGN.LIGHT_GREY : 'transparent'}} key={s.shopId}>
            <MTableCell align="center">
              <div className={classes.collapseNoButton} />
            </MTableCell>
            <MTableCell>
              <div className={classes.nameCell}>
                <img width={34} height={45} src={product.picture || ''} />
                <div className={classes.productInfo}>
                  <span className={classes.productName}>{product.name}</span>
                  <span style={{fontWeight: 700}} className={classes.productSku}>
                    {s.shopName}{' '}
                  </span>
                </div>
              </div>
            </MTableCell>
            <MTableCell />
            <MTableCell align="center">
              <Tooltip
                title={
                  mpAccounts.current?.onlyDefaultPriceEnabled ? (
                    <>
                      Редактирование доступно только при активной модели{' '}
                      <Link href={`${getRouteSettings()}/price-monitoring`}>"От цен в магазинах"</Link>
                    </>
                  ) : (
                    ''
                  )
                }
              >
                <div>
                  <PriceUpdaterTooltip
                    disabled={mpAccounts.current?.onlyDefaultPriceEnabled}
                    collectPriceUpdateFunctions={collectPriceUpdateFunctions}
                    updateProductPriceOnShop={updateProductPriceOnShop}
                    shop={s}
                    type="price"
                    productId={newProductData?.id}
                  >
                    {formatPriceToString(s.price)}
                  </PriceUpdaterTooltip>
                </div>
              </Tooltip>
            </MTableCell>
            <MTableCell align="center">
              <PriceUpdaterTooltip
                collectPriceUpdateFunctions={collectPriceUpdateFunctions}
                updateProductPriceOnShop={updateProductPriceOnShop}
                shop={s}
                type="minPrice"
                productId={newProductData?.id}
              >
                {formatPriceToString(s.minPrice)}
              </PriceUpdaterTooltip>
            </MTableCell>
            <MTableCell align="center">
              <PriceUpdaterTooltip
                collectPriceUpdateFunctions={collectPriceUpdateFunctions}
                updateProductPriceOnShop={updateProductPriceOnShop}
                shop={s}
                type="maxPrice"
                productId={newProductData?.id}
              >
                {formatPriceToString(s.maxPrice)}
              </PriceUpdaterTooltip>
            </MTableCell>
            <MTableCell></MTableCell>
          </MTableRow>
        ))}
    </>
  );
});
