import {FC} from 'react';

import {Typography} from '@src/components/common/typography';
import {COLORS, COLORS_REDESIGN} from '@src/shared/const/appPalette';

import {LOGS_TYPES} from '../consts';
import {TLogType} from '../types';

import {useEmotionWrapper} from './log-item.s';

interface ILogItem {
  date: string;
  items: TLogType[];
}

export const LogItem: FC<ILogItem> = ({date, items}) => {
  const {classes} = useEmotionWrapper();

  const getLabelColor = (type: string) => {
    switch (type) {
      case LOGS_TYPES.feature:
        return COLORS.GREEN_20;

      case LOGS_TYPES.update:
        return COLORS.YELLOW_20;

      case LOGS_TYPES.bugfix:
        return COLORS.RED_20;
    }
  };

  return (
    <div className={classes.root}>
      <div style={{width: 100}}>
        <Typography size={20} weight={600} color={COLORS_REDESIGN.DARK_NIGHT_BLUE}>
          {date}
        </Typography>
      </div>
      <div className={classes.description}>
        {items.map((item) => (
          <div className={classes.logItem}>
            <div className={classes.labelContainer}>
              <div className={classes.label} style={{backgroundColor: getLabelColor(item.type)}}>
                {item.type}
              </div>
            </div>
            <div className={classes.logContent}>{item.content}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
