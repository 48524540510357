import {emotionWrapper} from '@shared/lib';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 5,
  },
  newCompetitorInput: {
    padding: '0 10px',
    minWidth: 260,
  },
  saveButton: {
    marginBottom: 1,
    marginRight: 5,
    width: 24,
    height: 32,
  },
  competitorsNotFound: {
    display: 'flex',
    justifyContent: 'center',
    padding: '5px 0',
  },
  competitorsNotFoundPlaceholder: {
    color: COLORS_REDESIGN.DARK_GREY,
  },
}));
