import {type FC, memo} from 'react';

import Step from '@mui/material/Step/Step';
import StepLabel from '@mui/material/StepLabel/StepLabel';
import Stepper from '@mui/material/Stepper/Stepper';

import {useEmotionWrapper} from './edit-strategy-stepper.s';

interface IEditStrategyStepperProps {
  steps: string[];
  currentStep: number;
  setCurrentStepHandler: (step: number) => void;
}

export const EditStrategyStepper: FC<IEditStrategyStepperProps> = memo(
  ({steps, currentStep, setCurrentStepHandler}) => {
    const {classes} = useEmotionWrapper();

    return (
      <Stepper classes={{root: classes.rootStepper}} activeStep={currentStep}>
        {steps.map((label) => (
          <Step completed={currentStep >= steps.indexOf(label)} key={label}>
            <StepLabel
              onClick={() => setCurrentStepHandler(steps.indexOf(label))}
              classes={{root: classes.label, active: classes.stepLabel}}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  }
);
