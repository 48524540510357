import {emotionWrapper} from '@shared/lib';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';

export const useEmotionWrapper = emotionWrapper(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  filtersAndStats: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 1550px)': {
      alignItems: 'flex-end',
    },
  },
  headerButton: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    color: COLORS_REDESIGN.GENERAL_GRAY,
  },
}));
