import {FC} from 'react';
import {observer} from 'mobx-react-lite';

import FormControl from '@mui/material/FormControl/FormControl';
import InputLabel from '@mui/material/InputLabel/InputLabel';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput/OutlinedInput';
import {SearchIcon} from '@src/components/common/icons/components/search';
import {MButton} from '@src/components/redesign/mui-components/common/button/button';
import {MInput} from '@src/components/redesign/mui-components/common/input/input';
import {MSelect} from '@src/components/redesign/mui-components/common/select/select';
import {TSelectedProductFilters} from '@src/pages/repricer/types/products';
import {
  PRICE_COMPETITIVENESS_TYPE,
  PRICE_COMPETITIVENESS_TYPE_OPTIONS,
} from '@src/shared/enums/price-competitiveness-type';
import {declOfNum} from '@src/shared/utils/declOfNum';
import {useStore} from '@src/stores';

import {useEmotionWrapper} from './product-filters.s';

interface IProductFilters {
  onChangeItemFilters: (selectedFilters: TSelectedProductFilters) => void;
  selectedFilters: TSelectedProductFilters;
  showPriceCompetitiveness?: boolean;
}

export const ProductFilters: FC<IProductFilters> = observer(
  ({onChangeItemFilters, selectedFilters, showPriceCompetitiveness = true}) => {
    const {classes} = useEmotionWrapper();
    const {mpItems, productGroups} = useStore();

    const hasSelectedFilter =
      selectedFilters.search.length ||
      selectedFilters.shops.length ||
      selectedFilters.categories.length ||
      selectedFilters.vendors.length ||
      (selectedFilters.priceCompetitivenessTypes && selectedFilters.priceCompetitivenessTypes.length) ||
      selectedFilters.groups.length;

    return (
      <div className={classes.root}>
        <MInput
          autoComplete="off"
          placeholder="Найти товары по названию, sku"
          sx={{width: 300}}
          fullWidth
          onChange={(e) => onChangeItemFilters({...selectedFilters, search: e.target.value})}
          value={selectedFilters.search}
          size="small"
          slotProps={{
            input: {
              endAdornment: <SearchIcon />,
            },
          }}
        />
        <FormControl>
          <InputLabel size="small">
            {selectedFilters.shops.length
              ? declOfNum(selectedFilters.shops.length, ['магазин', 'магазина', 'магазинов'])
              : 'Все магазины'}
          </InputLabel>
          <MSelect
            size="small"
            multiple
            isClearable
            sx={{width: 160}}
            value={selectedFilters.shops}
            clearFunction={() => onChangeItemFilters({...selectedFilters, shops: []})}
            onChange={(e) => onChangeItemFilters({...selectedFilters, shops: e.target.value as number[]})}
            input={
              <OutlinedInput
                label={
                  selectedFilters.shops.length
                    ? declOfNum(selectedFilters.shops.length, ['магазин', 'магазина', 'магазинов'])
                    : 'Все магазины'
                }
              />
            }
          >
            {mpItems.filters.shops.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                {s.name}
              </MenuItem>
            ))}
          </MSelect>
        </FormControl>
        <FormControl>
          <InputLabel size="small">
            {selectedFilters.categories.length
              ? declOfNum(selectedFilters.categories.length, ['категория', 'категории', 'категорий'])
              : 'Категории'}
          </InputLabel>
          <MSelect
            size="small"
            multiple
            isClearable
            sx={{width: 160}}
            value={selectedFilters.categories}
            clearFunction={() => onChangeItemFilters({...selectedFilters, categories: []})}
            onChange={(e) => onChangeItemFilters({...selectedFilters, categories: e.target.value as number[]})}
            input={
              <OutlinedInput
                label={
                  selectedFilters.categories.length
                    ? declOfNum(selectedFilters.categories.length, ['категория', 'категории', 'категорий'])
                    : 'Магазины'
                }
              />
            }
          >
            {mpItems.filters.categories.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                {s.name}
              </MenuItem>
            ))}
          </MSelect>
        </FormControl>
        <FormControl>
          <InputLabel size="small">
            {selectedFilters.vendors.length
              ? declOfNum(selectedFilters.vendors.length, ['Бренд', 'Бренда', 'Брендов'])
              : 'Бренды'}
          </InputLabel>
          <MSelect
            size="small"
            multiple
            isClearable
            sx={{width: 160}}
            value={selectedFilters.vendors}
            clearFunction={() => onChangeItemFilters({...selectedFilters, vendors: []})}
            onChange={(e) => onChangeItemFilters({...selectedFilters, vendors: e.target.value as number[]})}
            input={
              <OutlinedInput
                label={
                  selectedFilters.vendors.length
                    ? declOfNum(selectedFilters.vendors.length, ['Бренд', 'Бренды', 'Брендов'])
                    : 'Бренды'
                }
              />
            }
          >
            {mpItems.filters.vendors.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                {s.name}
              </MenuItem>
            ))}
          </MSelect>
        </FormControl>
        <FormControl>
          <InputLabel size="small">
            {selectedFilters.groups.length
              ? declOfNum(selectedFilters.groups.length, ['Группы', 'Группы', 'Групп'])
              : 'Группы'}
          </InputLabel>
          <MSelect
            size="small"
            multiple
            isClearable
            sx={{width: 160}}
            value={selectedFilters.groups}
            clearFunction={() => onChangeItemFilters({...selectedFilters, groups: []})}
            onChange={(e) => onChangeItemFilters({...selectedFilters, groups: e.target.value as number[]})}
            input={
              <OutlinedInput
                label={
                  selectedFilters.groups.length
                    ? declOfNum(selectedFilters.groups.length, ['Группы', 'Группы', 'Групп'])
                    : 'Группы'
                }
              />
            }
          >
            {productGroups.list.length ? (
              productGroups.list.map((s) => (
                <MenuItem key={s.id} value={s.id}>
                  {s.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem sx={{fontSize: 14}} disabled value="">
                <em>Нет созданных групп</em>
              </MenuItem>
            )}
          </MSelect>
        </FormControl>
        {showPriceCompetitiveness && (
          <FormControl>
            <InputLabel size="small">
              {selectedFilters.groups.length
                ? declOfNum(selectedFilters.groups.length, ['Цены', 'Цены', 'Цен'])
                : 'Цена'}
            </InputLabel>
            <MSelect
              size="small"
              multiple
              isClearable
              sx={{width: 160}}
              value={selectedFilters.priceCompetitivenessTypes}
              clearFunction={() => onChangeItemFilters({...selectedFilters, priceCompetitivenessTypes: []})}
              onChange={(e) =>
                onChangeItemFilters({
                  ...selectedFilters,
                  priceCompetitivenessTypes: e.target.value as PRICE_COMPETITIVENESS_TYPE[],
                })
              }
              input={
                <OutlinedInput
                  label={
                    selectedFilters.priceCompetitivenessTypes && selectedFilters.priceCompetitivenessTypes.length
                      ? declOfNum(selectedFilters.priceCompetitivenessTypes.length, ['Цены', 'Цены', 'Цен'])
                      : 'Цена'
                  }
                />
              }
            >
              {PRICE_COMPETITIVENESS_TYPE_OPTIONS.map((s) => (
                <MenuItem key={s.value} value={s.value}>
                  {s.label}
                </MenuItem>
              ))}
            </MSelect>
          </FormControl>
        )}
        {hasSelectedFilter ? (
          <MButton
            variant="text"
            onClick={() =>
              onChangeItemFilters({
                shops: [],
                categories: [],
                vendors: [],
                groups: [],
                priceCompetitivenessTypes: [],
                search: '',
              })
            }
          >
            Сбросить фильтры
          </MButton>
        ) : null}
      </div>
    );
  }
);
