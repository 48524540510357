import {MTableCell} from '@src/components/redesign/mui-components/common/table/table-cell';
import {MTableRow} from '@src/components/redesign/mui-components/common/table/table-row';
import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';

export const EmptyStrategies = () => {
  return (
    <MTableRow>
      <MTableCell align="center" colSpan={4}>
        <MTypography sx={{fontSize: 18, fontWeight: 700}}>Нет данных</MTypography>
      </MTableCell>
    </MTableRow>
  );
};
