import {useEffect, useMemo, useState} from 'react';
import {toast} from 'react-toastify';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import {Typography} from '@mui/material';
import {MButton} from '@src/components/redesign/mui-components/common/button/button';
import {SlideUpModal} from '@src/components/redesign/slide-up-modal/slide-up-modal';
import {useAccountLimits} from '@src/hooks/useAccountLimits';
import {useDebounce} from '@src/hooks/useDebounce';
import {getRoutePricing, getRouteRepricer} from '@src/shared/const/router';
import {REPRICER_STRATEGY} from '@src/shared/enums/repricer-strategy';
import {declOfNum} from '@src/shared/utils/declOfNum';
import {useStore} from '@src/stores';
import {TMpAccountItem} from '@src/stores/market-place-account-item/types';
import {TRepricerStrategy} from '@src/stores/repricer/types';

import {StrategyContext} from './context/create-strategy';
import {InfiniteLoadContext} from './context/infinite-load';
import {EditStrategyStepper} from './edit-strategy-stepper/edit-strategy-stepper';
import {StepperButtons} from './stepper-buttons/stepper-buttons';
import {AddProducts} from './steps/add-items/add-items';
import {StrategyDetails} from './steps/strategy-details/strategy-details';
import {adapterRepricerItem} from './utils/adapter-repricer-item';
import {updateMaxPriceWithPercentOnShops, updateMinPriceWithPercentOnShops} from './utils/update-price-on-shops';
import {NEW_STRATEGY_ID, REPRICER_SEARCH_ITEMS_OFFSET} from '../../consts';
import {STRATEGY_CREATE_STATUS} from '../../enums';
import {useStepper} from '../../hooks/useStepper';
import {UploadProductsModal} from '../../modals/upload-products-modal';
import {TSelectedProductFilters} from '../../types/products';
import {TCollectionPriceUpdateFunctions, TRepricerItem} from '../../types/repricer-item';

import {useEmotionWrapper} from './edit-strategy.s';

const steps = ['Товары', 'Настройка'];

const initialCollectionPriceUpdateFunctions = {
  price: [],
  minPrice: [],
  maxPrice: [],
};

const initialSelectedFilters = {
  search: '',
  shops: [],
  categories: [],
  vendors: [],
  groups: [],
  priceCompetitivenessTypes: [],
};

export const EditStrategyModal = observer(() => {
  const {classes} = useEmotionWrapper();
  const [searchParams] = useSearchParams();
  const isCreateNewStrategy = searchParams.get('create') === 'true';

  const navigate = useNavigate();
  const {strategyId} = useParams();
  const {repricer, mpAccounts, mpItems} = useStore();
  const {currentStep, changeCurrentStep} = useStepper();
  const {maxRepricerItems} = useAccountLimits();

  const [strategyCreationStatus, setStrategyCreationStatus] = useState(STRATEGY_CREATE_STATUS.CREATION_COMPLETED);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenUploadProductModal, setIsOpenUploadProductModal] = useState(false);
  const [isLoadingRepricerItems, setIsLoadingRepricerItems] = useState(true);
  const [isLoadingNextStep, setIsLoadingNextStep] = useState(false);

  const [strategyData, setStrategyData] = useState<TRepricerStrategy>({} as TRepricerStrategy); //обновленная информация стратегии
  const [productsOnStrategy, setProductsOnStrategy] = useState<TMpAccountItem[]>([]); //товары учавствующие в стратегии
  const [initialRepricerItems, setInitialRepricerItems] = useState<TRepricerItem[]>({} as TRepricerItem[]); // товары учавствуещие в стратегии на момент получения данных из бд
  const [addProductIds, setAddProductIds] = useState<number[]>([]); //id добавляемых товаров
  const [deleteProductIds, setDeleteProductIds] = useState<number[]>([]); //id удаляемых товаров

  const [pageParams, setPageParams] = useState({offset: 0, limit: REPRICER_SEARCH_ITEMS_OFFSET, count: 0});
  const [selectedFilters, setSelectedFilters] = useState<TSelectedProductFilters>(initialSelectedFilters); //фильтры на этапе настройки стратегии

  const [selectedFiltersAddStep, setSelectedFiltersAddStep] = useState<TSelectedProductFilters>(initialSelectedFilters); //фильтры на этапе добавления товаров

  const [collectionPriceUpdateFunctions, setCollectionPriceUpdateFunctions] = useState<TCollectionPriceUpdateFunctions>(
    initialCollectionPriceUpdateFunctions
  ); //функции обновления цен на стратегии, после создания стратегии

  const marketPlaceAccountId = mpAccounts.current?.id;
  const debouncedSearchFilter = useDebounce(selectedFilters.search, 400);

  const isRepricerLimited = useMemo(() => {
    let countItemsOnAllStrategies = productsOnStrategy.length;

    repricer.summaryRepricerItemIds.forEach((value, key) => {
      if (key === strategyId?.toString()) {
        return;
      }
      countItemsOnAllStrategies += value.itemIds.length;
    });

    return maxRepricerItems !== null && maxRepricerItems ? countItemsOnAllStrategies >= maxRepricerItems : false;
  }, [maxRepricerItems, productsOnStrategy.length, repricer.summaryRepricerItemIds]);

  const findStrategyById = useMemo(() => {
    return repricer.list.find((strategy) => strategy.id === Number(strategyId));
  }, [JSON.stringify(repricer.list), strategyId]);

  useEffect(() => {
    if (isCreateNewStrategy) {
      setStrategyCreationStatus(STRATEGY_CREATE_STATUS.CREATION_IN_PROGRESS);
    }
  }, [isCreateNewStrategy]);

  useEffect(() => {
    return () => {
      if (strategyCreationStatus === STRATEGY_CREATE_STATUS.CREATION_IN_PROGRESS) {
        repricer.deleteStrategy(Number(strategyId));
        navigate(getRouteRepricer());
      }
    };
  }, []);

  useEffect(() => {
    if (!isOpen && marketPlaceAccountId) {
      repricer.getStrategies({marketPlaceAccountId, searchItem: ''}).catch((e) => console.log(e));
    }
  }, [isOpen]);

  useEffect(() => {
    if (isRepricerLimited) {
      toast.info(
        <div>
          <Typography sx={{fontSize: 16, fontWeight: 600}}>Превышен лимит по тарифу</Typography>
          <Typography sx={{fontSize: 13}}>
            По тарифу доступно {declOfNum(maxRepricerItems || 0, ['товар', 'товара', 'товаров'])} товаров. Повысьте
            тариф, чтобы добавить больше товаров
          </Typography>
          <MButton
            onClick={() => navigate(getRoutePricing())}
            sx={{marginTop: '15px'}}
            size="small"
            variant="contained"
          >
            Повысить тариф
          </MButton>
        </div>
      );
    }
  }, [isRepricerLimited]);

  useEffect(() => {
    if (strategyId && marketPlaceAccountId) {
      setIsLoadingRepricerItems(true);
      repricer
        .getStrategyItems({
          strategyId: Number(strategyId),
          marketPlaceAccountId,
          filters: {
            search: debouncedSearchFilter,
            shopIds: selectedFilters.shops,
            categoryIds: selectedFilters.categories,
            vendorIds: selectedFilters.vendors,
            groupIds: selectedFilters.groups,
          },
          pageParams: {offset: 0, limit: pageParams.limit},
        })
        .then((res) => {
          const mappedRepricerItem = res.items.map((i: TRepricerItem) => adapterRepricerItem(i));

          setProductsOnStrategy([...mappedRepricerItem]);
          setIsLoadingRepricerItems(false);
        });
    }
  }, [
    JSON.stringify(selectedFilters.categories),
    JSON.stringify(selectedFilters.shops),
    strategyId,
    JSON.stringify(selectedFilters.vendors),
    JSON.stringify(selectedFilters.groups),
    debouncedSearchFilter,
  ]);

  useEffect(() => {
    setStrategyData({
      id: strategyId ? Number(strategyId) : NEW_STRATEGY_ID,
      name: findStrategyById?.name || '',
      type: findStrategyById?.type || REPRICER_STRATEGY.DEFAULT,
      maxPricePct: findStrategyById?.maxPricePct === undefined ? null : findStrategyById?.maxPricePct,
      minPricePct: findStrategyById?.minPricePct === undefined ? null : findStrategyById?.minPricePct,
      enabled: findStrategyById?.enabled !== undefined ? findStrategyById?.enabled : true,
      isAuto: findStrategyById?.isAuto || false,
      params: findStrategyById?.params || {place: null},
      competitors: (findStrategyById?.competitors as TRepricerStrategy['competitors']) || [],
    });
  }, [JSON.stringify(repricer.list), strategyId]);

  useEffect(() => {
    loadRepricerItems(0);
  }, [strategyId, marketPlaceAccountId]);

  useEffect(() => {
    setIsOpen(true);

    return () => {
      setIsOpen(false);
    };
  }, [strategyId]);

  const collectPriceUpdateFunctions = (
    type: 'minPrice' | 'maxPrice' | 'price',
    updateFunction: () => Promise<void>
  ) => {
    setCollectionPriceUpdateFunctions((prev) => ({
      ...prev,
      [type]: [...prev[type], updateFunction],
    }));
  };

  const resetCollectPriceUpdateFunctions = (type: 'minPrice' | 'maxPrice' | 'price') => {
    setCollectionPriceUpdateFunctions((prev) => ({
      ...prev,
      [type]: [],
    }));
  };

  const loadRepricerItems = async (offset: number) => {
    setIsLoadingRepricerItems(true);

    if (strategyId && marketPlaceAccountId) {
      const filters = {
        search: selectedFilters.search,
        shopIds: selectedFilters.shops,
        categoryIds: selectedFilters.categories,
        vendorIds: selectedFilters.vendors,
        groupIds: selectedFilters.groups,
      };
      repricer
        .getStrategyItems({
          strategyId: Number(strategyId),
          filters,
          marketPlaceAccountId,
          pageParams: {offset, limit: pageParams.limit},
        })
        .then((res) => {
          const mappedRepricerItem = res.items.map((i: TRepricerItem) => adapterRepricerItem(i)); //маппим модель TRepricerItem и TMpAccountItem (товар). У TRepricerItem добавляем поле id равное itemId

          setPageParams({offset: res.offset, limit: res.limit, count: res.count});
          setProductsOnStrategy([...mappedRepricerItem]);
          setInitialRepricerItems(mappedRepricerItem);
        })
        .catch((e) => {
          console.log('e', e);
        })
        .finally(() => {
          setIsLoadingRepricerItems(false);
        });
    }
  };

  const loadMoreRepricerItems = async (offset: number) => {
    if (offset < pageParams.count) {
      setPageParams({...pageParams, offset: offset});

      if (strategyId && marketPlaceAccountId) {
        const filters = {
          search: selectedFilters.search,
          shopIds: selectedFilters.shops,
          categoryIds: selectedFilters.categories,
          vendorIds: selectedFilters.vendors,
          groupIds: selectedFilters.groups,
        };
        try {
          const res = await repricer.getStrategyItems({
            strategyId: Number(strategyId),
            marketPlaceAccountId,
            filters,
            pageParams: {offset, limit: pageParams.limit},
          });
          const mappedRepricerItem = res.items.map((i: TRepricerItem) => adapterRepricerItem(i));
          setProductsOnStrategy((prev) => [...prev, ...mappedRepricerItem]);
          setInitialRepricerItems((prev) => [...prev, ...mappedRepricerItem]);
        } catch (e) {
          console.log('e', e);
        }
      }
    }
  };

  //при изменении максимального процента цены апдейтим на интерфейсе минимальные цены во всех магазинах
  useEffect(() => {
    const updateMinPrices = updateMinPriceWithPercentOnShops({
      products: productsOnStrategy,
      minPricePct: strategyData.minPricePct,
    });
    setProductsOnStrategy(updateMinPrices as TMpAccountItem[]);
  }, [strategyData.minPricePct]);

  //при изменении максимального процента цены апдейтим на интерфейсе максимальные цены во всех магазинах
  useEffect(() => {
    const updatedMaxPrices = updateMaxPriceWithPercentOnShops({
      products: productsOnStrategy,
      maxPricePct: strategyData.maxPricePct,
    });

    setProductsOnStrategy(updatedMaxPrices as TMpAccountItem[]);
  }, [strategyData.maxPricePct]);

  const onSelectProduct = (product: TMpAccountItem) => {
    const productId = product.id;
    const findInitialProductIndex = initialRepricerItems.findIndex((p) => p.itemId === productId);

    if (findInitialProductIndex !== -1) {
      const findIndexDelete = deleteProductIds.findIndex((p) => p === productId);
      if (findIndexDelete !== -1) {
        setDeleteProductIds([
          ...deleteProductIds.slice(0, findIndexDelete),
          ...deleteProductIds.slice(findIndexDelete + 1),
        ]);
      } else {
        setDeleteProductIds((prev) => [...prev, productId]);
      }
    } else {
      const newProductIndex = addProductIds.findIndex((p) => p === productId);

      if (newProductIndex === -1) {
        setAddProductIds([...addProductIds, product.id]);
      } else {
        setAddProductIds([...addProductIds.slice(0, newProductIndex), ...addProductIds.slice(newProductIndex + 1)]);
      }
    }
  };

  const onClose = async () => {
    if (strategyCreationStatus === STRATEGY_CREATE_STATUS.CREATION_IN_PROGRESS) {
      await repricer.deleteStrategy(Number(strategyId));
    }
    setIsOpen(false);
    setTimeout(() => navigate(getRouteRepricer()), 300);
  };

  const setCurrentStepHandler = async (newStep: number) => {
    if (newStep === 1) {
      setIsLoadingNextStep(true);
      setProductsOnStrategy([]);

      if (addProductIds.length) {
        await repricer.addItemsToStrategy({strategyId: strategyData.id, itemIds: addProductIds});
      }

      if (deleteProductIds.length) {
        await repricer.deleteItemsFromStrategy({strategyId: Number(strategyId), ids: deleteProductIds});
      }

      if (marketPlaceAccountId) {
        await repricer.getStrategies({marketPlaceAccountId, searchItem: ''});
      }

      await loadRepricerItems(0);

      setDeleteProductIds([]);
      setAddProductIds([]);

      changeCurrentStep(newStep);
      setIsLoadingNextStep(false);
    }
    if (newStep === 0) {
      changeCurrentStep(newStep);
    }
  };

  const addItemsBySelectedFilters = async () => {
    const itemsIds = await mpItems.getItemIdsList({
      marketPlaceAccountId,
      filters: {
        search: selectedFiltersAddStep.search,
        shopsIds: selectedFiltersAddStep.shops,
        categoriesId: selectedFiltersAddStep.categories,
        vendorsIds: selectedFiltersAddStep.vendors,
        groupIds: selectedFiltersAddStep.groups,
      },
    });

    const isUseItemIds: number[] = [];
    const deletedItemIds: number[] = [];

    repricer.summaryRepricerItemIds.forEach((value) => {
      isUseItemIds.push(...value.itemIds);
      const isDeleted = deleteProductIds.find((id) => value.itemIds.includes(id));
      const isUseStrategy = productsOnStrategy.some((item) => value.itemIds.includes(item.id));

      if (!isDeleted && isUseStrategy) {
        deletedItemIds.push(...value.itemIds);
      }
    });

    const filteredItemIds = itemsIds.filter((id: number) => !isUseItemIds.includes(id));
    const newItemsIds = [...filteredItemIds, ...addProductIds];

    setDeleteProductIds(deletedItemIds);
    setAddProductIds(Array.from(newItemsIds));
  };

  const deleteAllProducts = async () => {
    setAddProductIds([]);
    strategyId && setDeleteProductIds(repricer.summaryRepricerItemIds.get(strategyId.toString())?.itemIds || []);
  };

  const deleteProduct = async (itemId: number) => {
    const findIndex = deleteProductIds.findIndex((id) => id === itemId);
    if (findIndex !== -1) {
      setDeleteProductIds([...deleteProductIds.slice(0, findIndex), ...deleteProductIds.slice(findIndex + 1)]);
    } else {
      setDeleteProductIds((prev) => [...prev, itemId]);
    }
  };

  return (
    <SlideUpModal
      opened={isOpen}
      onClose={onClose}
      title={
        <div className={classes.headerRoot}>
          {strategyCreationStatus === STRATEGY_CREATE_STATUS.CREATION_IN_PROGRESS
            ? 'Создайте стратегию'
            : `Редактирование стратегии ${repricer.list.find((item) => item.id === Number(strategyId))?.name}`}
          <EditStrategyStepper setCurrentStepHandler={setCurrentStepHandler} steps={steps} currentStep={currentStep} />
        </div>
      }
    >
      <UploadProductsModal opened={isOpenUploadProductModal} onClose={() => setIsOpenUploadProductModal(false)} />
      <div className={classes.contentWrapper}>
        <StrategyContext.Provider
          value={{
            strategy: strategyData,
            productsOnStrategy,
            onSelectProduct,
            deleteProductIds,
            onChangeStrategy: (strategy) => setStrategyData(strategy),
            initialRepricerItems: initialRepricerItems,
            isRepricerLimited,
            strategyCreationStatus,
            addProductIds,
            addItemsBySelectedFilters,
            deleteAllProducts,
            deleteProduct,
          }}
        >
          <div className={classes.contentBlock}>
            <div className={classes.contentMaxWidth}>
              <StepperButtons
                currentStep={currentStep}
                setCurrentStepHandler={setCurrentStepHandler}
                isLoadingNextStep={isLoadingNextStep}
                countAddedProducts={addProductIds.length}
                onOpenUploadProductModal={() => setIsOpenUploadProductModal(true)}
              />
              {currentStep === 0 && (
                <AddProducts
                  selectedFiltersAddStep={selectedFiltersAddStep}
                  setSelectedFiltersAddStep={setSelectedFiltersAddStep}
                />
              )}
              <InfiniteLoadContext.Provider
                value={{
                  offset: pageParams.offset,
                  loadMoreItems: loadMoreRepricerItems,
                  isLoadingRepricerItems,
                }}
              >
                {currentStep === 1 && (
                  <StrategyDetails
                    collectionPriceUpdateFunctions={collectionPriceUpdateFunctions}
                    collectPriceUpdateFunctions={collectPriceUpdateFunctions}
                    resetCollectPriceUpdateFunctions={resetCollectPriceUpdateFunctions}
                    onChangeItemFilters={setSelectedFilters}
                    selectedFilters={selectedFilters}
                  />
                )}
              </InfiniteLoadContext.Provider>
            </div>
          </div>
        </StrategyContext.Provider>
      </div>
    </SlideUpModal>
  );
});
