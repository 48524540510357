import {type FC} from 'react';

import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';

import {RightArrowIcon} from '../../common/icons';

import {useEmotionWrapper} from './action-button.s';

interface IActionButton {
  onClick?: () => void;
  children?: React.ReactNode;
}

type IActionButtonProps = IActionButton & Omit<React.ComponentPropsWithoutRef<'button'>, keyof IActionButton>;

export const ActionButton: FC<IActionButtonProps> = ({onClick, children, ...props}) => {
  const {classes} = useEmotionWrapper();
  return (
    <button onClick={onClick} className={classes.settingButton} {...props}>
      <MTypography className={classes.buttonText}>{children}</MTypography>
      <RightArrowIcon />
    </button>
  );
};
