import {emotionWrapper} from '@shared/lib';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';

export const useEmotionWrapper = emotionWrapper(() => ({
  nameCell: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  productName: {
    maxWidth: 350,
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    fontSize: 13,
  },
  productSku: {
    display: 'inline-block',
    maxWidth: 350,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 11,
    color: COLORS_REDESIGN.DARK_GREY,
  },
  productInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  collapseNoButton: {
    width: 32,
    height: 32,
  },
  noImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 34,
    height: 45,
  },
  customTooltip: {
    backgroundColor: COLORS_REDESIGN.BRAND,
  },
  customTooltipArrow: {
    color: COLORS_REDESIGN.BRAND,
  },
}));
