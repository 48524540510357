import {type FC} from 'react';
import {observer} from 'mobx-react-lite';

import {Link, Typography} from '@src/components';
import {MaintenanceIcon} from '@src/components/common/icons';
import {COLORS, COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {RESOURCE_URLS} from '@src/shared/const/resource-urls';
import {useStore} from '@src/stores';

import {useEmotionWrapper} from './maintenance-mode.s';

interface IMaintenanceMode {
  children: React.ReactNode;
}

export const MaintenanceMode: FC<IMaintenanceMode> = observer(({children}) => {
  const {classes} = useEmotionWrapper();
  const {utils} = useStore();
  const isMaintenanceMode = import.meta.env.WEB_APP_IS_MAINTENANCE_MODE === 'true';
  const isNetworkError = utils.isNetworkError;

  if (!isMaintenanceMode && !isNetworkError) return children;

  const networkError = (
    <Typography align="center" weight={600} color={COLORS_REDESIGN.DARK_NIGHT_BLUE} size={22}>
      Возникла проблема с сетью
      <br />
      <br />
      <Typography size={22} color={COLORS.DARK_70}>
        Пожалуйста, напишите нам через виджет обратной связи или{' '}
        <Link href={RESOURCE_URLS.SUPPORT_TELEGRAM}>в Telegram.</Link>
      </Typography>
      <br />
      <br />
      <Typography size={22} color={COLORS.DARK_70}>
        Также можете попробовать перезагрузить страницу или воспользоваться другим браузером. <br /> Приносим извинения
        за неудобство.
      </Typography>
    </Typography>
  );

  const maintenanceMode = (
    <>
      <Typography align="center" weight={600} color={COLORS_REDESIGN.DARK_NIGHT_BLUE} size={32}>
        Мы работаем над улучшением сервиса
      </Typography>
      <br />
      <Typography align="center" weight={400} color={COLORS.DARK_70} size={22}>
        Технические работы пройдут сегодня с 7:00 до 10:30 по Московскому времени.
        <br />
        На работу с Маркетом это не влияет, Ваши товары продолжают продвигаться.
      </Typography>
    </>
  );

  return (
    <div className={classes.root}>
      <MaintenanceIcon size="550px" />
      {isNetworkError ? networkError : maintenanceMode}
    </div>
  );
});
