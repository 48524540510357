import {useMemo} from 'react';

import {PRICE_RECOMMENDATION_NOTIFY_LAST_SEEN} from '@src/shared/const/localStorage';
import {useStore} from '@src/stores';
import dayjs from 'dayjs';

type LocalStorageItem = {id: string; lastSeen: string};

export const usePriceCompetitiveness = () => {
  const {mpAccounts} = useStore();

  const lastSeenArray: LocalStorageItem[] = JSON.parse(
    localStorage.getItem(PRICE_RECOMMENDATION_NOTIFY_LAST_SEEN) || '[]'
  );
  const now = dayjs();
  const currentDay = now.day();

  const setNowLastSeenInLocalStorage = () => {
    const arr: LocalStorageItem[] = [...lastSeenArray];
    const findIndex = arr.findIndex((item) => item.id === mpAccounts.current?.id.toString());

    if (findIndex === -1 && mpAccounts.current?.id.toString()) {
      arr.push({id: mpAccounts.current?.id.toString(), lastSeen: now.format('YYYY-MM-DD')});
    } else {
      arr[findIndex].lastSeen = now.format('YYYY-MM-DD');
    }

    localStorage.setItem(PRICE_RECOMMENDATION_NOTIFY_LAST_SEEN, JSON.stringify(arr));
  };

  const lastSeenFromLocalStorage = useMemo(() => {
    return lastSeenArray.find((item) => item.id === mpAccounts.current?.id.toString())?.lastSeen;
  }, [JSON.stringify(lastSeenArray), mpAccounts.current?.id]);

  return {
    lastSeenArray,
    currentDay,
    setNowLastSeenInLocalStorage,
    lastSeenFromLocalStorage,
  };
};
