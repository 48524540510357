import {FC, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';

import {Tooltip, Typography} from '@mui/material';
import {NoImageIcon} from '@src/components/common/icons';
import {MButton} from '@src/components/redesign/mui-components/common/button/button';
import {MTableCell} from '@src/components/redesign/mui-components/common/table/table-cell';
import {MTableRow} from '@src/components/redesign/mui-components/common/table/table-row';
import {MTypography} from '@src/components/redesign/mui-components/common/typography/typography';
import {useCurrentTariff} from '@src/hooks/useCurrenTariff';
import {useStrategyContext} from '@src/pages/repricer/hooks/useStrategyContext';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {useStore} from '@src/stores';
import {TMpAccountItem} from '@src/stores/market-place-account-item/types';

import {useEmotionWrapper} from './product-row.s';
interface IProductRow {
  product: TMpAccountItem;
}

export const ProductRow: FC<IProductRow> = observer(({product}) => {
  const {classes} = useEmotionWrapper();
  const {repricer} = useStore();
  const {currentTariff} = useCurrentTariff();

  const {onSelectProduct, productsOnStrategy, strategy, isRepricerLimited, addProductIds, deleteProductIds} =
    useStrategyContext();
  const [isErrorLoadImage, setIsErrorLoadImage] = useState(false);

  const copiedMap = new Map(
    [...repricer.summaryRepricerItemIds].map(([key, value]) => [key, JSON.parse(JSON.stringify(value))])
  );

  const isUseAnotherStrategy = useMemo(() => {
    copiedMap.delete(strategy.id.toString());

    const isUseAnotherStrategy = [...copiedMap.values()].some((value) => value.itemIds.includes(product.id));

    return isUseAnotherStrategy;
  }, [copiedMap, strategy.id, product.id]);

  const isUseThisStrategy =
    productsOnStrategy.some((item) => item.id === product.id) || addProductIds.some((item) => item === product.id);

  const getAnotherStrategy = () => {
    //получаем имя стратегии в которой используется данный товар (если это не стратегия, которая сейчас редактируется)
    let strategyId = strategy.id;
    copiedMap.delete(strategyId.toString());

    copiedMap.forEach((value, key) => {
      if (value.itemIds.includes(product.id)) {
        strategyId = Number(key);
      }
    });

    const findStrategy = repricer.list.find((item) => item.id === strategyId);

    return findStrategy;
  };

  const isUseThisProduct = () => {
    const isInProductsOnStrategy = repricer.summaryRepricerItemIds
      .get(strategy.id.toString())
      ?.itemIds.some((item) => item === product.id);
    const isInDeleteProductIds = deleteProductIds.some((item) => item === product.id);
    const isInAddProductIds = addProductIds.some((item) => item === product.id);

    if (isInProductsOnStrategy) {
      return !isInDeleteProductIds;
    } else {
      return isInDeleteProductIds || isInAddProductIds;
    }
  };

  return (
    <>
      <MTableRow key={product.id}>
        <MTableCell className={classes.nameCell}>
          {isErrorLoadImage ? (
            <div className={classes.noImage}>
              <NoImageIcon color={COLORS_REDESIGN.DARK_GREY} size="32" />
            </div>
          ) : (
            <img
              onError={() => setIsErrorLoadImage(true)}
              loading="lazy"
              width={34}
              height={45}
              src={product.picture || ''}
            />
          )}
          <div className={classes.productInfo}>
            <MTypography className={classes.productName}> {product.name}</MTypography>
            <MTypography className={classes.productSku}>
              SKU: {product.sku} · {product.category}
            </MTypography>
          </div>
        </MTableCell>
        {/* <MTableCell align="left">
          <MTypography className={classes.price}>{insertSeparatorToNumberOrString(product.price)} ₽</MTypography>{' '}
        </MTableCell> */}
        <MTableCell align="right">
          {isUseAnotherStrategy ? (
            <div className={classes.actionsCellContainer}>
              <div className={classes.isUseItem}>
                <Typography sx={{fontSize: 12, color: COLORS_REDESIGN.DARK_GREY}}>Уже в стратегии</Typography>
                <Typography sx={{fontSize: 12, fontWeight: 600, color: COLORS_REDESIGN.DARK_GREY}}>
                  {getAnotherStrategy()?.name}
                </Typography>
              </div>
            </div>
          ) : (
            <Tooltip
              title={
                isRepricerLimited && !isUseThisStrategy ? (
                  <MTypography sx={{fontSize: 13, width: 280}}>
                    Превышен лимит товаров к добавлению в Ценообразование. Всего доступно{' '}
                    {currentTariff?.maxRepricerItems} шт.
                  </MTypography>
                ) : (
                  ''
                )
              }
            >
              <div>
                <MButton
                  disabled={isUseThisStrategy ? false : isRepricerLimited}
                  onClick={() => onSelectProduct(product)}
                  variant={isUseThisProduct() ? 'outlined' : 'contained'}
                >
                  {isUseThisProduct() ? 'Удалить' : 'Добавить'}
                </MButton>
              </div>
            </Tooltip>
          )}
        </MTableCell>
        <MTableCell />
      </MTableRow>
    </>
  );
});
