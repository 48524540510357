import {FC, useLayoutEffect, useRef, useState} from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import {CircularProgress, Switch, TextField, Tooltip} from '@mui/material';
import Box from '@mui/material/Box/Box';
import {MIconButton} from '@src/components/redesign/mui-components/common/icon-button/icon-button';
import {COLORS_REDESIGN} from '@src/shared/const/appPalette';
import {isTextTruncated} from '@src/shared/utils/is-text-truncated';
import {removeHttpPrefix} from '@src/shared/utils/remove-http-prefix';
import {TRepricerItemCompetitor} from '@src/stores/repricer/types';

import {useEmotionWrapper} from './competitor-item.s';

interface ICompetitorItemProps {
  competitor: TRepricerItemCompetitor;
  onClickDeleteCompetitors: (competitorId: number) => Promise<void>;
  onChangeEnabled: (competitor: TRepricerItemCompetitor) => Promise<void>;
  onUpdateCompetitorName: (competitor: TRepricerItemCompetitor) => Promise<void>;
  idx: number;
}

export const CompetitorItem: FC<ICompetitorItemProps> = ({
  competitor,
  onClickDeleteCompetitors,
  onChangeEnabled,
  onUpdateCompetitorName,
  idx,
}) => {
  const {classes} = useEmotionWrapper(idx);
  const [competitorName, setCompetitorName] = useState<string>(competitor.name || competitor.url);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingChangeEnabled, setIsLoadingChangeEnabled] = useState(false);
  const [isLoadingUpdateName, setIsLoadingUpdateName] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const competitorNameRef = useRef(null);

  const handleDelete = async () => {
    setIsLoadingDelete(true);
    try {
      await onClickDeleteCompetitors(competitor.id);
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const handleChangeEnabled = async () => {
    setIsLoadingChangeEnabled(true);
    try {
      await onChangeEnabled({...competitor, enabled: !competitor.enabled});
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoadingChangeEnabled(false);
    }
  };

  const onChangeCompetitorName = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompetitorName(e.target.value);
  };

  const handeClickUpdateName = async () => {
    setIsLoadingUpdateName(true);
    try {
      await onUpdateCompetitorName({...competitor, name: competitorName});
    } catch (e) {
      console.log('e', e);
    } finally {
      setIsLoadingUpdateName(false);
      setIsEditing(false);
    }
  };

  useLayoutEffect(() => {
    if (competitorNameRef.current) {
      setIsTruncated(isTextTruncated(competitorNameRef.current));
    }
  }, [competitorNameRef]);

  return (
    <Box className={classes.root} onClick={(e) => e.stopPropagation()}>
      <div className={classes.container}>
        {!isEditing && (
          <Tooltip arrow title="Отслеживать цену на товары конкурента и учитывать в рекомендациях">
            <Switch
              size="small"
              onChange={handleChangeEnabled}
              disabled={isLoadingChangeEnabled}
              checked={competitor.enabled}
            />
          </Tooltip>
        )}
        {isEditing ? (
          <TextField
            fullWidth
            size="small"
            variant="standard"
            value={competitorName}
            onChange={onChangeCompetitorName}
          />
        ) : (
          <Tooltip arrow enterDelay={700} leaveDelay={0} title={isTruncated ? competitor.name || competitor.url : ''}>
            <span
              ref={competitorNameRef}
              className={classes.checkBoxLabel}
              onClick={() => window.open(competitor.url, '_blank')}
            >
              {competitor.name ? competitor.name : removeHttpPrefix(competitor.url)}
            </span>
          </Tooltip>
        )}
      </div>
      {isEditing && (
        <MIconButton size="small" disabled={isLoadingUpdateName || !competitorName} onClick={handeClickUpdateName}>
          {isLoadingUpdateName ? (
            <CircularProgress size={24} />
          ) : (
            <DoneIcon sx={{color: !competitorName ? 'disabled' : COLORS_REDESIGN.GREEN}} />
          )}
        </MIconButton>
      )}
      {!isEditing && (
        <div style={{display: 'flex', marginLeft: 'auto'}}>
          <MIconButton size="small" onClick={() => setIsEditing(true)}>
            {<EditIcon />}
          </MIconButton>
          <MIconButton size="small" onClick={handleDelete}>
            {isLoadingDelete ? <CircularProgress size={20} /> : <DeleteOutlineIcon />}
          </MIconButton>
        </div>
      )}
    </Box>
  );
};
