import {useEffect, useState} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';

import SettingsIcon from '@mui/icons-material/Settings';
import LoadingButton from '@mui/lab/LoadingButton';
import {MButton} from '@src/components/redesign/mui-components/common/button/button';
import {PageTitle} from '@src/components/redesign/mui-components/common/page-title/page-title';
import {PageDescription} from '@src/components/redesign/specific/page-description/page-description';
import {useDebounce} from '@src/hooks/useDebounce';
import {IS_VIEWED_REPRICER_PAGE_DESCRIPTION} from '@src/shared/const/localStorage';
import {getRouteRepricer, getRouteSettings} from '@src/shared/const/router';
import {REPRICER_STRATEGY} from '@src/shared/enums/repricer-strategy';
import {useStore} from '@src/stores';
import {TRepricerStrategy} from '@src/stores/repricer/types';
import dayjs from 'dayjs';

import {RepricerItemsStats} from './components/repricer-items-stats/repricer-items-stats';
import {StrategiesTable} from './components/strategy-views/strategies-table/strategies-table';
import {StrategiesFilter} from './components/strategy-views/strategy-filters/strategies-filter';
import {TRepricerStrategiesFilterCriteria} from './types/strategies';
import {filterStrategies} from './utils/filter-strategies';

import {useEmotionWrapper} from './repricer.s';

export const RepricerPage = observer(() => {
  const {classes} = useEmotionWrapper();
  const {mpAccounts, repricer} = useStore();
  const navigate = useNavigate();

  const [isLoadingStrategies, setIsLoadingStrategies] = useState(true);
  const [isLoadingCreateNewStrategy, setIsLoadingCreateNewStrategy] = useState(false);

  const [filteredStrategies, setFilteredStrategies] = useState<TRepricerStrategy[]>(repricer.list);
  const [filterCriteria, setFilterCriteria] = useState<TRepricerStrategiesFilterCriteria>({
    searchItem: '',
    type: [],
    enabled: [],
  });
  const debouncedSearchItem = useDebounce(filterCriteria.searchItem, 400);

  useEffect(() => {
    if (mpAccounts.current?.id) {
      setIsLoadingStrategies(true);
      repricer
        .getStrategies({marketPlaceAccountId: mpAccounts.current.id, searchItem: debouncedSearchItem})
        .catch((e) => console.log(e))
        .finally(() => setIsLoadingStrategies(false));
    }
  }, [mpAccounts.current?.id, debouncedSearchItem]);

  useEffect(() => {
    const filtered = filterStrategies(repricer.list, {
      ...filterCriteria,
      enabled: filterCriteria.enabled?.map((e) => (e === 'true' ? true : false)),
    });

    setFilteredStrategies(filtered);
  }, [JSON.stringify(filterCriteria), JSON.stringify(repricer.list)]);

  const createStrategyHandler = async () => {
    if (mpAccounts.current?.id) {
      setIsLoadingCreateNewStrategy(true);
      const {strategyId} = await repricer.createStrategy({
        marketPlaceAccountId: mpAccounts.current?.id,
        name: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        type: REPRICER_STRATEGY.DEFAULT,
        enabled: true,
        isAuto: false,
        minPricePct: null,
        maxPricePct: null,
        params: {place: null},
      });
      navigate(`${getRouteRepricer()}` + `/${strategyId}?create=true`);
      await repricer.getStrategies({marketPlaceAccountId: mpAccounts.current?.id});

      setIsLoadingCreateNewStrategy(false);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.pageHeader}>
        <PageTitle>Ценообразование</PageTitle>
        <div className={classes.headerButton}>
          <MButton
            startIcon={<SettingsIcon />}
            onClick={() => navigate(`${getRouteSettings()}` + '/price-monitoring')}
            variant="outlined"
            color="inherit"
          >
            Настройки
          </MButton>
          <LoadingButton variant="contained" loading={isLoadingCreateNewStrategy} onClick={createStrategyHandler}>
            Создать стратегию
          </LoadingButton>
        </div>
      </div>
      <PageDescription pageDescriptionId={IS_VIEWED_REPRICER_PAGE_DESCRIPTION}>
        Ценообразование упрощает работу управления ценой товаров, используя данные о конкурентах и рекомендации цен в
        автоматических и ручных стратегиях.
      </PageDescription>
      <div className={classes.filtersAndStats}>
        <StrategiesFilter filterCriteria={filterCriteria} onChangeFilterCriteria={setFilterCriteria} />
        <RepricerItemsStats />
      </div>
      <StrategiesTable isLoadingStrategies={isLoadingStrategies} strategies={filteredStrategies} />
      <Outlet />
    </div>
  );
});
