import {FC} from 'react';

import {TSelectedProductFilters} from '@src/pages/repricer/types/products';
import {TCollectionPriceUpdateFunctions, TProductPrices} from '@src/pages/repricer/types/repricer-item';

import {ProductSettingsTable} from './repricer-item-table/product-settings-table/product-settings-table';
import {StrategySettings} from './strategy-settings/strategy-settings';
import {ProductFilters} from '../../../product-filters/product-filters';

interface IStrategyDetailsProps {
  collectPriceUpdateFunctions: (type: TProductPrices, updateFunction: () => Promise<void>) => void;
  collectionPriceUpdateFunctions: TCollectionPriceUpdateFunctions;
  resetCollectPriceUpdateFunctions: (type: TProductPrices) => void;
  onChangeItemFilters: (selectedFilters: TSelectedProductFilters) => void;
  selectedFilters: TSelectedProductFilters;
}

export const StrategyDetails: FC<IStrategyDetailsProps> = ({
  collectPriceUpdateFunctions,
  collectionPriceUpdateFunctions,
  resetCollectPriceUpdateFunctions,
  onChangeItemFilters,
  selectedFilters,
}) => {
  return (
    <div style={{display: 'flex', gap: 20, maxWidth: '100%', justifyContent: 'center'}}>
      <div style={{display: 'flex', gap: 20, width: '100%', alignItems: 'flex-start', justifyContent: 'center'}}>
        <div>
          <ProductFilters
            showPriceCompetitiveness={false}
            selectedFilters={selectedFilters}
            onChangeItemFilters={onChangeItemFilters}
          />
          <ProductSettingsTable
            collectPriceUpdateFunctions={collectPriceUpdateFunctions}
            resetCollectPriceUpdateFunctions={resetCollectPriceUpdateFunctions}
          />
        </div>
        <StrategySettings collectionPriceUpdateFunctions={collectionPriceUpdateFunctions} />
      </div>
    </div>
  );
};
