import {useContext} from 'react';

import {StrategyContext} from '../components/edit-strategy/context/create-strategy';

export const useStrategyContext = () => {
  const {
    productsOnStrategy,
    onSelectProduct,
    strategy,
    onChangeStrategy,
    deleteProductIds,
    initialRepricerItems,
    isRepricerLimited,
    addProductIds,
    strategyCreationStatus,
    addItemsBySelectedFilters,
    deleteAllProducts,
    deleteProduct,
  } = useContext(StrategyContext);

  return {
    productsOnStrategy,
    onSelectProduct,
    onChangeStrategy,
    strategy,
    deleteProductIds,
    initialRepricerItems,
    isRepricerLimited,
    addProductIds,
    strategyCreationStatus,
    addItemsBySelectedFilters,
    deleteAllProducts,
    deleteProduct,
  };
};
